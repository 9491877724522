import {
  UiHStack,
  UiIconListChecks,
  UiIconMagnifyingGlassMinus,
  UiSimpleGrid,
  UiSpinner,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import { dynamicColor } from '@/lib/util';
import { type ChartDataset } from 'chart.js';
import type React from 'react';
import { useMemo, useEffect, useState } from 'react';
import { type useGetListActivatedChartType } from '@/registration/report/Registration';
import LineChart from '@/base/Chart/Line';
import { type EventListData } from '@/api/registration';
import { defaultColors } from '@/api/constant/colors';
import BaseSimpleSelectElement, { type Option as SelectElementOption } from '@/base/Form/SimpleSelectElement';
import type { OnChangeValue } from 'chakra-react-select';

interface RegistrationsChartReportProps {
  selectOptions: Array<{
    label: string
    value: string
  }>
  eventList: EventListData | undefined
  useGetListActivatedChart: useGetListActivatedChartType
  type: string
  title: string
  paymentOptions?: Array<{
    label: string
    value: string
  }>
  eventOptions?: SelectElementOption[]
  handleScrollToBottom?: (event: WheelEvent | TouchEvent) => void
}

const RegistrationsActivatedChartReport: React.FC<RegistrationsChartReportProps> = ({ selectOptions, useGetListActivatedChart, title, paymentOptions, eventOptions = [], handleScrollToBottom }) => {
  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [selectedPayment, setSelectedPayment] = useState<string>('');
  const [labels, setLabels] = useState<string[]>([]);
  const [totalRegistration, setTotalRegistration] = useState<number>(0);
  const [datasets, setDatasets] = useState<Array<ChartDataset<'line', number[]>>>([]);
  const [eventId, setEventId] = useState<string>('');

  const { data, isLoading } = useGetListActivatedChart({ groupName: selectedGroup, eventId, paymentMethod: selectedPayment });

  useEffect(() => {
    let colors: string[] = [...defaultColors];

    if (data && !data?.errors) {
      setLabels(data.item.labels);
      let totals = 0;
      const fetchDataset = data?.item.datasets.map((item: any) => {
        const color = dynamicColor(colors);
        colors = colors.filter((value) => { return value !== color; });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, no-eval
        totals += eval(item.values.join('+'));

        return {
          label: item.label,
          data: item.values,
          backgroundColor: color,
          borderColor: color,
        };
      });
      setTotalRegistration(totals);
      setDatasets(fetchDataset);
    }
  }, [data, selectedGroup, title]);

  const handleChange = (option: OnChangeValue<SelectElementOption, false>) => {
    setSelectedGroup(option?.value ? `${option?.value}` : '');
  };

  const handleChangePayment = (option: OnChangeValue<SelectElementOption, false>) => {
    setSelectedPayment(option?.value ? `${option?.value}` : '');
  };

  const handleChangeEvent = (option: OnChangeValue<SelectElementOption, false>) => {
    setEventId(option?.value ? `${option?.value}` : '');
  };

  return (
    <UiStack
      alignItems={'stretch'}
      spacing={4}
      borderWidth={'1px'}
      borderColor={'blackAlpha.100'}
      borderRadius={uiStyles.borderRadius}
      p={8}
    >
      <UiHStack spacing={2}>
        <UiIconListChecks color={'text.primary'} />
        <UiText variant={'body1'}>{title}</UiText>
      </UiHStack>
      <UiSimpleGrid columns={2} spacing={4} flexGrow={1}>
        <UiStack flexGrow={1}>
          <BaseSimpleSelectElement
            optionValue={eventId}
            onChange={handleChangeEvent}
            options={eventOptions}
            onScrollToBottom={handleScrollToBottom}
            placeholder={'Select event'}
          />
        </UiStack>
        <UiStack flexGrow={1}>
          <BaseSimpleSelectElement
            optionValue={selectedGroup}
            onChange={handleChange}
            options={selectOptions}
            placeholder={'Select group'}
          />
        </UiStack>
        {paymentOptions && <UiStack flexGrow={1}>
          <BaseSimpleSelectElement
            optionValue={selectedPayment}
            onChange={handleChangePayment}
            options={paymentOptions}
            placeholder={'Select payment'}
          />
        </UiStack>}
      </UiSimpleGrid>

      {labels?.length === 0 && (
        <UiHStack py={2}>
          <UiIconMagnifyingGlassMinus color={'text.secondary'} />
          <UiText color={'gray.secondary'} variant={'body2'}>
            No results
          </UiText>
        </UiHStack>
      )}

      {labels && labels.length > 0 && (
        <LineChart datasets={datasets} labels={labels} totalRegistration={totalRegistration} />
      )}

      {isLoading && !!selectedGroup && !!eventId && !!selectedPayment && (
        <UiStack>
          <UiSpinner size={'md'} color={'primary.500'} thickness='2px' />
        </UiStack>
      )}

    </UiStack>
  );
};

export default RegistrationsActivatedChartReport;
