import { type FC, ReactNode, useCallback, useState } from 'react';
import {
  UiHStack,
  type UiHStackProps, UiIconEye,
  UiIconPencilSimple,
  UiIconProhibit,
  UiSpinner,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import { ModulePermission, ModulePermissionNone } from './permissions';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { account } from '@/api';

export interface ModulePermissionEditProps extends UiHStackProps {
  teamId: number
  moduleCard: ReactNode
  module: PermissionDomain
  permission: ModulePermission
  disableViewOnly?: boolean
  // onPermissionChange: (module: PermissionDomain, permission: ModulePermission) => void
}

const ModulePermissionEdit: FC<ModulePermissionEditProps> = ({
  teamId,
  module,
  permission,
  disableViewOnly = false,
}) => {
  const [ errors, setErrors ] = useState<string[]>([]);
  const { createGlobalApiAclRequest } = useGlobalApi();
  const queryClient = useQueryClient();
  const { reportToGlobal } = useApiErrorHandler();

  const { mutate, isLoading } = useMutation<account.UpdateTeamDomainActionsResponse, Error, account.UpdateTeamDomainActionsRequest>({
    mutationFn: async (data: account.UpdateTeamDomainActionsRequest) =>
      await account.updateTeamDomainActions(createGlobalApiAclRequest())(data),
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setErrors(result?.errors);
      } else {
        setErrors([]);
        // Reload the team.
        void queryClient.invalidateQueries({ queryKey: [account.teamQueryKey, {id: teamId}]});
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrors([error.message ?? 'Failed to update the permission.']);
    }
  });

  const changePermission = useCallback(
    (action: ModulePermission) => {
      let _actions: PermissionAction[] = [];
      if (action !== ModulePermissionNone) {
        _actions = [action];
      }
      mutate({
        teamId,
        domain: module,
        actions: _actions,
      })
    },
    [mutate, teamId]
  );

  return (
    <UiHStack
      borderRadius={uiStyles.borderRadius}
      // bgColor={'#fff'}
      justifyContent={'space-between'}
      bgColor={'#fff'}
      // p={8}
    >
      <UiHStack justifyContent={'flex-end'} flexGrow={1} spacing={8}>
        {errors.length > 0 && (
          <UiStack>
            <UiText color={'red.500'}>{errors[0]}</UiText>
          </UiStack>
        )}
        {isLoading && (
          <UiSpinner size={'sm'} color={'primary.500'}/>
        )}
        <UiHStack
          justifyContent={'flex-end'}
          flexGrow={1}
          spacing={4}
          // opacity={isLoading ? 0.6 : 1}
          // bgColor={isLoading ? 'gray.100' : 'transparent'}
        >
          <UiHStack
            p={4}
            py={2}
            borderWidth={'1px'}
            borderColor={permission === PermissionAction.Write ? 'green.500' : 'gray.400'}
            borderStyle={'solid'}
            borderRadius={uiStyles.buttonRadius}
            bgColor={permission === PermissionAction.Write ? 'green.100' : 'transparent'}
            onClick={() => changePermission(PermissionAction.Write)}
            {...uiStyles.hover}
          >
            <UiIconPencilSimple size={'lg'}/>
            <UiText variant={'body2'}>View & edit</UiText>
          </UiHStack>
          {!disableViewOnly && (
            <UiHStack
              p={4}
              py={2}
              borderWidth={'1px'}
              borderColor={permission === PermissionAction.Read ? 'yellow.500' : 'gray.400'}
              borderStyle={'solid'}
              borderRadius={uiStyles.buttonRadius}
              bgColor={permission === PermissionAction.Read ? 'yellow.100' : 'transparent'}
              onClick={() => changePermission(PermissionAction.Read)}
              {...uiStyles.hover}
            >
              <UiIconEye size={'lg'}/>
              <UiText variant={'body2'}>View only</UiText>
            </UiHStack>
          )}
          <UiHStack
            p={4}
            py={2}
            borderWidth={'1px'}
            borderColor={permission === ModulePermissionNone ? 'red.500' : 'gray.400'}
            borderStyle={'solid'}
            borderRadius={uiStyles.buttonRadius}
            bgColor={permission === ModulePermissionNone ? 'red.100' : 'transparent'}
            onClick={() => changePermission(ModulePermissionNone)}
            {...uiStyles.hover}
          >
            <UiIconProhibit size={'lg'}/>
            <UiText variant={'body2'}>No access</UiText>
          </UiHStack>
        </UiHStack>
      </UiHStack>
    </UiHStack>
  );
};

export default ModulePermissionEdit;
