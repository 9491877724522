import { type FC } from 'react';
import {
  UiHStack, UiIconUsersFour,
  UiIconXCircle,
  UiSimpleGrid,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import { registrationIdQueryKey, registrationTableQueryKey, type RegistrationInfo } from '@/api/registration';
import dayjs from 'dayjs';
import { HStack, useDisclosure } from '@chakra-ui/react';
import CancelRegistrationConfirmation from './CancelRegistrationConfirmation';
import { useQueryClient } from '@tanstack/react-query';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';
import MigrateRegistrationDrawer from './TransferRegistrationDrawer';
import BaseButtonPlainIcon from '@/base/Button/PlainIcon';

export interface RegistrationSummaryProps {
  registration: RegistrationInfo
}

const RegistrationSummary: FC<RegistrationSummaryProps> = ({ registration }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const onConfirm = () => {
    onClose();
    void queryClient.invalidateQueries({ queryKey: [registrationIdQueryKey, { id: registration.id }] });
    void queryClient.invalidateQueries({ queryKey: [registrationTableQueryKey] });
  };

  return (
    <>
      <UiStack p={8} py={8} borderRadius={uiStyles.borderRadius} bgColor={'#fff'} flexGrow={1} shadow={uiStyles.cardShadow}>
        <UiSimpleGrid columns={3} gap={8}>
          <UiStack>
            <UiText color={'text.secondary'}>Email</UiText>
            <UiHStack spacing={4}>
              <UiText>{registration.email}</UiText>
            </UiHStack>
          </UiStack>
          <UiStack>
            <UiText color={'text.secondary'}>Status</UiText>
            <HStack>
              <UiText>{registration.status}</UiText>
              {registration.status !== 'Cancelled' && (
                <UiStack {...uiStyles.hover} title='Cancel the registration'>
                  <BaseButtonPlainIcon
                    label={'Cancel the registration'}
                    Icon={UiIconXCircle}
                    color={'red.500'}
                    onClick={onToggle}
                  />
                </UiStack>
              )}
            </HStack>
          </UiStack>
          <UiStack>
            <UiText color={'text.secondary'}>Attendee Category</UiText>
            <UiText>{registration.attendeeCategory.name}</UiText>
          </UiStack>
          <UiStack>
            <UiText color={'text.secondary'}>Event</UiText>
            <UiText>{registration.event.name} (id: {registration.event.id})</UiText>
          </UiStack>
          <UiStack>
            <UiText color={'text.secondary'}>CreatedAt</UiText>
            <UiText>{dayjs(registration.createdAt).format('DD MMMM YYYY HH:mm')}</UiText>
          </UiStack>
          {registration.cancelledAt && (
            <UiStack>
              <UiText color={'text.secondary'}>Cancelled At</UiText>
              <UiText>{dayjs(registration.cancelledAt).format('DD MMMM YYYY HH:mm')}</UiText>
            </UiStack>
          )}
          {registration.cancelledBy && (
            <UiStack>
              <UiText color={'text.secondary'}>Cancelled By</UiText>
              <UiText>{registration.cancelledBy}</UiText>
            </UiStack>
          )}
        </UiSimpleGrid>
      </UiStack>
      <CancelRegistrationConfirmation
        registrationId={registration.id}
        isOpen={isOpen}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </>
  );
};

export default RegistrationSummary;
