import { type FC, type ElementType } from 'react';
import {
  UiStack,
  UiHStack,
  type UiHStackProps,
  uiStyles,
  UiText,
  UiSpinner,
} from '@/lib/ui';

export interface ButtonProps extends UiHStackProps {
  LeftIcon?: ElementType
  RightIcon?: ElementType
  color?: string
  isLoading?: boolean
  children: string
}

const Ghost: FC<ButtonProps> = ({
  children,
  LeftIcon,
  RightIcon,
  isLoading = false,
  color = 'primary.600',
  ...props
}) => {

  return (
    <UiHStack
      borderRadius={uiStyles.buttonRadius}
      justifyContent={'space-between'}
      {...uiStyles.hover}
      {...props}
    >
      {isLoading && (
        <UiSpinner color={color}/>
      )}
      {!isLoading && LeftIcon
        ? (
          <UiStack>
            <LeftIcon color={color} size={'2xl'}/>
          </UiStack>
        )
        : null}
      <UiStack flexGrow={1}>
        <UiText color={color} variant={'body1'}>
          {children}
        </UiText>
      </UiStack>
      {!isLoading && RightIcon
        ? (
          <UiStack>
            <RightIcon color={color} size={'2xl'}/>
          </UiStack>
        )
        : null}
    </UiHStack>
  );
};

export default Ghost;
