import { TicketType, type TicketState } from '@/api/constant/ticket';
import {
  type ApiRequest,
  type ApiResponse,
  callApi,
  deleteAPI
} from '@/api/tenantClient';
import { type TicketDiscount } from './ticketDiscount';

export const ticketQueryKey = 'registration.ticket';
export const functionTicketListQueryKey = 'registration.functionTicketList';

export interface TicketData {
  id: number
  eventId: string
  state: TicketState
  type: TicketType
  name: string
  description: string
  price: number // Amount in cents.
  stock: number
  createdAt: string | null
  updatedAt: string | null
  code: string
  validFrom: string
  validTo: string
  attendeeCategories: Array<{
    id: number
    name: string
  }>
  ticketDiscounts?: TicketDiscount[]
  currentStock: number
  stockType: number
  ticketSharedStockId: number | null
  days: Array<{
    id: number
    date: string
  }>
  themes: Array<{
    id: number
    name: string
  }>
  recommendedDelegateTypes: Array<{
    id: number
    name: string
  }>
  registrationBadges: Array<{
    id: number
    name: string
  }>
  accessLiveStream: string
  accessMeetingMatching: string
  accessNetworking: string
  accessResources: string
  accessVodContent: string
  accessChat: string
  accessUserProfile: string
  registrationBadgesColour: string
}

export interface TicketSaveRequestItem extends Omit<TicketData, 'id' | 'createdAt' | 'updatedAt' | 'state' | 'type' | 'attendeeCategories' | 'eventId' | 'currentStock' | 'days' | 'themes' | 'recommendedDelegateTypes' | 'registrationBadges'> {
  id?: number
  eventIds: string[]
  attendeeCategoryIds: string[]
  dayIds: string[]
  themeIds: string[]
  delegateTypeRecommendationIds: string[]
  registrationBadgeIds: string[]
}

export interface TicketSaveRequest extends TicketSaveRequestItem {
  ticketType: TicketType
}

export function saveTicket(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: TicketSaveRequest): Promise<ApiResponse<TicketData>> => {
    const path = params.ticketType === TicketType.Main ? '/api/v1/admin/delegate_types' : '/api/v1/admin/sessions_pricings';
    const payload = params.ticketType === TicketType.Main ? { delegateType: params } : { sessionsPricing: params };
    if (params.id) {
      request.method = 'PUT';
      request.endpoint.path = path + `/${params.id}`;
      request.endpoint.query = {
        eventId: params.eventIds[0]
      };
      request.payload = payload;
    } else {
      request.method = 'POST';
      request.endpoint.path = path;
      request.endpoint.query = {
        eventId: params.eventIds[0]
      };
      request.payload = payload;
    }
    return await callApi<TicketData>(request);
  };
}

export interface TicketDeleteRequest {
  id: string
  ticketType: TicketType
}

export function deleteTicket(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: TicketDeleteRequest) => {
    const path = params.ticketType === TicketType.Main ? '/api/v1/admin/delegate_types/' : '/api/v1/admin/sessions_pricings/';
    request.method = 'DELETE';
    request.endpoint.path = path + params.id;
    await deleteAPI(request);
  };
}

export const getProductType = (type: TicketType) => {
  if (type === TicketType.Main) {
    return 'DelegateType';
  } else {
    return 'SessionsPricing';
  }
};

export interface TicketCloneRequest {
  id: string
  ticketType: TicketType
}

export function cloneTicket(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: TicketCloneRequest): Promise<ApiResponse<TicketData>> => {
    const path = params.ticketType === TicketType.Main ? '/api/v1/admin/delegate_types/clone' : '/api/v1/admin/sessions_pricings/clone';
    request.method = 'POST';
    request.endpoint.path = path;
    request.endpoint.query = { id: params.id.toString() };
    return await callApi<TicketData>(request);
  };
}
