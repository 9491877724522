import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  UiStack,
  UiText,
  UiLink,
  UiHStack,
  uiStyles,
  UiIconCaretRight,
  UiIconCheck,
  UiIconArrowCircleRight,
  UiTag,
  UiIconFunnel,
  UiInput,
  UiIconMagnifyingGlass, UiSpinner, UiIconCaretLeft,
} from '@/lib/ui';
import { account } from '@/api';
import { generatePageUrl } from '@/app/pages';
import { useNavigate } from 'react-router-dom';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import BaseBlockList from '@/base/BlockList';
import { useQuery } from '@tanstack/react-query';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { TenantState } from '@/api/constant';
// import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BaseSearchBar from '@/base/SearchBar';

export interface TenantListProps {
  enableSearch?: boolean
}

const TenantList: FC<TenantListProps> = ({
  enableSearch = true,
}) => {
  // AAA module isn't tied to a certain tenant. So the tenant variable here can be null.
  const { tenant } = useTenantRoute();
  const { adminAuth } = useAdminAuth();
  const { createGlobalApiAclRequest } = useGlobalApi();
  const [ toggleSearch, setToggleSearch ] = useState(false);
  const [ name, setName ] = useState('');
  const [ page, setPage ] = useState(1);
  const [ limit, setLimit ] = useState(20);
  const [ orderByField, setOrderByField ] = useState('name');
  const [ isOrderedAsc, setIsOrderedAsc ] = useState(true);

  const getTenantLink = useCallback(
    (tenantCode: string) => {
      return generatePageUrl('AccountDashboardModules', {tenantCode,});
    },
    []
  );

  const { data, isLoading, error, refetch } = useQuery<account.TenantSearchResponse, Error>(
    [account.tenantListQueryKey, {page, orderByField, isOrderedAsc, limit}],
    async () => {
      return await account.searchTenants(createGlobalApiAclRequest())({
        state: TenantState.Active,
        name,
        page,
        orderByField,
        isOrderedAsc,
        limit, // Let's get all the teams at once.
      });
    },
  );

  useEffect(
    () => {
      refetch();
    },
    [
      refetch,
      page,
      orderByField,
      isOrderedAsc,
    ]
  );

  useEffect(
    () => {
      refetch();
    },
    [
      toggleSearch,
    ]
  );

  const totalPages = useMemo(
    () => {
      if (data?.total) {
        return Math.ceil(data?.total / limit);
      }
      return 0;
    },
    [data?.total]
  );

  const onNextPage = useCallback(
    () => {
      if (page < totalPages) {
        setPage(page + 1);
      }
    },
    [setPage, page, totalPages]
  );

  const onPreviousPage = useCallback(
    () => {
      if (page > 1) {
        setPage(page - 1);
      }
    },
    [setPage, page]
  );

  return (
    <UiStack
      spacing={4}
    >
      <UiHStack justifyContent={'space-between'}>
        {data?.total && data?.total > 1 ? (
          <UiText variant={'title'}>Workspaces</UiText>
        ) : (
          <UiText variant={'title'}>Workspace</UiText>
        )}
      </UiHStack>
      {enableSearch && (
        <BaseSearchBar
          placeHolder={'Search name'}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          onClear={() => {
            setName('');
            setToggleSearch(!toggleSearch);
          }}
          onSearch={refetch}
        />
      )}
      {isLoading && (
        <UiStack>
          <UiSpinner size={'sm'} color={'primary.500'} />
        </UiStack>
      )}
      {(data?.tenantList && Array.isArray(data?.tenantList) && data?.tenantList.length > 0) && (
        <BaseBlockList
          enableDivider={true}
          bgColor={'#fff'}
        >
          {data.tenantList.map((t, index) => {
            return (
              // <UiLink key={t.id} href={tenant && tenant.id === t.id ? "#" : getTenantLink(t.code)} bgColor={'#fff'}>
              <UiLink key={t.id} href={getTenantLink(t.code)} bgColor={'#fff'}>
                <UiHStack
                  px={8}
                  py={4}
                  justifyContent={'space-between'}
                  {...uiStyles.hover}
                >
                  <UiHStack spacing={4}>
                    <UiText>{t.name}</UiText>
                    {tenant && tenant.id === t.id && (
                      <UiTag colorScheme={'green'}>Current</UiTag>
                    )}
                  </UiHStack>
                  <UiIconArrowCircleRight size={'2xl'} color={'primary.500'}/>
                </UiHStack>
              </UiLink>
            );
          })}
        </BaseBlockList>
      )}
      {totalPages > 1 && (
        <UiHStack justifyContent={'flex-end'}>
          <UiHStack spacing={4}>
            {page > 1 && (
              <UiStack
                {...uiStyles.hover}
                onClick={onPreviousPage}
              >
                <UiIconCaretLeft size={'2xl'} color={'primary.500'}/>
              </UiStack>
            )}
            <UiText>{page} / {totalPages}</UiText>
            {totalPages > page && (
              <UiStack
                {...uiStyles.hover}
                onClick={onNextPage}
              >
                <UiIconCaretRight size={'2xl'} color={'primary.500'}/>
              </UiStack>
            )}
          </UiHStack>
        </UiHStack>
      )}
    </UiStack>
  );
};

export default TenantList;
