import { type FC, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  UiStack,
  UiSpinner,
  type UiDrawerProps, UiHStack, UiTag, UiText, UiGrid, UiSimpleGrid, uiStyles,
} from '@/lib/ui';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { account } from '@/api';
import BaseBlockList from '@/base/BlockList';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';
import { subscriptionBillItemCodeLabels } from '@/api/constant/subscriptionBillItem';
import CurrencyTag from '@/account/component/CurrencyTag';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BillStatus from '@/account/subscription/Payment/BillStatus';
import utc from 'dayjs/plugin/utc';

export interface DrawerProps {
  subscriptionBillId: number
}

const BillInfo: FC<DrawerProps> = ({
  subscriptionBillId,
}) => {
  const {tenant} = useTenantRoute();
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();

  const { data, isLoading, error } = useQuery<account.SubscriptionBillLoadTenantSubscriptionBillResponse, Error>(
    [account.subscriptionBillQueryKey, {tenantId: tenant?.id, subscriptionBillId,}],
    async () => {
      return await account.loadTenantSubscriptionBill(createGlobalApiAclRequest())({
        tenantId: tenant?.id ?? 0,
        subscriptionBillId,
      });
    },
    {
      enabled: !!tenant?.id,
    }
  );

  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );

  if (isLoading) {
    return (
      <UiStack p={8}>
        <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
      </UiStack>
    );
  }

  if (data?.subscriptionBill && data?.subscriptionBill?.id) {
    return (
      <UiStack spacing={8}>
        <UiStack spacing={4}>
          <BaseBlockList
            // bgColor={'#fff'}
            // px={8}
            // py={6}
            // borderRadius={uiStyles.borderRadius}
          >
            <UiSimpleGrid columns={2} gap={4} bgColor={'#fff'} px={8} py={4}>
              <UiStack>
                <UiText variant={'body2'} color={'text.secondary'}>Invoice</UiText>
                <UiHStack>
                  <UiText>{data.subscriptionBill.name}</UiText>
                  <BillStatus billState={data.subscriptionBill.state}/>
                </UiHStack>
              </UiStack>
              <UiStack>
                <UiText variant={'body2'} color={'text.secondary'}>Due date</UiText>
                <UiText>{data.subscriptionBill.dueAt && dayjs(data.subscriptionBill.dueAt).format("DD MMMM YYYY")}</UiText>
              </UiStack>
            </UiSimpleGrid>
            <UiSimpleGrid columns={2} gap={4} bgColor={'#fff'} px={8} py={4}>
              <UiStack>
                <UiText variant={'body2'} color={'text.secondary'}>From</UiText>
                <UiText>{data.subscriptionBill.from && dayjs(data.subscriptionBill.from).format("DD MMMM YYYY")}</UiText>
              </UiStack>
              <UiStack>
                <UiText variant={'body2'} color={'text.secondary'}>To</UiText>
                <UiText>{data.subscriptionBill.to && dayjs(data.subscriptionBill.to).format("DD MMMM YYYY")}</UiText>
              </UiStack>
            </UiSimpleGrid>
          </BaseBlockList>
          {/*<UiHStack justifyContent={'flex-end'}>*/}
          {/*  <UiStack alignItems={'flex-end'} spacing={2}>*/}
          {/*    <BillStatus billState={data.subscriptionBill.state}/>*/}
          {/*    <UiHStack>*/}
          {/*      <UiText>{data.subscriptionBill.name}</UiText>*/}
          {/*    </UiHStack>*/}
          {/*    {!!data.subscriptionBill.description && (*/}
          {/*      <UiHStack>*/}
          {/*        <UiText color={'text.secondary'}>{data.subscriptionBill.description}</UiText>*/}
          {/*      </UiHStack>*/}
          {/*    )}*/}
          {/*    <UiHStack>*/}
          {/*      <UiText>{data.subscriptionBill.from && dayjs(data.subscriptionBill.from).format("DD MMMM YYYY")} -</UiText>*/}
          {/*      <UiText>{data.subscriptionBill.to && dayjs(data.subscriptionBill.to).format("DD MMMM YYYY")}</UiText>*/}
          {/*    </UiHStack>*/}
          {/*    <UiHStack>*/}
          {/*      <UiText>Due date {data.subscriptionBill.dueAt && dayjs(data.subscriptionBill.dueAt).format("DD MMMM YYYY")}</UiText>*/}
          {/*    </UiHStack>*/}
          {/*  </UiStack>*/}
          {/*</UiHStack>*/}
        </UiStack>
        <BaseDividerHorizontal height={2} />
        <UiHStack justifyContent={'flex-end'}>
          <CurrencyTag currency={'AUD'} />
        </UiHStack>
        <UiStack>
          <UiText variant={'title'}>Fees</UiText>
          {data.subscriptionBillItemList && Array.isArray(data.subscriptionBillItemList) && (
            <BaseBlockList>
              {data.subscriptionBillItemList.map((billItem) => {
                return (
                  <UiHStack justifyContent={'space-between'} key={billItem.id} px={8} py={6} bgColor={'#fff'}>
                    <UiText>{subscriptionBillItemCodeLabels[billItem.code] ?? '-'}</UiText>
                    <UiText>
                      <PriceDisplay price={(billItem.amount ?? 0) / 100}/>
                    </UiText>
                  </UiHStack>
                );
              })}
            </BaseBlockList>
          )}
        </UiStack>
        <UiStack alignItems={'flex-end'} spacing={2}>
          <UiHStack justifyContent={'space-between'} spacing={4}>
            <UiText fontWeight={600}>Subtotal</UiText>
            <UiText>
              <PriceDisplay price={((data.subscriptionBill.totalAmount ?? 0) - (data.subscriptionBill.taxAmount ?? 0)) / 100}/>
            </UiText>
          </UiHStack>
          <UiHStack justifyContent={'space-between'} spacing={4}>
            <UiText fontWeight={600}>GST</UiText>
            <UiText>
              <PriceDisplay price={(data.subscriptionBill.taxAmount ?? 0) / 100}/>
            </UiText>
          </UiHStack>
          <UiHStack justifyContent={'space-between'} spacing={4}>
            <UiText fontWeight={600}>Total amount</UiText>
            <UiText>
              <PriceDisplay price={(data.subscriptionBill.totalAmount ?? 0) / 100}/>
            </UiText>
          </UiHStack>
        </UiStack>
      </UiStack>
    );
  }
};

export default BillInfo;
