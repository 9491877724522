import { type FC } from 'react';
import { useSubscriptionPlanEditor } from '@/account/hook/useSubscriptionPlanEditor';
import {
  UiStack,
  UiText,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  type UiDrawerProps, UiGrid,
} from '@/lib/ui';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import PlanEditor from '@/account/component/Subscription/PlanEditor';
import { account } from '@/api';
import { useWindowSize } from '@/lib/hook';

export interface DrawerProps {
  isOpen: UiDrawerProps['isOpen']
  onClose: UiDrawerProps['onClose']
  subscription: account.Subscription
}

const EditSubscriptionDrawer: FC<DrawerProps> = ({
  isOpen,
  onClose,
  subscription,
}) => {
  const {tenant} = useTenantRoute();
  const subscriptionEditor = useSubscriptionPlanEditor(subscription)
  const { innerHeight: windowInnerHeight } = useWindowSize();

  return (
    <UiDrawer
      placement={'bottom'}
      size={'full'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerCloseButton size={'lg'} color={'primary.500'}/>
        <UiDrawerBody p={8} bgColor={'gray.100'}>
          {/*<UiStack alignItems={'stretch'} p={8}>*/}
          {/*  */}
          {/*</UiStack>*/}
          {/*<UiGrid templateColumns={'3fr 1fr'} gap={8}>*/}
          {/*  <UiStack overflowY={'scroll'} maxHeight={`${innerHeight - 80}px`}>*/}
          {/*    <PlanEditor subscriptionEditor={subscriptionEditor} />*/}
          {/*  </UiStack>*/}
          {/*  <UiStack></UiStack>*/}
          {/*</UiGrid>*/}
          <PlanEditor subscriptionEditor={subscriptionEditor} />
        </UiDrawerBody>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default EditSubscriptionDrawer;
