import { createContext, type FC, type PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { loadItem, removeItem, saveItem } from '@/api/local';
import { account } from '@/api';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { UiSpinner, UiStack } from '@/lib/ui';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import bugsnag from '@/bugsnag';
import { AdminUserSessionState } from '@/api/constant/adminUserSession';

// import { AdminUser } from '@/api/tenant/adminUserSession';

export interface ProviderAdminAuthProps extends PropsWithChildren {
  // config: AppAdminAuth
}

function getCurrentAdminAuthAccessTokenKey(): string {
  return 'ADMIN_USER_CURRENT_ACCESS_TOKEN';
}

export interface AdminAuth {
  // user: account.TenantAdminUserSession;
  // accessToken: string;
  user: account.AdminUserSessionUser | null
  adminUserSessionState: AdminUserSessionState
  permissionMaps: account.TenantPermissionMap[] | null
  accessToken: string
  client: string // We need this for tenant API. Comment it off for now.
}

export type Permission = [PermissionDomain, PermissionAction];

export function verifyPermissions(permissionMap: account.PermissionMap, permissions: Permission[]): boolean {
  for (const p of permissions) {
    if (!permissionMap.hasOwnProperty(p[0])) {
      return false;
    }
    if (!permissionMap[p[0]] || permissionMap[p[0]]?.length === 0) {
      return false;
    }
    if (p[1] !== '*' && !permissionMap[p[0]]?.includes(p[1])) {
      return false;
    }
  }
  return true;
}

const defaultAdminAuth: AdminAuth = {
  user: null,
  adminUserSessionState: AdminUserSessionState.Inactive,
  permissionMaps: null,
  accessToken: '',
  client: '',
};

interface AdminAuthContextValue {
  adminAuth: AdminAuth
  isLoading: boolean
  updateAdminAuth: (adminAuth: AdminAuth) => void
  persistAdminAuth: (adminAuthData: AdminAuth) => void
  hasPermissions: (tenantId: number, permissions: Permission[]) => boolean
  signOut: () => void
  isSignOutLoading: boolean
}

const AdminAuthContext = createContext<AdminAuthContextValue>({
  adminAuth: defaultAdminAuth,
  isLoading: false,
  updateAdminAuth: (adminAuth: AdminAuth) => {},
  persistAdminAuth: (adminAuthData: AdminAuth) => {},
  hasPermissions: (tenantId: number, permissions: Permission[]) => { return false; },
  signOut: () => {},
  isSignOutLoading: false,
});

export const ProviderAdminAuth: FC<ProviderAdminAuthProps> = ({
  children,
  ...props
}) => {
  const { reportToGlobal } = useApiErrorHandler();
  const { createGlobalApiAclRequest } = useGlobalApi();
  const queryClient = useQueryClient();
  const [adminAuth, setAdminAuth] = useState(() => {
    const _adminAuth = defaultAdminAuth;
      // Is there an existing admin user access token locally?
    const localItem = loadItem<string>({key: getCurrentAdminAuthAccessTokenKey()}, false);
    if (localItem.item) {
      // Use the access token from the local storage.
      _adminAuth.accessToken = localItem.item;
    }
    return _adminAuth;
  });

  const { mutate, isLoading: isSignOutLoading } = useMutation<account.SignOutResponse, Error, account.SignOutRequest>({
    mutationFn: async (data: account.SignOutRequest) => {
      return await account.signOut(createGlobalApiAclRequest())(data);
    },
    onSuccess: (result) => {
      removeItem({key: getCurrentAdminAuthAccessTokenKey()});
      setAdminAuth(defaultAdminAuth);
    },
    onError: (error) => {
      reportToGlobal(error);
    }
  });

  const signOut = useCallback(
    () => {
      mutate({});
    },
    [mutate]
  );

  const { createGlobalApiAclRequestByAuthToken } = useGlobalApi();

  // Load the admin user session once an accessToken is provided.
  const { data, isLoading, refetch, error } = useQuery<account.LoadCurrentAdminUserSessionResponse, Error>(
    [account.adminUserSessionQueryKey, {accessToken: adminAuth.accessToken}],
    async () => {
      return await account.loadCurrentSession(createGlobalApiAclRequestByAuthToken(adminAuth.accessToken))({});
    },
    {
      enabled: !!adminAuth.accessToken,
    }
  );

  // Update the admin user when the new admin user session is fetched from tbe backend..
  useEffect(() => {
    if (data) {
      const newAdminAuth = {...adminAuth};
      const adminUser = data.adminUser;
      if (adminUser) {
        newAdminAuth.user = data.adminUser;
      }
      newAdminAuth.adminUserSessionState = data.adminUserSessionState;
      if (data.permissionMaps) {
        newAdminAuth.permissionMaps = data.permissionMaps;
      }
      bugsnag.setUser(`${adminUser?.id}`, adminUser?.email, `${adminUser?.firstName} ${adminUser?.lastName}`);
      persistAdminAuth(newAdminAuth);
    } else {
      bugsnag.setUser(); // Bugsnap: set id = null to clear user
    }
  }, [data]);

  // Handle the API error.
  useEffect(() => {
    if (error) {
      reportToGlobal(error);
    }
  }, [error]);

  // Fetch the admin user session once a new accessToken is provided.
  useEffect(() => {
    if (adminAuth.accessToken) {
      refetch();
    }
  }, [adminAuth.accessToken]);

  const persistAdminAuth = useCallback(
    (adminAuthData: AdminAuth) => {
      saveItem({
        key: getCurrentAdminAuthAccessTokenKey(),
        item: adminAuthData.accessToken,
      });
      setAdminAuth(adminAuthData);
    },
    [setAdminAuth, saveItem]
  );

  const hasPermissions = useCallback(
    (tenantId: number, permissions: Permission[]): boolean => {
      if (!adminAuth.permissionMaps || !Array.isArray(adminAuth.permissionMaps)) {
        return false;
      }
      for (const tenantPermissionMap of adminAuth.permissionMaps) {
        if (tenantPermissionMap.tenantId === tenantId) {
          return verifyPermissions(tenantPermissionMap.permissionMap ?? [], permissions);
        }
      }
      return false;
    },
    [adminAuth.permissionMaps]
  );

  return (
    <AdminAuthContext.Provider
      value={{
        adminAuth,
        isLoading,
        updateAdminAuth: setAdminAuth,
        persistAdminAuth,
        hasPermissions,
        signOut,
        isSignOutLoading,
      }}
    {...props}
    >
      {/* Notice the isLoading is always true when enabled is false even the query isn't actually running. So need to combine the two here.*/}
      {isLoading && !!adminAuth.accessToken ? (
        <UiStack p={8}>
          <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
        </UiStack>
      ) : children}
    </AdminAuthContext.Provider>
  );
};

export interface AdminAuthHook extends AdminAuthContextValue {
  setAccessToken: (accessToken: string) => void
}

export function useAdminAuth(): AdminAuthHook {
  const {
    adminAuth,
    isLoading,
    updateAdminAuth,
    persistAdminAuth,
    signOut,
    isSignOutLoading,
    hasPermissions,
  } = useContext(AdminAuthContext) as Required<AdminAuthContextValue>;

  const setAccessToken = useCallback(
    (accessToken: string) => {
      const newAdminAuth = {...adminAuth, ...{accessToken}};
      persistAdminAuth(newAdminAuth);
    },
    [adminAuth, persistAdminAuth]
  );

  return {
    adminAuth,
    isLoading,
    updateAdminAuth,
    persistAdminAuth,
    setAccessToken,
    hasPermissions,
    signOut,
    isSignOutLoading,
  };
}
