import {
  type ApiRequest,
  deleteAPI,
  properCallApi,
  type ApiResponseSingle,
  type ApiResponse,
  callApi
} from '@/api/tenantClient';

import { type DiscountCode } from './discountCode';
import { type RegistrationRouteName } from '@/registration/pages';
import { type RegistrationStrategyType } from './attendeeCategory';
import { type InfoForm } from './infoForm';

export const registrationQueryKey = 'registration.registration';
export const registrationTableQueryKey = 'registration.table';
export const registrationIdQueryKey = 'registration.id';
export const registrationReportKey = 'registration.statistics';
export const cancelledRegistrationTableQueryKey = 'registration.cancelledRegistrationTable';

export type RegistrationSteps = RegistrationStep[];

export interface RegistrationStep {
  routeName: RegistrationRouteName
  isEnable: boolean
}

export interface RegistrationAuth {
  uid: string
  client: string
  token: string
}

export interface Registration {
  id: number
  email: string
  attendeeCategoryId: number
  attendeeCategory: {
    id: number
    name: string
  }
  discountId?: number
  tmpIdPhotocopy?: string
  idPhotocopy?: string
  idInfo?: string
  expiry: string
  termsAndConditionsAccepted: boolean
  privacyPolicyAccepted: boolean
  eventId: string
  paid: boolean
  orderHash?: string
  status: string
  event: {
    id: string
    name: string
  }
  createdAt: string
  personalInfoFormResponseCount: number
  additionalInfoFormResponseCount: number
  registrationSteps: RegistrationSteps
  registrationStrategy: RegistrationStrategyType
  discount: DiscountCode
}

export interface RegistrationTableRow {
  id: number
  uuid: number
  idInfo: string
  fullName: string
  email: string
  main_ticket: string
  status: string
  paid: string
  complete: string
  createdAt: string
  confirmDelegateTypeAt: string
  abandonedCart: boolean
  isGroupRegistration: boolean
  attendee: { name: string, email: string }
  event: { id: number, name: string }
  attendeeCategory: { name: string }
  country: string | null
  discountCode: string | null
}

export interface RegistrationTableQueryRequest {
  page: number
  filters?: any
  sorting?: any
  size: number
  search: string
  type?: string
}

export interface RegistrationWithToken extends Registration {
  auth: RegistrationAuth
}

export interface RegistrationQueryRequest {
  email: string
  eventId: string
}

export interface RegistrationCompleteRequest {
  registrationId: number
}

export function loadRegistration(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: RegistrationQueryRequest
  ): Promise<ApiResponseSingle<Registration>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/registrations/load_registration';
    request.payload = {
      email: params.email,
      eventId: params.eventId
    };
    return await properCallApi<ApiResponseSingle<Registration>>(request);
  };
}

export interface RegistrationSaveRequest extends Omit<Registration, 'id' | 'expiry' | 'registrationSteps'> {
  eventId: string
}

export interface RegistrationDeleteRequest {
  id: string
}

export function deleteRegistration(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: RegistrationDeleteRequest) => {
    request.method = 'DELETE';
    request.endpoint.path = '/api/v1/registrations/' + params.id;
    await deleteAPI(request);
  };
}

export function completeRegistration(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: RegistrationCompleteRequest
  ): Promise<ApiResponseSingle<Registration>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/registrations/complete';
    request.payload = {
      id: params.registrationId
    };
    return await properCallApi<ApiResponseSingle<Registration>>(request);
  };
};

export interface RegistrationUpdateRequest {
  registration: {
    id: number
    attendeeCategoryId?: number
    discountId?: number
    tmpIdPhotocopy?: string
    idInfo?: string
    termsAndConditionsAccepted: boolean
    privacyPolicyAccepted: boolean
  }
}

export function updateRegistration(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: RegistrationUpdateRequest
  ): Promise<ApiResponseSingle<Registration>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/registrations/update';
    request.payload = params;

    return await properCallApi<ApiResponseSingle<Registration>>(request);
  };
};

export function loadRegistrationsForTable(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: RegistrationTableQueryRequest
  ): Promise<ApiResponse<RegistrationTableRow>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/registrations/table';
    request.endpoint.query = {
      page: (params?.page ?? 0) + 1,
      filters: JSON.stringify(params.filters ?? []),
      sorting: JSON.stringify(params.sorting ?? []),
      size: params.size ?? 10,
      type: params?.type,
      ...params.search ? { search: params.search } : {}
    };

    return await properCallApi<ApiResponse<RegistrationTableRow>>(request);
  };
}

export interface FormResponse {
  id: number
  response: Record<string, string>
  createdAt: string
  infoForm?: InfoForm
}

export interface RegistrationInfo {
  id: number
  additionalFormReponses?: FormResponse
  attendeeCategory: { id: 2, name: 'Sponsors' }
  createdAt: string
  discount?: {
    discountCode: string
    name: string
    description: string
    idInfo?: string
    tmpIdPhotocopy?: string
  }
  email: string
  firstName: string
  lastName: string
  event: { id: number, name: string }
  personalFormResponses?: FormResponse
  status: string
  cancelledBy?: string
  cancelledAt?: string
}

export function loadRegistrationById(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: { id: number }
  ): Promise<ApiResponseSingle<RegistrationInfo>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/registrations/' + params.id;

    return await properCallApi<ApiResponseSingle<RegistrationInfo>>(request);
  };
}

export interface ExportEmailAsCSVRquest {
  model: 'Registration' | 'Order' | 'Refund' | 'Cancelled-Registration' | 'Form-Response' | 'Order-Finance'
  email: string
  eventId?: string
  attendeeCategoryId?: string
}

export function exportEmailAsCSV(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: ExportEmailAsCSVRquest
  ): Promise<ApiResponseSingle<{}>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/csv/request_csv_email';
    request.endpoint.query = params;

    return await properCallApi<ApiResponseSingle<{}>>(request);
  };
}

export interface RequestDetailsChangeRequest {
  registrationId: number
}

export interface RequestConfirmationEmailRequest {
  orderId: number
  forward_emails: string[]
}

export function requestDetailsChange(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: RequestDetailsChangeRequest
  ): Promise<ApiResponseSingle<void>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/registrations/request_details_change_email';
    request.payload = params;

    return await properCallApi<ApiResponseSingle<void>>(request);
  };
}

export function requestConfirmationEmail(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: RequestConfirmationEmailRequest
  ): Promise<ApiResponseSingle<void>> => {
    const { orderId, ...payload } = params;
    request.method = 'POST';
    request.endpoint.path = `/api/v1/admin/orders/${orderId}/resend_confirmation_email`;
    request.payload = payload;

    return await properCallApi<ApiResponseSingle<void>>(request);
  };
}

interface ChartDataItem {
  category?: string
  ticketType?: string
  country?: string
  name?: string
  count: number
}

export interface RegistrationReport {
  byAttendeeCategory?: ChartDataItem[]
  byMainTicketType?: ChartDataItem[]
  byCountry?: ChartDataItem[]
  byDiscountCode?: ChartDataItem[]
  byFunctionTicket?: ChartDataItem[]
  byWorkshopTicket?: ChartDataItem[]
  byTourTicket?: ChartDataItem[]
  byTotalTicket?: ChartDataItem[]
}

export function registrationStatistics(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: { type: string, eventId: string, paymentMethod?: string }
  ): Promise<ApiResponseSingle<RegistrationReport>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/registrations/statistics';
    request.endpoint.query = {
      type: params.type,
      event_id: params.eventId,
      payment_method: params.paymentMethod
    };

    return await properCallApi<ApiResponseSingle<RegistrationReport>>(request);
  };
};

export function exportRegistrationStatistics(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: { email: string, type: string }
  ): Promise<ApiResponseSingle<undefined>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/registrations/statistics_export';
    request.endpoint.query = {
      email: params.email,
      type: params.type
    };

    return await properCallApi<ApiResponseSingle<undefined>>(request);
  };
};
export interface ActivatedDataset {
  label: string
  values: string[][]
}
export interface ListActivatedChart {
  labels: string[]
  datasets: ActivatedDataset[]
}

export function registrationListActivated(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: { groupName: string, eventId: string, paymentMethod?: string }
  ): Promise<ApiResponseSingle<ListActivatedChart>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/registrations/list_activated';
    request.endpoint.query = {
      groupName: params.groupName,
      eventId: params.eventId,
      payment_method: params.paymentMethod
    };

    return await properCallApi<ApiResponseSingle<ListActivatedChart>>(request);
  };
};
