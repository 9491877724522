import {
  type RouteObject
} from 'react-router-dom';
import AAAAccess from '@/aaa/component/AAAAccess';
import AAAManageTenantPage from '@/aaa/manage/Tenant';
import AAAManageTenantInfoPage from '@/aaa/manage/TenantInfo';
import AAAManageSubscriptionPage from '@/aaa/manage/Subscription';
// import AAALoginEmailPasswordPage from 'src/admin/login/EmailPassword';

// All the route names should start with AAA to avoid conflicts with other modules (e.g. registration).
export interface AAARouteParams {
  // AAALoginEmailPassword: {}
  AAAManageTenant: {},
  AAAManageTenantInfo: {
    tenantId: string,
  },
  AAAManageSubscription: {},
}

export type AAARouteName = keyof AAARouteParams;

export const adminRoutes: Record<AAARouteName, RouteObject> = {
  // AAALoginEmailPassword: {
  //   path: '/admin/login/email-password',
  //   element: (<AAALoginEmailPasswordPage />)
  // },
  // AAAManageTenant: {
  //   path: '/admin/manage/tenant',
  //   element: (<AAAAccess><AAAManageTenantPage /></AAAAccess>)
  // },
  AAAManageTenant: {
    path: '/aaa/manage/tenant',
    element: <AAAAccess><AAAManageTenantPage/></AAAAccess>,
  },
  AAAManageTenantInfo: {
    path: '/aaa/manage/tenant-info/:tenantId',
    element: <AAAAccess><AAAManageTenantInfoPage/></AAAAccess>,
  },
  AAAManageSubscription: {
    path: '/aaa/manage/subscription',
    element: <AAAAccess><AAAManageSubscriptionPage/></AAAAccess>,
  },
};
