import { type FC, type ReactNode } from 'react';
import BaseRouterLink from '@/base/Router/Link';
import {
  UiBox,
  UiHStack,
  UiIconCheck,
  UiImage,
  UiStack,
  type UiStackProps,
  uiStyles, UiText
} from '@/lib/ui';

export interface AppCardProps extends UiStackProps {
  icon?: ReactNode
  name: string
  description?: string
  isSelected?: boolean
  navLink?: string
}

const AppCard: FC<AppCardProps> = ({
  icon,
  name,
  description = undefined,
  isSelected = false,
  navLink = undefined,
  ...props
}) => {
  return (
    // We need this UiStack to make sure the card will grow to its max width.
    <UiStack flexGrow={1} {...props}>
      <BaseRouterLink to={navLink ?? ''} style={{ cursor: 'inherit' }} target={navLink ? '_blank' : '_self'}>
        <UiHStack spacing={4} borderRadius={uiStyles.borderRadius}>
          {/* The UiBox here will make sure the icon will not shrink when window size changes. */}
          <UiBox>
            {!!icon && icon}
          </UiBox>
          <UiStack flexGrow={1}>
            <UiText variant='body1' fontWeight={600}>{name}</UiText>
            {description && (
              <UiText variant='body2' color={'text.secondary'}>{description}</UiText>
            )}
          </UiStack>
          {isSelected && (
            <UiIconCheck color={'green.500'} weight={'bold'} />
          )}
          {/* <UiIconCaretRight size={'36px'} color={'primary.500'}/> */}
        </UiHStack>
      </BaseRouterLink>
    </UiStack>
  );
};

export default AppCard;
