import { type FC, type ReactElement, useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  UiText,
  UiVStack,
  UiHStack,
  uiStyles,
  UiStack,
  UiButton,
  UiIconCurrencyCircleDollar,
  UiIconDotsNine,
  UiIconUsers,
  UiIconUsersFour,
  UiIconCalendarBlank, UiIconUserCircle,
  UiIconIdentificationBadge,
} from '@/lib/ui';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { useConfig } from '@/app/ProviderConfig';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useAdminAuth, type AdminAuthHook } from '@/app/ProviderAdminAuth';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
dayjs.extend(utc);

export interface Menu {
  name: string
  label: string
  url?: string
  icon?: ReactElement
  subMenu?: Menu[]
}

export type ActiveNodes = Array<Menu['name']>;

interface MenuParams {
  tenantId: number
  tenantCode: string
  hasPermissions: AdminAuthHook['hasPermissions']
  versionTag?: string
}

// To use generatePageUrl, it must wait until the routes are initialized (meaning the function generatePageUrl has to be used inside components).
const getMenus = (params: MenuParams): Menu[] => {
  // const dashboardMenu = {
  //   name: 'dashboard', // The name has to be unique on the same level.
  //   label: 'Dashboard',
  //   subMenu: [
  //     {
  //       name: 'modules',
  //       label: 'Modules',
  //       url: generatePageUrl('AccountDashboardModules', { tenantCode: params.tenantCode }),
  //       icon: (<UiIconDotsNine size={'2xl'} />)
  //     },
  //   ]
  // };

  const subscriptionMenu = {
    name: 'subscription', // The name has to be unique on the same level.
    label: 'Subscription',
    subMenu: [
      {
        name: 'plan',
        label: 'Plan',
        url: generatePageUrl('AccountSubscriptionPlan', { tenantCode: params.tenantCode }),
        icon: (<UiIconDotsNine size={'2xl'} />)
      },
      {
        name: 'billing',
        label: 'Billing',
        url: generatePageUrl('AccountModuleBill', { tenantCode: params.tenantCode }),
        icon: (<UiIconCurrencyCircleDollar size={'2xl'} />)
      }
    ]
  };
  if (!params.hasPermissions(params.tenantId, [[PermissionDomain.Account, PermissionAction.AdminUserAdmin]])) {
    subscriptionMenu.subMenu = [];
  }

  const usersAndAccessMenu = {
    name: 'userAccess', // The name has to be unique on the same level.
    label: 'Users',
    subMenu: [
      {
        name: 'user',
        label: 'Admin users',
        url: generatePageUrl('AccountAccessAdminUser', { tenantCode: params.tenantCode }),
        icon: (<UiIconUsers size={'2xl'} />)
      },
      {
        name: 'team',
        label: 'Teams',
        url: generatePageUrl('AccountAccessTeam', { tenantCode: params.tenantCode }),
        icon: (<UiIconUsersFour size={'2xl'} />)
      }
    ],
  };
  if (!params.hasPermissions(params.tenantId, [[PermissionDomain.Account, PermissionAction.AdminUserAdmin]])) {
    usersAndAccessMenu.subMenu = [];
  }

  // if (versionTag === 'future') {
  //   usersAndAccessMenu.subMenu.push({
  //     name: 'team',
  //     label: 'Teams',
  //     url: generatePageUrl('AccountAccessTeam', { tenantCode }),
  //     icon: (<UiIconUsersFour size={'2xl'} />)
  //   });
  // }

  const setupMenu = {
    name: 'setup', // The name has to be unique on the same level.
    label: 'Manage',
    subMenu: [
      {
        name: 'event',
        label: 'Events',
        url: generatePageUrl('AccountSetupEvent', { tenantCode: params.tenantCode }),
        icon: (<UiIconCalendarBlank size={'2xl'} />)
      },
      {
        name: 'accountSettings',
        label: 'My account',
        url: generatePageUrl('AccountManageAccountSettings', { tenantCode: params.tenantCode }),
        icon: (<UiIconUserCircle size={'2xl'} />)
      }
    ]
  };

  return [
    // dashboardMenu,
    setupMenu,
    subscriptionMenu,
    usersAndAccessMenu,
  ];
};

export interface SideNavProps {
  activeNodes?: ActiveNodes
  enableTransition?: boolean
}

const SideNav: FC<SideNavProps> = ({
  activeNodes = [],
  enableTransition = false
}) => {
  const appConfig = useConfig();
  const { tenant } = useTenantRoute();
  const { hasPermissions } = useAdminAuth();
  const transitionProps = enableTransition
    ? {
      transform: 'width, opacity',
      transitionDuration: '0.5s',
      transitionTimingFunction: 'ease-in-out'
    }
    : {};
  const menus = useMemo(
    () => {
      return getMenus({
        tenantId: tenant?.id ?? 0,
        tenantCode: tenant?.code ?? '',
        hasPermissions,
        versionTag: appConfig.versionTag,
      });
    }, [
      tenant?.code,
      appConfig.versionTag,
      hasPermissions,
    ]);

  return (
    <UiVStack
      alignItems={'stretch'}
      justifyContent={'flex-start'}
      flexGrow={1}
      p={8}
      overflowY={'scroll'}
      borderLeftRadius={uiStyles.bodyRadius}
      // borderRightRadius={uiStyles.borderRadius}
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        '&::-webkit-scrollbar-thumb': {
          display: 'none'
        }
      }}
      {...uiStyles.glass}
      {...transitionProps}
    >
      <UiStack width={'100%'}>
        {menus.map((menu) => {
          if (menu.subMenu && Array.isArray(menu.subMenu) && menu.subMenu.length > 0) {
            return (
              <UiStack key={menu.name}>
                <StandardSideMenu menu={menu} activeNodes={activeNodes} />
                {/* <UiBox height={4}/> */}
              </UiStack>
            );
          }
        })}
      </UiStack>
    </UiVStack>
  );
};

interface StandardSideMenuProps {
  menu: Menu
  activeNodes?: ActiveNodes
}

const StandardSideMenu: FC<StandardSideMenuProps> = ({
  menu,
  activeNodes = []
}) => {
  const _activeNodePath = useMemo<string>(
    () => {
      return activeNodes.join('-');
    },
    [activeNodes]
  );

  return (
    <UiStack pb={4}>
      <UiHStack justifyContent={'flex-start'} flexGrow={1} width={'100%'}>
        <UiText variant='title'>
          {menu.label}
        </UiText>
      </UiHStack>
      <UiVStack
        spacing={0}
        alignItems={'stretch'}
        // pl={8}
      >
        {menu.subMenu?.map((subMenu) => {
          const _nodePath = `${menu.name}-${subMenu.name}`;
          return (
            <BaseRouterLink key={subMenu.url} to={subMenu.url ?? ''}>
              <UiStack
                key={_nodePath}
                {...uiStyles.hover}
                bgColor={_activeNodePath === _nodePath ? 'primary.50' : 'transparent'}
                borderRadius={uiStyles.borderRadius}
                flexGrow={1}
                px={4}
              >
                <UiButton
                  leftIcon={subMenu.icon ?? undefined}
                  variant={'unstyled'}
                  flexGrow={1}
                  // bgColor={'transparent'}
                >
                  <UiText variant={'body1'} flex={1} display={'flex'}>{subMenu.label}</UiText>
                </UiButton>
              </UiStack>
            </BaseRouterLink>
          );
        })}
      </UiVStack>
    </UiStack>
  );
};

export default SideNav;
