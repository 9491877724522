/* eslint-disable @typescript-eslint/no-shadow */
import { type FC, useCallback, useEffect, useState } from 'react';
import {
  UiButton,
  UiHStack,
  UiIconCheck,
  UiIconEnvelopeSimple,
  UiIconPassword, UiIconWarningCircle,
  UiSpinner,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import UserNotFound from './UserNotFound';
import { AdminUserState, AdminUserType } from '@/api/constant/adminUser';
import AlreadyAccepted from '@/account/manage/AcceptInvite/AlreadyAccepted';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { account } from '@/api';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { useNavigate } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import BaseMessageBarStandard from '@/base/MessageBar/Standard';
import { useTenantRoute } from '@/account/hook/useTenantRoute';

export interface AcceptProps {
  tenant?: account.TenantData
  inviteToken: string
}

const Accept: FC<AcceptProps> = ({
  tenant,
  inviteToken,
}) => {
  // const [hasAccepted, setHasAccepted] = useState(false);
  // const [adminUser, setAminUser] = useState<account.AdminUserData | null>(null);
  // const [newAccessToken, setNewAccessToken] = useState('');
  const { adminAuth, setAccessToken } = useAdminAuth();
  const [errors, setErrors] = useState<string[]>([]);
  const { createGlobalApiRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const navigate = useNavigate();

  const toLogin = useCallback(
    () => {
      navigate(generatePageUrl('AccountLoginLoginEmail'));
    },
    [navigate]
  );

  const toSetPassword = useCallback(
    () => {
      if (tenant?.code) {
        navigate(generatePageUrl('AccountManageSetPassword', {tenantCode: tenant.code}));
      }
    },
    [navigate, tenant?.code]
  );

  const { mutate, isLoading } = useMutation<account.AdminUserAcceptInviteResponse, Error, account.AdminUserAcceptInviteRequest>({
    mutationFn: async (data: account.AdminUserAcceptInviteRequest) => {
      // console.log('----accept request: ', data);
      return await account.acceptAdminUserInvite(createGlobalApiRequest)(data);
    },
    onSuccess: (result) => {
      // console.log('----accept result: ', result);
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setErrors(result?.errors);
      } else {
        // setAccessToken(result.accessToken);
        setAccessToken(result.accessToken);
        setErrors([]);
        toSetPassword();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrors([error.message ?? 'Failed to invite the user.']);
    }
  });

  const acceptInvite = useCallback(
    () => {
      if (inviteToken) {
        mutate({
          inviteToken,
        });
      } else {
        setErrors(['Token invalid']);
      }
    },
    [inviteToken]
  );

  // // Call the backend API.
  // useEffect(() => {
  //   if (inviteToken) {
  //     mutate({
  //       inviteToken,
  //     });
  //   } else {
  //     setErrors(['Token invalid']);
  //   }
  // }, [inviteToken]);

  // The backend returns the new access token, set it to the local admin auth.
  // useEffect(() => {
  //   if (newAccessToken) {
  //     setAccessToken(newAccessToken);
  //   }
  // }, [newAccessToken]);

  return (
    <UiStack p={8}>
      {errors.length > 0 && (
        <BaseMessageBarStandard
          icon={<UiIconWarningCircle color={'red.500'} size={'3xl'}/>}
          text={(
            <UiText color={'red.500'}>
              {errors[0]}
            </UiText>
          )}
          button={(<UiButton onClick={toLogin} variant={'ghost'}>Back to login</UiButton>)}
        />
      )}
      <UiStack
        pt={240}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <UiStack
          minW={600}
          maxW={800}
          bgColor={'#fff'}
          borderRadius={uiStyles.borderRadius}
          spacing={8}
          py={8}
        >
          <UiHStack px={8}>
            <UiIconEnvelopeSimple color={'green.500'} size={'3xl'}/>
            <UiText>You are invited to join</UiText>
          </UiHStack>
          {!!tenant && (
            <UiStack px={8}>
              <UiStack
                bgColor={'blackAlpha.50'}
                borderRadius={uiStyles.borderRadius}
                p={8}
                py={6}
              >
                <UiText>
                  {tenant.name}
                </UiText>
              </UiStack>
            </UiStack>
          )}
          <UiHStack justifyContent={'flex-end'} px={8}>
            <UiButton onClick={acceptInvite} isLoading={isLoading}>Accept</UiButton>
          </UiHStack>
        </UiStack>
      </UiStack>
      {/*<BaseMessageBarStandard*/}
      {/*  icon={<UiIconEnvelopeSimple color={'green.500'} size={'3xl'}/>}*/}
      {/*  text={(*/}
      {/*    <UiText>*/}
      {/*      You are invited to join the team.*/}
      {/*    </UiText>*/}
      {/*  )}*/}
      {/*  button={(<UiButton onClick={acceptInvite} variant={'ghost'} isLoading={isLoading}>Accept</UiButton>)}*/}
      {/*/>*/}
    </UiStack>
  );

  // if (errors.length > 0) {
  //   return (
  //     <UiStack p={8}>
  //       <BaseMessageBarStandard
  //         icon={<UiIconWarningCircle color={'red.500'} size={'3xl'}/>}
  //         text={(
  //           <UiText color={'red.500'}>
  //             {errors[0]}
  //           </UiText>
  //         )}
  //         button={(<UiButton onClick={toLogin} variant={'ghost'}>Back to login</UiButton>)}
  //       />
  //     </UiStack>
  //   );
  // }

  // if (!adminAuth.user) {
  //   return (
  //     <UiStack p={8}>
  //       <BaseMessageBarStandard
  //         icon={<UiIconWarningCircle color={'red.500'} size={'3xl'}/>}
  //         text={(
  //           <UiText color={'red.500'}>
  //             User doesn't exist
  //           </UiText>
  //         )}
  //         button={(<UiButton onClick={toLogin} variant={'ghost'}>Back to login</UiButton>)}
  //       />
  //     </UiStack>
  //   );
  // }

  // return (
  //   <BaseMessageBarStandard
  //     icon={(<UiIconCheck color={'green.500'} size={'3xl'}/>)}
  //     text={(
  //       <UiText borderRadius={uiStyles.borderRadius}>
  //         Account activated.
  //       </UiText>
  //     )}
  //     button={(<UiButton onClick={toSetPassword} variant={'ghost'}>Go to account settings</UiButton>)}
  //   />
  // );
};

export default Accept;
