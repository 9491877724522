import { useTenantApi } from '@/account/hook/useTenantApi';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { registration } from '@/api';
import { type RegistrationSetup, type RegistrationSetupParams } from '@/api/registration';
import { type ApiResponseSingle } from '@/api/tenantClient';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import Uploader from '@/base/Form/Uploader';
import BaseMessageBarError from '@/base/MessageBar/Error';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useRegistrationSetupsQuery } from '@/registration/hook/useRegistrationSetupQuery';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Formik, type FormikHelpers } from 'formik';
import { type FC, useState } from 'react';
import * as Yup from 'yup';

export interface LabelsFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

interface FormData {
  invoiceTemplate: any
  refundReceipt: any
}

const formSchema = Yup.object().shape({
  invoiceTemplate: Yup.mixed(),
  refundReceipt: Yup.mixed()
});

const ManageTemplatesPageForm: FC<LabelsFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible
}) => {
  const { tenant } = useTenantRoute();
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const registrationSetup = useRegistrationSetupsQuery(eventId);
  const { createTenantAdminApiRequest } = useTenantApi();
  const { mutateAsync: mutateRegistrationSetup, isLoading } = useMutation<ApiResponseSingle<RegistrationSetup>, Error, RegistrationSetupParams>({
    mutationFn: async (data: RegistrationSetupParams) => {
      return await registration.updateRegistrationSetup(createTenantAdminApiRequest)(data, eventId);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.registrationSetupQueryKey, { eventId }] });
      onClose();
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the manage templates.']);
    }
  });

  const submitForm = async (values: FormData) => {
    await mutateRegistrationSetup({
      customInvoiceTemplate: values.invoiceTemplate,
      customRefundReceiptTemplate: values.refundReceipt
    });
  };

  return (
    <QueryContainer query={registrationSetup} loadingComponent={<></>}>
      {(registrationSetupData) => {
        return (
          <Formik
            initialValues={{
              invoiceTemplate: '',
              refundReceipt: ''
            }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={formSchema}
            onSubmit={async (
              values: FormData,
              { setSubmitting }: FormikHelpers<FormData>
            ) => {
              setSubmitting(true);
              await submitForm(values);
              setSubmitting(false);
            }}
          >
            {({ setFieldValue }) => {
              return (
                <BaseFormDrawer
                  isOpen={isVisible}
                  onClose={onClose}
                  title={'Manage Templates page'}
                  size={'xl'}
                  isLoading={isLoading}
                >
                  {saveErrors.length > 0 && (
                    <UiStack spacing={4} flexGrow={1} py={4}>
                      {saveErrors.map((error, index) => {
                        return (
                          <BaseMessageBarError key={index}>
                            {error}
                          </BaseMessageBarError>
                        );
                      })}
                    </UiStack>
                  )}
                  <BaseFormFieldGroup>
                    <Uploader
                      name="invoiceTemplate"
                      label="Invoice Template"
                      helperText='Please upload file under format: .tlf'
                      acceptFiles={ { 'application/octet-stream': ['.tlf'] } }
                      setFieldValue={setFieldValue}
                      defaultTemplateLink={`${tenant?.apiEndpoint}/thinreports/templates/invoice.tlf`}
                      defaultTemplateTitle='Download default invoice template'
                      defaultValue={registrationSetupData.customInvoiceTemplate} />
                    <Uploader
                      name="refundReceipt"
                      label="Refund Receipt"
                      helperText='Please upload file under format: .tlf'
                      acceptFiles={ { 'application/octet-stream': ['.tlf'] } }
                      setFieldValue={setFieldValue}
                      defaultTemplateLink={`${tenant?.apiEndpoint}/thinreports/templates/refund_receipt.tlf`}
                      defaultTemplateTitle='Download default refund receipt template'
                      defaultValue={registrationSetupData.customRefundReceiptTemplate} />
                  </BaseFormFieldGroup>
                </BaseFormDrawer>
              );
            }}
          </Formik>
        );
      }}
    </QueryContainer>
  );
};

export default ManageTemplatesPageForm;
