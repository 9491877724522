import { useTenantApi } from '@/account/hook/useTenantApi';
import { registration } from '@/api';
import { RegistrationMode } from '@/api/constant';
import { updateEvent, type Event, type EventUpdateRequest, type EventSettingConfirmValidate } from '@/api/registration';
import { type ApiResponseSingle, type ApiResponse } from '@/api/tenantClient';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import {
  UiButton,
  UiHStack,
  UiIconCaretDown,
  UiMenu,
  UiMenuButton,
  UiMenuList,
  UiSpinner,
  UiStack,
  UiText,
  uiStyles,
  type UiHStackProps, UiTag,
} from '@/lib/ui';
import ValidateEventSettingsModal from '@/registration/component/Modal/ValidateEventSettingsModal';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import { useEventSettingsQuery } from '@/registration/hook/useEventSettingsQuery';
import { useModal } from '@/registration/hook/useModal';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { MenuItemOption, MenuOptionGroup, Skeleton } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState, type FC } from 'react';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';

export interface SettingProps extends UiHStackProps {}

const Settings: FC<SettingProps> = () => {
  const { eventId, tenant } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const eventSettingQuery = useEventSettingsQuery(eventId);
  const { data: event } = useEventQuery(eventId);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();
  const [mode, setMode] = useState<RegistrationMode | undefined>(event?.registrationMode ?? undefined);
  const { selectedData: validateData, isOpen, onOpen, onClose } = useModal<EventSettingConfirmValidate>();

  const { mutateAsync: updateEventMutation, isLoading } = useMutation<ApiResponse<Event>, Error, EventUpdateRequest>({
    mutationFn: async (data: EventUpdateRequest) => {
      return await updateEvent(createTenantAdminApiRequest)(data);
    },
    onSuccess: (result) => {
      if (result.errors && result.errors.length > 0) {
        setSaveErrors(result.errors);
      } else {
        void queryClient.invalidateQueries({ queryKey: [registration.eventQueryKey] });
        setSaveErrors([]);
      }
    }
  });

  const { mutateAsync: validateEventSetting, isLoading: isValidating } = useMutation<ApiResponseSingle<registration.EventSettingValidate>, Error, { eventId: string }>({
    mutationFn: async (data: { eventId: string }) => {
      return await registration.validateEventSetting(createTenantAdminApiRequest)(data);
    }
  });

  const onUpdateEvent = useCallback((value?: RegistrationMode) => {
    void updateEventMutation({
      event: {
        id: eventId,
        registrationMode: value ?? mode
      }
    });
  }, [eventId, mode, updateEventMutation]);

  useEffect(() => {
    setMode(event?.registrationMode);
  }, [event?.registrationMode]);

  const onChange = async (value: string | string[]) => {
    const formatValue: string = typeof value === 'string' ? value : value.join(',');
    if (mode === 'Building' && ['Preview', 'Live'].includes(formatValue)) {
      const result = await validateEventSetting({ eventId });
      if ((result.item.errors && result.item.errors.length > 0) ||
        (result.item.warnings && result.item.warnings.length > 0)) {
        return onOpen({ ...result.item, mode: value as RegistrationMode });
      } else {
        setMode(value as RegistrationMode);
      }
    }
    onUpdateEvent(value as RegistrationMode);
  };

  return (
    <>
      <QueryContainer
        query={eventSettingQuery}
        loadingComponent={<Skeleton height='80px' />}
        errorMessage="Failed to load data."
      >
        {(eventSettings) => {
          return (
            <UiStack
            // justifyContent={'flex-start'}
              alignItems={'stretch'}
              // {...uiStyles.hover}
              // alignItems={'flex-start'}
              // justifyContent={'flex-start'}
              spacing={4}
              // minWidth={'300px'}
              // py={4}
              // bgColor={'#fff'}
              // flexGrow={1}
              borderRadius={uiStyles.borderRadius}
            >
              <UiStack flexGrow={1}>
                <UiHStack p={6} spacing={4} borderRadius={uiStyles.borderRadius} flexGrow={1} bgColor={'#fff'} justifyContent={'space-between'} shadow={uiStyles.cardShadow}>
                  <UiStack spacing={1}>
                    <UiText variant={'body1'}>Registration mode</UiText>
                    <UiText variant={'body2'} color={'text.secondary'}>This controls the status of the registration available to attendees.</UiText>
                  </UiStack>
                  <BaseAclContainer
                    tenantId={tenant?.id ?? 0}
                    permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}
                    noPermissionPlaceholder={(
                      <UiStack>
                        {isLoading ? (
                            <UiSpinner size="sm" color={'primary.500'} thickness='2px' />
                          ) : (
                          <UiTag size={'lg'}>{event?.registrationMode}</UiTag>
                        )}
                      </UiStack>
                    )}
                  >
                    <UiMenu>
                      <UiMenuButton
                        as={UiButton}
                        rightIcon={<UiIconCaretDown color={'gray.800'} />}
                        color={'text.primary'}
                        bgColor={'blackAlpha.100'}
                        _expanded={{
                          bgColor: 'primary.100'
                        }}
                      >
                        {isLoading ? <UiSpinner size="sm" color={'primary.500'} thickness='2px' /> : event?.registrationMode}
                      </UiMenuButton>
                      <UiMenuList>
                        <MenuOptionGroup
                          value={mode}
                          type='radio'
                          onChange={onChange}>
                          <MenuItemOption value={RegistrationMode.Building}>{RegistrationMode.Building}</MenuItemOption>
                          <MenuItemOption value={RegistrationMode.Preview}>{RegistrationMode.Preview}</MenuItemOption>
                          <MenuItemOption value={RegistrationMode.Live}>{RegistrationMode.Live}</MenuItemOption>
                          <MenuItemOption value={RegistrationMode.Closed}>{RegistrationMode.Closed}</MenuItemOption>
                        </MenuOptionGroup>
                      </UiMenuList>
                    </UiMenu>
                  </BaseAclContainer>
                </UiHStack>
              </UiStack>
              {saveErrors.length > 0 && (
                <UiStack spacing={2} flexGrow={1}>
                  {saveErrors.map((error) => {
                    return (
                      <UiText color={'red.500'} key={error}>
                        {error}
                      </UiText>
                    );
                  })}
                </UiStack>
              )}
            </UiStack>
          );
        }}
      </QueryContainer>
      <ValidateEventSettingsModal
        data={validateData}
        isOpen={isOpen}
        isLoading={isValidating}
        onClose={onClose}
        onUpdateEvent={onUpdateEvent} />
    </>
  );
};

export default Settings;
