import { type FC } from 'react';
// import { Player } from '@lottiefiles/react-lottie-player';
import {
  type UiBoxProps,
  uiStyles,
  UiIconUserCircle,
  UiHStack, UiIconUser,
} from '@/lib/ui';
import TenantSwitch from '@/base/App/TenantSwitch';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
// import smartButtonLottie from '@/lottie/white-robot.json';

export interface SmartButtonProps extends UiBoxProps {
}

const SmartButton: FC<SmartButtonProps> = (props) => {
  // const { tenant } = useTenantRoute();
  const { adminAuth } = useAdminAuth();

  return (
    <UiHStack
      spacing={6}
      // bgColor={'whiteAlpha.700'}
      // borderRadius={uiStyles.buttonRadius}
      // borderWidth={'2px'}
      // borderColor={'primary.500'}
      // borderStyle={'solid'}
      // px={4}
      // py={2}
    >
      {/*<UiStack*/}
      {/*  borderRightWidth={'1px'}*/}
      {/*  borderRightColor={'gray.100'}*/}
      {/*  borderRightStyle={'solid'}*/}
      {/*  py={4}*/}
      {/*  pr={6}*/}
      {/*>*/}
      {/*  <TenantSwitch/>*/}
      {/*</UiStack>*/}
      {/* <Player
        autoplay
        loop
        src={smartButtonLottie}
        style={{ height: '72px', width: '72px' }}
      /> */}
      <TenantSwitch/>
      {!!adminAuth.user && (
        <UiHStack
          p={2}
          borderRadius={'50%'}
          bgColor={'primary.50'}
          borderWidth={'2px'}
          borderStyle={'solid'}
          borderColor={'primary.300'}
          {...uiStyles.hoverShadow}
          {...props}
          // pr={2}
        >
          <UiIconUser size={'20px'} color={'primary.500'} weight={'bold'}/>
          {/*<UiText color={'primary.500'}>{adminAuth.user?.email}</UiText>*/}
        </UiHStack>
      )}
      {/* <UiIconUserCircle size={48} color={'primary.500'} weight={'thin'}/> */}
    </UiHStack>
  );
};

export default SmartButton;
