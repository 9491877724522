import { type FC, type ReactElement, useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  UiText,
  UiVStack,
  UiHStack,
  uiStyles,
  UiStack,
  UiButton,
  UiIconCurrencyCircleDollar,
  UiIconDotsNine,
  UiIconUsers,
  UiIconUsersFour,
  UiIconCalendarBlank
} from '@/lib/ui';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { useConfig } from '@/app/ProviderConfig';
dayjs.extend(utc);

export interface Menu {
  name: string
  label: string
  url?: string
  icon?: ReactElement
  subMenu?: Menu[]
}

export type ActiveNodes = Array<Menu['name']>;

// To use generatePageUrl, it must wait until the routes are initialized (meaning the function generatePageUrl has to be used inside components).
const getMenus = (versionTag: string): Menu[] => {
  const manageMenu = {
    name: 'manage', // The name has to be unique on the same level.
    label: 'Manage',
    subMenu: [
      {
        name: 'tenant',
        label: 'Workspaces',
        url: generatePageUrl('AAAManageTenant'),
        icon: (<UiIconDotsNine size={'2xl'} />)
      },
      {
        name: 'subscription',
        label: 'Subscriptions',
        url: generatePageUrl('AAAManageSubscription'),
        icon: (<UiIconCurrencyCircleDollar size={'2xl'} />)
      },
    ],
  };

  return [
    manageMenu,
  ];
};

export interface SideNavProps {
  activeNodes?: ActiveNodes
  enableTransition?: boolean
}

const SideNav: FC<SideNavProps> = ({
  activeNodes = [],
  enableTransition = false
}) => {
  const appConfig = useConfig();
  const transitionProps = enableTransition
    ? {
      transform: 'width, opacity',
      transitionDuration: '0.5s',
      transitionTimingFunction: 'ease-in-out'
    }
    : {};
  const menus = useMemo(
    () => {
      return getMenus(appConfig.versionTag);
    }, [
      appConfig.versionTag
    ]);

  return (
    <UiVStack
      alignItems={'stretch'}
      justifyContent={'flex-start'}
      flexGrow={1}
      p={8}
      overflowY={'scroll'}
      borderLeftRadius={uiStyles.bodyRadius}
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        '&::-webkit-scrollbar-thumb': {
          display: 'none'
        }
      }}
      {...uiStyles.glass}
      {...transitionProps}
    >
      <UiStack width={'100%'}>
        {menus.map((menu) => {
          return (
            <UiStack key={menu.name}>
              <AdminSideMenu menu={menu} activeNodes={activeNodes} />
              {/* <UiBox height={4}/> */}
            </UiStack>
          );
        })}
      </UiStack>
    </UiVStack>
  );
};

interface AdminSideMenuProps {
  menu: Menu
  activeNodes?: ActiveNodes
}

const AdminSideMenu: FC<AdminSideMenuProps> = ({
  menu,
  activeNodes = []
}) => {
  const _activeNodePath = useMemo<string>(
    () => {
      return activeNodes.join('-');
    },
    [activeNodes]
  );

  return (
    <UiStack pb={4}>
      <UiHStack justifyContent={'flex-start'} flexGrow={1} width={'100%'}>
        <UiText variant='title'>
          {menu.label}
        </UiText>
      </UiHStack>
      <UiVStack
        spacing={0}
        alignItems={'stretch'}
        // pl={8}
      >
        {menu.subMenu?.map((subMenu) => {
          const _nodePath = `${menu.name}-${subMenu.name}`;
          return (
            <BaseRouterLink key={subMenu.url} to={subMenu.url ?? ''}>
              <UiStack
                key={_nodePath}
                {...uiStyles.hover}
                bgColor={_activeNodePath === _nodePath ? 'primary.50' : 'transparent'}
                borderRadius={uiStyles.borderRadius}
                flexGrow={1}
                px={4}
              >
                <UiButton
                  leftIcon={subMenu.icon ?? undefined}
                  variant={'unstyled'}
                  flexGrow={1}
                  bgColor={'transparent'}
                >
                  <UiText variant={'body1'} flex={1} display={'flex'}>{subMenu.label}</UiText>
                </UiButton>
              </UiStack>
            </BaseRouterLink>
          );
        })}
      </UiVStack>
    </UiStack>
  );
};

export default SideNav;
