/* eslint-disable @typescript-eslint/no-shadow */
import { type FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  UiHStack,
  UiSpinner,
  UiStack,
} from '@/lib/ui';
import LoginSupportSmartButton from '@/account/component/Layout/LoginSupportSmartButton';
import LoginSmartWindow from '@/account/component/Layout/LoginSmartWindow';
import BaseLayout from '@/base/Layout';
import LoginBody from '@/account/component/Layout/LoginBody';
import Accept from './Accept';
import { useTenantRoute } from '@/account/hook/useTenantRoute';

const AcceptInvite: FC = () => {
  const { tenant, isLoading: isTenantLoading } = useTenantRoute();
  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get('t');
  // console.log('----invite token', inviteToken);

  return (
    <BaseLayout
      smartWindow={(<LoginSmartWindow />)}
      sideMenu={null}
      smartButton={(<UiStack />)}
      appSwitch={(<UiHStack flexGrow={1}/>)}
    >
      <LoginBody>
        {isTenantLoading ? (
          <UiStack p={8}>
            <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
          </UiStack>
        ) : (
          <Accept tenant={tenant} inviteToken={inviteToken ?? ''}/>
        )}
      </LoginBody>
    </BaseLayout>
  );
};

export default AcceptInvite;
