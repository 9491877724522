import { useEffect, useState, SetStateAction, Dispatch } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  MRT_PaginationState,
  MRT_SortingState,
} from 'mantine-react-table';
import { account } from '@/api';
import { useTenantApi } from '@/account/hook/useTenantApi';
import DataTable from './DataTable';
import { type ApiResponse } from '@/api/tenantClient';
import { CenteredSpinner } from '@/base/QueryContainer/QueryContainer';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import { UiSpinner, UiStack, uiStyles } from '@/lib/ui';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useGlobalApi } from '@/account/hook/useGlobalApi';

interface Params {
  filters: any
  sorting: any
  pagination: any
  search?: string
}

const loadTenants = ({ filters, sorting, pagination }: Params) => {
  const { createGlobalApiAclRequest } = useGlobalApi();
  return useQuery<account.TenantSearchResponse, Error>({
    queryKey: [account.tenantSearchQueryKey, pagination, filters, sorting],
    queryFn: async (): Promise<account.TenantSearchResponse> => {
      const searchRequest: account.TenantSearchRequest = {
        page: pagination.pageIndex + 1,
        limit: pagination.pageSize ?? 10,
      };
      if (sorting && sorting.length > 0) {
        searchRequest.orderByField = sorting['0'].id;
        searchRequest.isOrderedAsc = !sorting['0'].desc;
      }
      if (filters && filters.length > 0) {
        for (const col of filters) {
          if (col.id === 'name') {
            searchRequest.name = col.value;
          }
          if (col.id === 'state') {
            searchRequest.state = parseInt(col.value, 10);
          }
          if (col.id === 'code') {
            searchRequest.code = col.value;
          }
        }
      }
      return await account.searchTenants(createGlobalApiAclRequest())(searchRequest);
    },
    // enabled: !isTenantLoading,
    keepPreviousData: true,
    staleTime: 30_000
  });
};

const TenantTable = () => {
  const [filters, setFilters] = useState<any>([]);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const { reportToGlobal } = useApiErrorHandler();

  const { data, isLoading, error, isError, isFetching, refetch } = loadTenants({ filters, sorting, pagination });
  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );

  if (!data || isLoading) {
    return (
      <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
    );
  }

  return (
    <DataTable
      data={(data.tenantList && Array.isArray(data.tenantList)) ? data.tenantList : []}
      isLoading={isLoading}
      isError={isError}
      isFetching={isFetching}
      refetch={refetch}
      filters={filters}
      setFilters={setFilters}
      sorting={sorting}
      setSorting={setSorting}
      // search={search}
      // setSearch={setSearch}
      pagination={pagination}
      setPagination={setPagination}
      rowCount={data.total ?? 0}
    />
  );
};

export default TenantTable;
