import { type FC, ReactNode } from 'react';
import BaseRouterLink from '@/base/Router/Link';
import {
  UiHStack,
  type UiHStackProps,
  UiIconCheck,
  UiImage,
  UiStack,
  UiText,
  uiStyles, UiIconCaretCircleRight,
} from '@/lib/ui';
import BaseInfoBlockGroup from '@/base/InfoBlock/Group';
import BaseInfoBlockItem from '@/base/InfoBlock/Item';

export interface TeamCardProps extends UiHStackProps {
  name: string
  // numeOfMembers: number
  description?: string
  navLink?: string
}

const TeamCard: FC<TeamCardProps> = ({
  name,
  // numeOfMembers,
  description,
  navLink = undefined,
  ...props
}) => {
  return (
    // We need this UiStack to make sure the card will grow to its max width.
      <UiStack flexGrow={1} {...props}>
        <UiHStack
          flexGrow={1}
          bgColor={'#fff'}
          p={8}
          minWidth={360}
          alignItems={'stretch'}
          borderRadius={uiStyles.borderRadius}
          shadow={uiStyles.cardShadow}
        >
          <UiStack flexGrow={1} justifyContent={'space-between'}>
            <UiText variant={'title'}>{name}</UiText>
            {!!description && (
              <UiText variant={'body2'} color={'text.secondary'}>{description}</UiText>
            )}
          </UiStack>
          <UiStack justifyContent={'flex-end'} {...uiStyles.hover}>
            <BaseRouterLink to={navLink ?? ''} style={{display: 'flex'}}>
              <UiIconCaretCircleRight size={'3xl'} color={'primary.500'}/>
            </BaseRouterLink>
          </UiStack>
        </UiHStack>
      </UiStack>
  );
};

export default TeamCard;
