import { type FC } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import AdminSideNav from '@/aaa/component/Layout/SideNav';
import SmartWindow from '@/base/Layout/SmartWindow';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiHStack, UiStack, UiText } from '@/lib/ui';
import TenantTable from './TenantTable';
import NewTenant from './NewTenant';
import { LayoutProvider } from '@/base/Layout/LayoutProvider';
import BaseLayoutFullScreenToggle from '@/base/Layout/FullScreenToggle';

const Subscription: FC = () => {
  const { tenantCode } = useRegisterRoute();

  return (
    <LayoutProvider>
      <BaseLayout
        // brand={(<ToTenant/>)}
        smartWindow={(<SmartWindow/>)}
        sideMenu={(<AdminSideNav activeNodes={['manage', 'tenant']} />)}
        appSwitch={(<UiHStack flexGrow={1}/>)}
        // appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'admin'} />)}
      >
        <BaseLayoutBody
          isStandalone={false}
          // breadcrumbNodes={breadcrumbNodes}
        >
          <UiStack spacing={4}>
            <UiHStack justifyContent={'flex-start'} spacing={4}>
              <BaseLayoutFullScreenToggle/>
              <NewTenant/>
            </UiHStack>
            <TenantTable/>
          </UiStack>
        </BaseLayoutBody>
      </BaseLayout>
    </LayoutProvider>
  );
};

export default Subscription;
