import { type FC, useCallback, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDisclosure } from '@chakra-ui/react';
import {
  type UiBoxProps, UiButton, UiDivider,
  UiHStack,
  UiIconPencilSimple,
  UiSimpleGrid,
  UiSpinner,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppAccountCard from '@/base/App/AccountCard';
import BaseEventAppCard from '@/base/App/EventAppCard';
import BaseEventWebsiteCard from '@/base/App/EventWebsiteCard';
import BaseAbstractCard from '@/base/App/AbstractCard';
import BaseDigitalPosterCard from '@/base/App/DigitalPosterCard';
import BaseLiveStreamingCard from '@/base/App/LiveStreamingCard';
import BaseSponsorExhibitorCard from '@/base/App/SponsorExhibitorCard';
import BaseWebinarCard from '@/base/App/WebinarCard';
import ModulePermissionCard from '@/account/access/TeamInfo/ModulePermissionCard';
import { account } from '@/api';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { PermissionMap } from '@/api/account';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import { verifyPermissions } from '@/app/ProviderAdminAuth';
import { getModulePermission } from '@/account/access/TeamInfo/permissions';
import TeamMemberList from '@/account/access/TeamInfo/TeamMemberList';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BaseMessageBarError from '@/base/MessageBar/Error';
import PermissionList from './PermissionList';
import TeamForm from '@/account/access/Team/TeamForm';
import DeleteTeamConfirmation from '@/account/access/TeamInfo/DeleteTeamConfirmation';
import { useNavigate } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';
import BaseButtonPlainIconDelete from '@/base/Button/PlainIconDelete';

// export const apps = {
//   ACCOUNT: 1,
//   REGISTRATION: 2,
//   ABSTRACT: 3,
// }

export interface TeamManagementProps extends UiBoxProps {
  // permissionMap: account.PermissionMap
  teamId: number
}

// function hasModule(permissions: PermissionMap, module: PermissionDomain): boolean {
//   return permissions.hasOwnProperty(module);
// }

function hasPermission(permissions: PermissionMap, module: PermissionDomain, action: PermissionAction): boolean {
  return verifyPermissions(permissions, [[module, action]]);
}

const TeamManagement: FC<TeamManagementProps> = ({
  teamId,
}) => {
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isDeleteOpen, onToggle: onDeleteToggle } = useDisclosure();
  const navigate = useNavigate();
  const { tenant } = useTenantRoute();
  const toTeamsPage = useCallback(() => {
    navigate(generatePageUrl('AccountAccessTeam', { tenantCode: tenant?.code ?? '' }));
  }, [tenant?.code]);
  // const { isOpen, onToggle } = useDisclosure();
  const { data, isLoading, error } = useQuery<account.LoadTeamResponse, Error>(
    [account.teamQueryKey, {id: teamId}],
    async () => {
      return await account.loadTeam(createGlobalApiAclRequest())({id: teamId});
    },
  );

  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );
  // console.log(data);

  if (isLoading) {
    return (
      <UiStack p={8}>
        <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
      </UiStack>
    );
  }

  // console.log(data);
  if (data?.errors.length && data?.errors.length > 0) {
    return (
      <BaseMessageBarError>{data?.errors[0]}</BaseMessageBarError>
    );
  }

  if (data && data.team) {
    return (
      <>
        <UiStack spacing={4}>
          <UiStack spacing={4}>
            <UiText variant={'title'}>Team</UiText>
            <UiHStack
              p={8}
              bgColor={'#fff'}
              borderRadius={uiStyles.borderRadius}
              spacing={1}
              py={6}
              justifyContent={'space-between'}
              shadow={uiStyles.cardShadow}
            >
              <UiStack>
                <UiText variant={'body1'} fontWeight={600}>{data.team.name}</UiText>
                {!!data.team.description && (<UiText variant={'body2'} color={'text.secondary'}>{data.team.description}</UiText>) }
              </UiStack>
              <UiHStack spacing={8}>
                <BaseButtonPlainIconEdit onClick={onToggle}/>
                <BaseButtonPlainIconDelete onClick={onDeleteToggle}/>
                {/*<UiButton colorScheme={'red'} variant={'ghost'} onClick={onDeleteToggle}>Delete</UiButton>*/}
                {/*<UiButton variant={'ghost'} onClick={onToggle}>Edit</UiButton>*/}
              </UiHStack>
            </UiHStack>
          </UiStack>
          <BaseDividerHorizontal height={4}/>
          <UiHStack spacing={4}>
            <UiText variant={'title'}>Permissions</UiText>
            {/*<UiHStack spacing={1} {...uiStyles.hover} onClick={onToggle}>*/}
            {/*  <UiIconPencilSimple size={'2xl'} color={'primary.500'} weight={'bold'}/>*/}
            {/*  <UiText color={'primary.500'} variant={'title'}>Edit</UiText>*/}
            {/*</UiHStack>*/}
          </UiHStack>
          <PermissionList team={data.team}/>
          <BaseDividerHorizontal height={4}/>
          <TeamMemberList team={data.team}/>
        </UiStack>
        <TeamForm
          onClose={onToggle}
          isVisible={isOpen}
          team={data.team}
          onSaveSuccess={() => {}}
        />
        <DeleteTeamConfirmation
          isOpen={isDeleteOpen}
          onClose={onDeleteToggle}
          team={data.team}
          onDeleteSuccess={toTeamsPage}
        />
        {/*<PermissionsForm*/}
        {/*  permissions={data.team.permissions}*/}
        {/*  isVisible={isOpen}*/}
        {/*  onClose={onToggle}*/}
        {/*  onSaveSuccess={() => {}}*/}
        {/*/>*/}
      </>
    );
  }
};

export default TeamManagement;
