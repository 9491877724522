import { type FC, useEffect } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  type UiBoxProps,
  UiStack,
  UiText,
  UiSimpleGrid, UiSpinner,
} from '@/lib/ui';
import TeamCard from './TeamCard';
import TeamForm from './TeamForm';
import { generatePageUrl } from '@/app/pages';
import { useQuery } from '@tanstack/react-query';
import { account } from '@/api';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useTenantRoute } from '@/account/hook/useTenantRoute';

// export const apps = {
//   ACCOUNT: 1,
//   REGISTRATION: 2,
//   ABSTRACT: 3,
// }

export interface TeamListProps extends UiBoxProps {
}

const TeamList: FC<TeamListProps> = () => {
  // const { isOpen, onToggle } = useDisclosure();
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const { tenant } = useTenantRoute();

  const { data, isLoading, error } = useQuery<account.SearchTeamsResponse, Error>(
    [account.teamSearchQueryKey, {tenantId: tenant?.id}],
    async () => {
      return await account.searchTeams(createGlobalApiAclRequest())({
        tenantId: tenant?.id,
        page: 1,
        limit: 999, // Let's get all the teams at once.
      });
    },
  );

  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );

  if (isLoading) {
    return (
      <UiStack p={8}>
        <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
      </UiStack>
    );
  }

  if (data?.teamList && Array.isArray(data.teamList)) {
    return (
      <>
        <UiStack spacing={4} alignItems={'stretch'}>
          <UiSimpleGrid spacing={8} columns={3}>
            {data.teamList.map((team) => {
              return (
                <TeamCard
                  // onClick={onToggle}
                  key={team.id}
                  name={team.name}
                  description={team.description ?? ''}
                  // numeOfMembers={0}
                  navLink={generatePageUrl('AccountAccessTeamInfo', { tenantCode: tenant?.code ?? '', teamId: `${team.id}` })}
                />
              );
            })}
            {/*<TeamCard*/}
            {/*  onClick={onToggle}*/}
            {/*  name={'Admin'}*/}
            {/*  description={'Manages all modules'}*/}
            {/*  numeOfMembers={2}*/}
            {/*  navLink={generatePageUrl('AccountAccessTeamInfo', { tenantCode: 'dev', teamId: '1' })}*/}
            {/*/>*/}
            {/*<TeamCard*/}
            {/*  onClick={onToggle}*/}
            {/*  name={'Rego management'}*/}
            {/*  description={'Manages the registration module'}*/}
            {/*  numeOfMembers={3}*/}
            {/*  navLink={generatePageUrl('AccountAccessTeamInfo', { tenantCode: 'dev', teamId: '2' })}*/}
            {/*/>*/}
          </UiSimpleGrid>
        </UiStack>
        {/*<TeamForm*/}
        {/*  onClose={onToggle}*/}
        {/*  isVisible={isOpen}*/}
        {/*  onSaveSuccess={() => {}}*/}
        {/*/>*/}
      </>
    );
  }
};

export default TeamList;
