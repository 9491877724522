import { useEffect, useState, type FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  uiStyles,
  UiSpinner,
} from '@/lib/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import { type ImportAttemptsTableRow, loadImportAttemptsForTable, loadImportAttemptsForTableQueryKey } from '@/api/importer';
import { type ApiResponse } from '@/api/tenantClient';
import { useTenantApi } from '@/account/hook/useTenantApi';
import DataTable from './DataTable';

dayjs.extend(utc);
interface Params {
  filters: any
  sorting: any
  pagination: any
  search: string
  modelType: string
}

const useGetImportAttemptsForTable = ({ filters, sorting, pagination, search, modelType }: Params & { setPagination: React.Dispatch<React.SetStateAction<any>> }) => {
  const { createTenantAdminApiRequest, isLoading: isTenantLoading } = useTenantApi();
  return useQuery<ApiResponse<ImportAttemptsTableRow>, Error>({
    queryKey: [loadImportAttemptsForTableQueryKey, pagination, filters, sorting, search],
    queryFn: async (): Promise<ApiResponse<ImportAttemptsTableRow>> => {
      return await loadImportAttemptsForTable(createTenantAdminApiRequest)({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        modelType,
        filters,
        sorting,
        search
      });
    },
    keepPreviousData: true,
    refetchInterval: 5_000,
    enabled: !isTenantLoading,
  });
};
export interface ImportTableProps {
  modelType: string
}

const ImportTable: FC<ImportTableProps> = ({ modelType }) => {
  const [filters, setFilters] = useState<any>([]);
  const [sorting, setSorting] = useState<any>([]);
  const [search, setSearch] = useState<string>('');
  const [pagination, setPagination] = useState<any>({ pageIndex: 0, pageSize: 10 });
  const { reportToGlobal } = useApiErrorHandler();
  const { data, isLoading, isError, error, isFetching, refetch } = useGetImportAttemptsForTable({ filters, sorting, pagination, search, setPagination, modelType });
  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );
  if (!data || isLoading) {
    return (
      <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
    );
  }

  if (data.items && Array.isArray(data.items)) {
    return (
      <DataTable
        data={data.items}
        isLoading={isLoading}
        isError={isError}
        isFetching={isFetching}
        refetch={refetch}
        filters={filters}
        setFilters={setFilters}
        sorting={sorting}
        setSorting={setSorting}
        search={search}
        setSearch={setSearch}
        pagination={pagination}
        setPagination={setPagination}
        rowCount={data.pagination?.total ?? 0}
        modelType={modelType}
      />
    );
  } else {
    return (
      <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>
        No registration import attempts
      </BaseMessageBarInfo>
    );
  }
};

export default ImportTable;
