/* eslint-disable react/no-unescaped-entities */
import { type FC, type ReactNode, useCallback, useState } from 'react';
import {
  UiButton,
  UiHStack, UiIconEnvelopeSimple, UiIconUser, UiIconUserCircle,
  UiModal,
  UiModalBody,
  UiModalCloseButton,
  UiModalContent,
  UiModalOverlay, UiSpinner, UiStack, uiStyles,
  UiText,
} from '@/lib/ui';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { account } from '@/api';
import BaseMessageBarError from '@/base/MessageBar/Error';

export interface RemoveMemberConfirmationProps {
  teamId: number
  member: account.LoadTeamResponseUser
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

const RemoveMemberConfirmation: FC<RemoveMemberConfirmationProps> = ({
  teamId,
  member,
  isOpen,
  onClose,
  onConfirm,
}) => {
  const [ errors, setErrors ] = useState<string[]>([]);
  const { createGlobalApiAclRequest } = useGlobalApi();
  const queryClient = useQueryClient();
  const { reportToGlobal } = useApiErrorHandler();

  const { mutate, isLoading } = useMutation<account.RemoveTeamAdminUsersResponse, Error, account.RemoveTeamAdminUsersRequest>({
    mutationFn: async (data: account.RemoveTeamAdminUsersRequest) =>
      await account.removeTeamAdminUsers(createGlobalApiAclRequest())(data),
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setErrors(result?.errors);
      } else {
        setErrors([]);
        // Reload the team.
        void queryClient.invalidateQueries({ queryKey: [account.teamQueryKey, {id: teamId}]});
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrors([error.message ?? 'Failed to remove the admin user from the team.']);
    }
  });

  const onYes = useCallback(
    () => {
      mutate({
        teamId,
        adminUserIds: [member.id],
      })
    },
    [teamId, member]
  );

  return (
    <>
      <UiModal
        onClose={onClose}
        size={'xs'}
        isOpen={isOpen}
        motionPreset={'scale'}
        // scrollBehavior={'inside'}
        isCentered={true}
      >
        <UiModalOverlay />
        <UiModalContent minW={480}>
          {/*<UiModalCloseButton alignSelf={'flex-end'} p={4} size={'lg'} />*/}
          <UiModalBody>
            <UiStack
              px={2}
              py={6}
              flexGrow={1}
              alignItems={'stretch'}
              // enableDivider={true}
              spacing={4}
            >
              {errors.length > 0 && (
                <UiStack spacing={4} flexGrow={1}>
                  {errors.map((error, index) => {
                    return (
                      <BaseMessageBarError key={index}>
                        {error}
                      </BaseMessageBarError>
                    );
                  })}
                </UiStack>
              )}
              <UiText>
                Continue to remove this user out of this team?
              </UiText>
              <UiHStack bgColor={'blackAlpha.50'} borderRadius={uiStyles.borderRadius} spacing={2} p={4} alignItems={'center'}>
                <UiIconUserCircle size={'3xl'}/>
                <UiText fontWeight={600}>{member.email}</UiText>
              </UiHStack>
              <UiHStack flexGrow={1} justifyContent={'space-between'}>
                <UiButton variant={'ghost'} color={'red.500'} pl={0} py={0} onClick={onClose}>No</UiButton>
                {isLoading ? (
                  <UiSpinner size={'sm'} color={'primary.500'}/>
                ) : (
                  <UiButton onClick={onYes}>Yes</UiButton>
                )}
              </UiHStack>
            </UiStack>
          </UiModalBody>
        </UiModalContent>
      </UiModal>
    </>
  );
};

export default RemoveMemberConfirmation;
