import { type FC } from 'react';
import RGL, { WidthProvider, type ReactGridLayoutProps } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

// const ResponsiveReactGridLayout = WidthProvider(Responsive);
const ReactGridLayout = WidthProvider(RGL);

// const layout: Layout[] = [
//   {x: 0, y: 0, w: 2, h: 1, maxH: 1, minW: 2, maxW: 2, i: 'basicInfoTitle'},
//   {x: 0, y: 1, w: 1, h: 1, maxH: 1, i: 'firstName'},
//   {x: 1, y: 1, w: 1, h: 1, maxH: 1, i: 'lastName'},
//   {x: 0, y: 2, w: 1, h: 1, maxH: 1, i: 'gender'},
//   {x: 1, y: 2, w: 1, h: 1, minW: 1, maxW: 2, maxH: 1, i: 'dob'},
// ];

export interface GridProps extends ReactGridLayoutProps {
}

const Grid: FC<GridProps> = (props) => {
  return (
    <ReactGridLayout
      // layout={layout}
      rowHeight={112}
      margin={[16, 48]}
      containerPadding={[0, 0]}
      // onLayoutChange={onLayoutChange}
      // onDrop={onDrop}
      // WidthProvider option
      // measureBeforeMount={false}
      cols={2}
      // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
      // and set `measureBeforeMount={true}`.
      // useCSSTransforms={this.state.mounted}
      // compactType={compactType}
      // preventCollision={!compactType}
      isDroppable={true}
      isResizable={true}
      {...props}
    />
  );
};

export default Grid;
