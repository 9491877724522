import { type ElementType, type FC, type ReactNode, useEffect, useMemo, useState } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import {
  UiStack,
  UiFlex,
  UiHStack,
  uiStyles,
  UiBox,
  UiHStackProps, UiText,
} from '@/lib/ui';
import { menuWidth, topNavHeight, bodyPadding, topBg } from './viewport';
// import { useWindowSize } from '@/lib/hook';
import BaseTransitionSlideLeft from '@/base/Transition/SlideLeft';
import BaseSmartButton from '@/base/Layout/SmartButton';
import SmartWindowDrawer from '@/base/Layout/SmartWindowDrawer';
import { useWindowScrollY, useWindowSize } from '@/lib/hook';
import GlobalMessages from '@/base/GlobalMessages';
import Brand from '@/base/Layout/Brand';
import {LayoutProvider, useLayout} from '@/base/Layout/LayoutProvider';
import BaseLayoutFullScreenToggle from '@/base/Layout/FullScreenToggle';

export interface LayoutProps {
  children: ReactNode
  smartWindow?: ReactNode
  breadcrumb?: ReactNode
  appSwitch?: ReactNode
  brand?: ReactNode
  isSmartWindowVisible?: boolean
  sideMenu?: ReactNode
  smartButton?: ReactNode
  disableTopNav?: boolean
  MainTransition?: ElementType | null
}

const bgGradients = [
  'linear(160deg, brand.50 10%, gray.200 10%, gray.200 90%, gray.200 10%, brand.50 10%)',
  'linear(160deg, brand.50 10%, gray.50 20%, gray.200 100%)',
  'radial(100% 100% at 0% 15%, brand.50 10%, gray.200 35%, gray.200 50%, gray.200 90%, gray.200 100%)',
  'radial(35% 100% at 0% 35%, brand.50 25%, gray.200 100%)', // This is good for top bar only
];

interface SideNavContainer extends UiHStackProps {
  isFixed: boolean
  isVisible?: boolean
}

const SideNavContainer: FC<SideNavContainer> = ({
  isFixed,
  isVisible = true,
  ...props
}) => {
  if (isFixed) {
    return (
      <>
        <UiStack
          // minWidth={isVisible ? menuWidth : 0}
          // maxWidth={isVisible ? menuWidth : 0}
          // opacity={isVisible ? 1 : 0}
          minWidth={menuWidth}
          maxWidth={menuWidth}
          flexGrow={1}
          position={'fixed'}
          left={bodyPadding}
          top={0}
          minH={`calc(100vh - ${4 * bodyPadding}px)`}
          zIndex={isVisible ? 999 : -1}
          opacity={isVisible ? 1 : 0}
          transition={'all .2s ease-in-out'}
          {...props}
        />
        <UiStack
          // minWidth={isVisible ? menuWidth : 0}
          // maxWidth={isVisible ? menuWidth : 0}
          // opacity={isVisible ? 1 : 0}
          minWidth={menuWidth}
          maxWidth={menuWidth}
          opacity={isVisible ? 1 : 0}
          transition={'all .2s ease-in-out'}
        />
      </>
    );
  }
  return (
    <UiStack
      // minWidth={isVisible ? menuWidth : 0}
      // maxWidth={isVisible ? menuWidth : 0}
      // opacity={isVisible ? 1 : 0}
      minWidth={menuWidth}
      maxWidth={menuWidth}
      flexGrow={1}
      transition={'all .2s ease-in-out'}
      {...props}
    />
  )
}

export const Layout: FC<LayoutProps> = ({
  children,
  smartWindow = undefined,
  MainTransition = BaseTransitionSlideLeft,
  disableTopNav = false,
  sideMenu = undefined,
  appSwitch = undefined,
  brand = undefined,
  breadcrumb = undefined,
  smartButton = undefined
}) => {
  const { isOpen: isSmartWindowVisible, onToggle: onSmartWindowToggle } = useDisclosure();
  const [ isSideNavFixed, setIsSideNavFixed ] = useState(false);
  const [topNavOpacity, setTopNavOpacity] = useState(1);
  const [topNavScale, setTopNavScale] = useState(1);
  const windowScrollY = useWindowScrollY();
  const { isFullScreen } = useLayout();

  useEffect(
    () => {
      setTopNavOpacity(1 - 2 * windowScrollY / (topNavHeight));
      setTopNavScale(Math.min(1 - (0.15 * windowScrollY) / (topNavHeight), 1));
    },
    [
      windowScrollY
    ]
  );

  useEffect(() => {
    if (windowScrollY >= topNavHeight) {
      setIsSideNavFixed(true);
    } else {
      setIsSideNavFixed(false);
    }
  }, [windowScrollY]);

  // const { clientWidth: windowWidth } = useWindowSize();
  // const mainWindowTranslate = useMemo(
  //   () => {
  //     const props = {
  //       transform: 'translateX(0)',
  //       transitionDuration: '0.5s',
  //       transitionTimingFunction: 'ease-in-out'
  //     };
  //     if (isSmartWindowVisible) {
  //       props.transform = `translateX(-${smartWindowWidth}px)`;
  //     }
  //     return props;
  //   },
  //   [isSmartWindowVisible]
  // );
  const { clientWidth: windowClientWidth } = useWindowSize();

  return (
    <>
      <GlobalMessages />
      <UiStack
        spacing={0}
        // p={4}
        minHeight={'100vh'}
        alignItems={'stretch'}
        // bgGradient={bgGradients[2]}
        // pb={`${topNavHeight - 32}px`}
        // bg={'url("/image/bg/bg-gradient-green-indigo-orange.jpg") no-repeat center'}
        // bg={'center / cover no-repeat url("/image/bg/bg-gradient-indigo-orange-red.jpg")'}
        // bgGradient='radial(indigo.100, indigo.600)'
      >
        {!disableTopNav &&  (
          <UiStack
            // mt={`${topNavHeight}px`}
            flexGrow={1}
            maxH={isFullScreen ? 0 : `${topBg + topNavHeight}px`}
            minH={isFullScreen ? 0 : `${topBg + topNavHeight}px`}
            // bgColor={'brand.50'}
            position={'fixed'}
            width={'100%'}
            top={0}
            left={0}
            bgGradient={'radial(100% 200% at 0% 30%, brand.50 25%, gray.200 100%)'}
            transition={'all .2s ease-in-out'}
            // borderBottomRadius={uiStyles.borderRadius * 2}
          >
            <UiStack
              position={'fixed'}
              width={'100%'}
              height={`${topNavHeight}px`}
              opacity={topNavOpacity}
              transform={`scale(${topNavScale})`}
              px={8}
              top={0}
              left={0}
              // py={8}
              justifyContent={'center'}
              // borderBottomColor={'gray.300'}
              // borderBottomWidth={'1px'}
              // borderBottomStyle={windowScrollY > 0 ? 'none' : 'solid'}
              // shadow={windowScrollY > 0 ? 'none' : 'md'}
              // bgGradient={bgGradients[3]}
              // bgGradient={'radial(65% 200% at 0% 30%, brand.50 25%, #fff 100%)'}
              // bgGradient={'linear(to right, brand.50, #fff)'}
              // bgColor={'brand.50'}
              // borderRadius={uiStyles.borderRadius}
              // zIndex={99999}
              // bgColor={'#FFF'}
              // shadow={uiStyles.cardShadow}
            >
              <UiHStack
                justifyContent={'space-around'}
                alignItems={'center'}
              >
                {brand ?? (<Brand />)}
                {appSwitch ?? (<UiHStack />)}
                {smartButton ? (
                  <UiBox
                    {...uiStyles.hover}
                    onClick={onSmartWindowToggle}
                    flexGrow={1}
                  >
                    {smartButton}
                  </UiBox>
                ) : (
                  <BaseSmartButton onClick={onSmartWindowToggle} />
                )}
              </UiHStack>
            </UiStack>
          </UiStack>
        )}
        <UiHStack
          justifyContent={'flex-start'}
          alignItems={'stretch'}
          overflowX={'hidden'}
          flexGrow={1}
          zIndex={99}
          mt={isFullScreen ? 0 : `${topNavHeight}px`}
          // mt={`-${topOffset}px`}
          spacing={0.5}
          // mb={-4}
          p={bodyPadding}
          pt={0}
          transition={'all .2s ease-in-out'}
        >
          {sideMenu ? (
            <>
              <SideNavContainer isFixed={isSideNavFixed} isVisible={!isFullScreen}>
                {sideMenu}
              </SideNavContainer>
            </>
          ) : null}
          <UiFlex
            flexGrow={1}
            // overflowX={'auto'}
            ml={isFullScreen ? -menuWidth : 0}
            transition={'all .2s ease-in-out'}
          >
            <UiStack
              flexGrow={1}
              spacing={0}
              position={'relative'}
            >
              {/*<UiStack*/}
              {/*  position={'absolute'}*/}
              {/*  left={'-6px'}*/}
              {/*  top={'-6px'}*/}
              {/*  zIndex={999}*/}
              {/*>*/}
              {/*  <BaseLayoutFullScreenToggle/>*/}
              {/*</UiStack>*/}
              { MainTransition ? (
                <MainTransition
                  // shadow={'sm'}
                >
                  {children}
                </MainTransition>
              ) : (
                <UiFlex
                  flexGrow={1}
                >
                  {children}
                </UiFlex>
              )}
            </UiStack>
          </UiFlex>
          {!!smartWindow && (
            <SmartWindowDrawer
              isOpen={isSmartWindowVisible}
              onClose={onSmartWindowToggle}
            >
              {smartWindow}
            </SmartWindowDrawer>
          )}
        </UiHStack>
      </UiStack>
    </>
  );
};

export default Layout;
