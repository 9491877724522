import { type FC, useEffect } from 'react';
import {
  useQuery
} from '@tanstack/react-query';
import {
  UiTable,
  UiThead,
  UiTbody,
  UiTr,
  UiTh,
  UiTd,
  UiTableContainer,
  uiStyles,
  UiStack,
  UiText,
  UiHStack,
  UiIconEye,
  UiSpinner,
} from '@/lib/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { account } from '@/api';
import PriceDisplay from '@/account/component/Subscription/PriceDisplay';
import BillStatus from '@/account/subscription/Payment/BillStatus';
import ViewBillInfoButton from '@/account/subscription/Payment/ViewBillInfoButton';
import BaseMessageBarInfo from '@/base/MessageBar/Info';

dayjs.extend(utc);

export interface PaymentTableProps {
}

const thStyles = {
  // fontSize: 'lg',
  px: 8,
  py: 6
};
const tdStyles = {
  px: 8,
  py: 6
};

const PaymentTable: FC<PaymentTableProps> = () => {
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const { tenant } = useTenantRoute();
  // const { adminAuth } = useAdminAuth();

  const { data, isLoading, error } = useQuery<account.SubscriptionBillSearchTenantBillsResponse, Error>(
    [account.subscriptionBillSearchQueryKey, {tenantId: tenant?.id}],
    async () => {
      return await account.searchTenantSubscriptionBills(createGlobalApiAclRequest())({
        tenantId: tenant?.id ?? 0,
        page: 1,
        orderByField: 'id',
        isOrderedAsc: false,
        limit: 30, // Let's get all the teams at once.
      });
    },
    {
      enabled: !!tenant?.id,
    }
  );

  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );

  if (isLoading) {
    return (
      <UiStack p={8}>
        <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
      </UiStack>
    );
  }

  if (data?.subscriptionBillList && Array.isArray(data?.subscriptionBillList) && data?.subscriptionBillList?.length > 0) {
    return (
      <>
        <UiHStack flexGrow={1} justifyContent={'space-between'} pb={2}>
          <UiText variant={'title'}>Invoices</UiText>
          {/*<UiStack {...uiStyles.hover}>*/}
          {/*  <UiIconFunnel size={'4xl'} color={'primary.500'}/>*/}
          {/*</UiStack>*/}
        </UiHStack>
        <UiTableContainer shadow={uiStyles.cardShadow} borderRadius={uiStyles.borderRadius}>
          <UiTable variant='simple' bgColor={'#fff'} borderRadius={uiStyles.borderRadius}>
            {/* <UiTableCaption>Imperial to metric conversion factors</UiTableCaption> */}
            <UiThead>
              <UiTr>
                <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'}>Invoice id</UiText></UiTh>
                <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'}>Status</UiText></UiTh>
                <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'}>Amount</UiText></UiTh>
                <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'}>Due date</UiText></UiTh>
                <UiTh {...thStyles}><UiText fontWeight={600} variant={'body2'}></UiText></UiTh>
              </UiTr>
            </UiThead>
            <UiTbody>
              {data?.subscriptionBillList.map((bill, rowIndex) => {
                return (
                  <UiTr key={bill.id} {...uiStyles.hover}>
                    {/*{row.map((col, colIndex) => {*/}
                    {/*  return (*/}
                    {/*    <UiTd {...tdStyles} key={colIndex}>{col}</UiTd>*/}
                    {/*  );*/}
                    {/*})}*/}
                    <UiTd {...tdStyles}>{bill.id}</UiTd>
                    <UiTd {...tdStyles}><BillStatus billState={bill.state}/></UiTd>
                    <UiTd {...tdStyles}>
                      <PriceDisplay price={bill.totalAmount / 100}/>
                    </UiTd>
                    <UiTd {...tdStyles}>
                      {bill.dueAt ? dayjs(bill.dueAt).format("DD MMMM YYYY") : '-'}
                    </UiTd>
                    <UiTd {...tdStyles}>
                      <ViewBillInfoButton subscriptionBillId={bill.id}/>
                    </UiTd>
                  </UiTr>
                );
              })}
            </UiTbody>
          </UiTable>
        </UiTableContainer>
      </>
    );
  } else {
    return (
      <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>
        No invoice found.
      </BaseMessageBarInfo>
    );
  }
};

export default PaymentTable;
