import { type FC, useState } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
// import Filter from './Filter';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import OrderTable from './OrderTable';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiBox, UiFlex, UiHStack, UiStack, UiText } from '@/lib/ui';
import BaseLayoutFullScreenToggle from '@/base/Layout/FullScreenToggle';
import { LayoutProvider } from '@/base/Layout/LayoutProvider';
import SubscribeButton from '@/base/ScheduleReport/SubscribeButton';
import { reportSubscriptionName } from '@/api/reports/reportSubscriptions';

const Order: FC = () => {
  const { tenantCode } = useRegisterRoute();

  return (
    <LayoutProvider>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        sideMenu={(<AdminSideNav activeNodes={['manage', 'orders']} tenantCode={tenantCode} />)}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
      >
        <BaseLayoutBody
          isStandalone={false}
          // breadcrumbNodes={breadcrumbNodes}
        >
          <UiHStack justifyContent={'space-between'} spacing={4}>
            <BaseLayoutFullScreenToggle />
            <UiText variant={'title'}>Orders</UiText>
            <UiFlex flexGrow={1} justifyContent={'flex-end'}>
              <SubscribeButton reportName={reportSubscriptionName.orderReport} />
            </UiFlex>
          </UiHStack>
          <UiStack height={4} />
          <OrderTable />
        </BaseLayoutBody>
      </BaseLayout>
    </LayoutProvider>
  );
};

export default Order;
