import { type ApiRequest, type ApiResponse, callApi, properCallApi, type ApiResponseSingle } from '@/api/tenantClient';
import { type FieldMetadata } from '@/base/FormBuilder/Field';
import { type Layout } from 'react-grid-layout';
import { FormBuilderSelectOptionType } from '@/api/constant/formBuilder';
import { type RegistrationMode } from '@/api/constant/registration';

export const eventSettingsQueryKey = 'registration.eventSettings';

interface EventSettingsSegment {
  isSet?: boolean // If the step is checked by the admin user.
}

export interface EventSettingsGroupRegistration extends EventSettingsSegment {
  isEnabled: boolean
}

export interface EventSettingsAttendeeCategory extends EventSettingsSegment {
  total: number
}

export interface EventSettingsDiscountCode extends EventSettingsSegment {
  total: number
}

export interface EventSettingsPersonalInformation extends EventSettingsSegment {
  totalFields: number
  fieldsLayout?: Layout[]
  fieldsMetadata?: FieldMetadata[]
}

export interface EventSettingsMainEventTicket extends EventSettingsSegment {
  total: number
}

export interface EventSettingsFunctionTicket extends EventSettingsSegment {
  isEnabled: boolean // This should be removed.
  total: number
}

export interface EventSettingsWorkshopTicket extends EventSettingsSegment {
  isEnabled: boolean // This should be removed.
  total: number
}

export interface EventSettingsTourTicket extends EventSettingsSegment {
  isEnabled: boolean // This should be removed.
  total: number
}

export interface EventSettingsAdditionalInformation extends EventSettingsSegment {
  isEnabled: boolean // This should be removed.
  totalFields: number // This should be changed to totalForms. We will show the total number of forms in the step card.
  // The follow 2 fields will also need to be replaced by an array since we will have multiple forms.
  fieldsLayout?: Layout[]
  fieldsMetadata?: FieldMetadata[]
  total: number
}

export interface EventSettingsPrivacy extends EventSettingsSegment {
  isEnabled: boolean
  privacyPolicyUrl?: string | undefined
  termsAndConditionsUrl?: string | undefined
}

export interface EventSettingsDelegateType extends EventSettingsSegment {
  total: number
}

export interface EventSettingsBadge extends EventSettingsSegment {
  isEnabled: boolean
}

export interface OtherSettings extends EventSettingsSegment {
  bookingCom: {
    bookingComUrl?: string
  }
}

export interface EventSettingsPaymentMethod extends EventSettingsSegment {
  displayTaxInclusivePrices: boolean
}

export interface PageMetadata {
  attendeeCategory: {
    title?: string
    description?: string
    discountCodeLabel?: string
    idPhotocopyLabel?: string
    idInformationLabel?: string
  }
  personalInfo: {
    title: string
    description: string
  }
  mainTicket: {
    title: string
    description: string
  }
  functionTicket: {
    title: string
    description: string
  }
  workshopTicket: {
    title: string
    description: string
  }
  tourTicket: {
    title: string
    description: string
  }
  additionalInfo: {
    title: string
    description: string
  }
  building: {
    message: string
  }
  closed: {
    message: string
  }
}

export interface Surcharge {
  label: string
  invoice: {
    type: 'fixed' | 'percentage'
    value: number
  }
  creditCard: {
    type: 'fixed' | 'percentage'
    value: number
  }
}

export interface EventSettings {
  groupRegistration: EventSettingsGroupRegistration
  attendeeCategory: EventSettingsAttendeeCategory
  discountCode: EventSettingsDiscountCode
  delegateType: EventSettingsDelegateType
  personalInformation: EventSettingsPersonalInformation
  mainEventTicket: EventSettingsMainEventTicket
  functionTicket: EventSettingsFunctionTicket
  workshopTicket: EventSettingsWorkshopTicket
  tourTicket: EventSettingsTourTicket
  additionalInformation: EventSettingsAdditionalInformation
  privacy: EventSettingsPrivacy
  merchandise: EventSettingsSegment
  badge: EventSettingsBadge
  checkout: EventSettingsSegment
  event: EventSettingsSegment
  paymentMethod: EventSettingsPaymentMethod
  pageMetadata: PageMetadata
  otherSettings: OtherSettings
  surcharge: Surcharge
}

export interface EventSetting {
  id: string
  eventId: string
  config: EventSettings
}

export type EventSettingsSegmentName = keyof EventSettings;

interface EventSettingQueryRequest {
  eventId: string | number
}

export function loadEventSettings(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (params: EventSettingQueryRequest): Promise<ApiResponseSingle<EventSetting>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/event_settings';
    request.endpoint.query = {
      eventId: params.eventId
    };
    return await properCallApi<ApiResponseSingle<EventSetting>>(request);
  };
}

export interface EventSettingsUpdateRequest {
  eventId?: string
  config: Partial<EventSettings>
}

export function updateEventSettings(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: EventSettingsUpdateRequest): Promise<ApiResponse<EventSetting>> => {
    request.method = 'PUT';
    request.endpoint.path = '/api/v1/event_settings/' + params.eventId;
    request.payload = {
      eventId: params.eventId,
      eventSettings: params
    };
    return await callApi<EventSetting>(request);
  };
}

export const initPersonalFieldsLayout: Layout[] = [
  { x: 0, y: 0, w: 2, h: 1, maxH: 1, minW: 2, maxW: 2, i: 'basicInfoSectionBreak' },
  { x: 1, y: 1, w: 1, h: 1, maxH: 1, i: 'firstName' },
  { x: 0, y: 2, w: 1, h: 1, maxH: 1, i: 'lastName' },
  { x: 1, y: 2, w: 1, h: 1, maxH: 1, i: 'gender' },
  { x: 0, y: 3, w: 1, h: 1, minW: 1, maxW: 2, maxH: 1, i: 'dob' }
];
export const initPersonalFieldsMetadata: FieldMetadata[] = [
  {
    id: 'basicInfoSectionBreak',
    type: 'sectionBreak',
    label: 'Basic info',
    description: 'Please tell us about yourself.',
    canDelete: true,
    canEdit: true
  },
  {
    id: 'firstName',
    type: 'input',
    label: 'First name',
    length: 100,
    helpText: '',
    isRequired: true,
    canEdit: false,
    canDelete: false
  },
  {
    id: 'lastName',
    type: 'input',
    label: 'Last name',
    length: 50,
    helpText: '',
    isRequired: true,
    canEdit: false,
    canDelete: false
  },
  {
    id: 'gender',
    type: 'select',
    label: 'Gender',
    optionType: FormBuilderSelectOptionType.Custom,
    options: 'Male\nFemale\nChoose not to tell',
    helpText: '',
    isRequired: true,
    isMultiple: false,
    canEdit: true,
    canDelete: true
  },
  {
    id: 'dob',
    type: 'date',
    label: 'Date of birth',
    isRequired: true,
    helpText: '',
    canEdit: true,
    canDelete: true
  }
];

export const initAdditionalFieldsLayout: Layout[] = [
  { x: 0, y: 0, w: 2, h: 1, maxH: 1, minW: 2, maxW: 2, i: 'basicInfoSectionBreak' },
  { x: 1, y: 1, w: 1, h: 1, maxH: 1, i: 'position' },
  { x: 0, y: 2, w: 1, h: 1, maxH: 1, i: 'organisation' }
];
export const initAdditionalFieldsMetadata: FieldMetadata[] = [
  {
    id: 'basicInfoSectionBreak',
    type: 'sectionBreak',
    label: 'Basic info',
    description: 'Please provide additional information.',
    canDelete: true,
    canEdit: true
  },
  {
    id: 'position',
    type: 'input',
    label: 'Position',
    length: 100,
    helpText: '',
    isRequired: true,
    canEdit: true,
    canDelete: true
  },
  {
    id: 'organisation',
    type: 'input',
    label: 'Organisation',
    length: 50,
    helpText: '',
    isRequired: true,
    canEdit: true,
    canDelete: true
  }
];

export function initializeEventSettings(): EventSettings {
  return {
    groupRegistration: { isSet: false, isEnabled: false },
    attendeeCategory: { isSet: false, total: 0 },
    discountCode: { isSet: false, total: 0 },
    delegateType: { isSet: false, total: 0 },
    personalInformation: {
      isSet: false,
      totalFields: 0,
      fieldsLayout: initPersonalFieldsLayout,
      fieldsMetadata: initPersonalFieldsMetadata
    },
    mainEventTicket: { isSet: false, total: 0 },
    functionTicket: { isSet: false, isEnabled: false, total: 0 },
    workshopTicket: { isSet: false, isEnabled: false, total: 0 },
    tourTicket: { isSet: false, isEnabled: false, total: 0 },
    additionalInformation: {
      total: 0,
      isSet: false,
      isEnabled: false,
      totalFields: 0,
      fieldsLayout: initAdditionalFieldsLayout,
      fieldsMetadata: initAdditionalFieldsMetadata
    },
    privacy: { isSet: false, isEnabled: false, privacyPolicyUrl: undefined, termsAndConditionsUrl: undefined },
    merchandise: { isSet: false },
    badge: { isSet: false, isEnabled: false },
    checkout: { isSet: false },
    event: { isSet: false },
    paymentMethod: { isSet: false, displayTaxInclusivePrices: false },
    pageMetadata: {
      attendeeCategory: {
        title: '',
        description: '',
        idPhotocopyLabel: '',
        idInformationLabel: ''
      },
      personalInfo: {
        title: '',
        description: ''
      },
      mainTicket: {
        title: '',
        description: ''
      },
      functionTicket: {
        title: '',
        description: ''
      },
      workshopTicket: {
        title: '',
        description: ''
      },
      tourTicket: {
        title: '',
        description: ''
      },
      additionalInfo: {
        title: '',
        description: ''
      },
      building: {
        message: ''
      },
      closed: {
        message: ''
      }
    },
    otherSettings: {
      bookingCom: {
        bookingComUrl: '',
      }
    },
    surcharge: {
      label: 'Processing fee',
      invoice: {
        type: 'fixed',
        value: 0
      },
      creditCard: {
        type: 'fixed',
        value: 0
      }
    },
  };
}

export interface EventSettingsCreateRequest extends Partial<EventSettings> {
  eventId: string | number
}

export function createEventSettings(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: EventSettingsCreateRequest): Promise<ApiResponse<EventSetting>> => {
    request.method = 'POST';
    request.endpoint.path = '/api/v1/event_settings/';
    request.payload = {
      eventId: params.eventId,
      eventSettings: {
        eventId: params.eventId,
      }
    };
    return await callApi<EventSetting>(request);
  };
}

export interface EventSettingsUpdateIsSetRequest {
  eventId: string
  key: keyof EventSettings
  isSet: boolean
}

export function updateIsSetEventSetting(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (params: EventSettingsUpdateIsSetRequest): Promise<ApiResponse<EventSetting>> => {
    request.method = 'PUT';
    request.endpoint.path = '/api/v1/event_settings/update_is_set';
    request.payload = {
      eventId: params.eventId,
      key: params.key,
      isSet: params.isSet
    };
    return await callApi<EventSetting>(request);
  };
}

export interface EventSettingValidate {
  warnings: string[]
  errors: string[]
  mode?: string | undefined
}

export interface EventSettingConfirmValidate extends EventSettingValidate {
  mode?: RegistrationMode | undefined
}

export function validateEventSetting(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: { eventId: string }
  ): Promise<ApiResponseSingle<EventSettingValidate>> => {
    request.method = 'GET';
    request.endpoint.path = `/api/v1/event_settings/validate?event_id=${params.eventId}`;

    return await properCallApi<ApiResponseSingle<EventSettingValidate>>(request);
  };
};
