import BaseTextList from '@/base/Text/List';
import BaseTextListItem from '@/base/Text/ListItem';
import {
  UiButton,
  UiHStack,
  UiIconStar,
  UiImage,
  uiStyles,
  type UiHStackProps, UiStack, UiText, UiIconCaretRight,
  UiBox,
} from '@/lib/ui';
import {
  useDisclosure
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useCallback, type FC } from 'react';
import EventDetail from './EventDetail';
import { registration } from '@/api';
import { type ApiResponseSingle } from '@/api/tenantClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { eventListQueryKey } from '@/api/registration';

dayjs.extend(utc);

type NewType = registration.Event;

export interface EventRowProps extends UiHStackProps {
  event: NewType
  isSelected?: boolean
}

export interface ToggleFavouriteRequest {
  eventId: string
}

const EventRow: FC<EventRowProps> = ({
  event,
  isSelected = false,
  ...props
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { mutate: mutateToggleFavourite } = useMutation<ApiResponseSingle<any>, Error, ToggleFavouriteRequest>({
    mutationFn: async (dataParams: ToggleFavouriteRequest) => {
      return await registration.toggleFavourite(createTenantAdminApiRequest)(dataParams);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [eventListQueryKey] });
    }
  });

  const handleToggleFavourite = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      void mutateToggleFavourite({
        eventId: event.id
      });
    },
    [event.id, mutateToggleFavourite]
  );

  return (
    <>
      <UiHStack
        py={4}
        px={8}
        bgColor={'#fff'}
        // px={8}
        flexGrow={1}
        spacing={4}
        justifyContent={'space-between'}
        // bgColor={isSelected ? 'primary.50' : 'transparent'}
        {...uiStyles.hoverFlex({ bgColor: 'gray.50' })}
        {...props}
        onClick={onToggle}
      >
        <UiHStack>
          {event.menuLogo && (
            <UiImage
              src={event.menuLogo}
              borderRadius={uiStyles.borderRadius}
              maxWidth={'48px'}
            />
          )}
          <UiStack spacing={1}>
            <UiText>{event.name}</UiText>
            {/* <BaseTextListItem variant='body2' color={'text.secondary'}>{text.trimText(data.description, 50)}</BaseTextListItem> */}
            {event.startDate && event.endDate ? (
              <UiText variant='caption' color={'text.secondary'}>
                {dayjs(event.startDate).format('DD MMM YYYY')} - {dayjs(event.endDate).format('DD MMM YYYY')}
              </UiText>
            ) : null}
          </UiStack>
        </UiHStack>
        <UiBox>
          <UiIconCaretRight color={'primary.500'} size={'2xl'} />
        </UiBox>
        {/* <EventStateTag state={data.state}/> */}
        {/* {isSelected && (<UiIconCheck size={'3xl'} weight={'bold'} color={'green.500'}/>)} */}
      </UiHStack>
      {/*<UiButton onClick={handleToggleFavourite} background={'white'} colorScheme={'white'}>*/}
      {/*  {event.favourite ? (*/}
      {/*    <UiIconStar />*/}
      {/*  ) : (*/}
      {/*    <UiIconStar weight="duotone" />*/}
      {/*  )}*/}
      {/*</UiButton>*/}
      <EventDetail
        isOpen={isOpen}
        event={event}
        onClose={onToggle}
      />
    </>
  );
};

export default EventRow;
