import { type FC } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
import AdminSideNav from '@/aaa/component/Layout/SideNav';
import SmartWindow from '@/base/Layout/SmartWindow';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { UiHStack, UiStack, UiText } from '@/lib/ui';
import SubscriptionTable from './SubscriptionTable';
import { LayoutProvider } from '@/base/Layout/LayoutProvider';
import BaseLayoutFullScreenToggle from '@/base/Layout/FullScreenToggle';

const Subscription: FC = () => {
  const { tenantCode } = useRegisterRoute();

  return (
    <LayoutProvider>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        sideMenu={(<AdminSideNav activeNodes={['manage', 'subscription']} />)}
        appSwitch={(<UiHStack flexGrow={1}/>)}
        // appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'admin'} />)}
      >
        <BaseLayoutBody
          isStandalone={false}
          // breadcrumbNodes={breadcrumbNodes}
        >
          <UiHStack justifyContent={'flex-start'} spacing={4}>
            <BaseLayoutFullScreenToggle/>
            <UiText variant={'title'}>Subscriptions</UiText>
          </UiHStack>
          <UiStack height={4}/>
          <SubscriptionTable/>
        </BaseLayoutBody>
      </BaseLayout>
    </LayoutProvider>

  );
};

export default Subscription;
