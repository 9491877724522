import { type FC, type ReactElement, useMemo } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  UiText,
  UiVStack,
  UiHStack,
  uiStyles,
  UiStack,
  UiIconBrowsers,
  UiButton,
  UiIconShoppingCart,
  UiIconIdentificationBadge,
  UiIconChartLine,
  UiIconReceiptX,
  UiIconHandCoins,
  UiIconListChecks,
} from '@/lib/ui';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { UiIconUpload } from '@/lib/ui/Icon/UiIconUpload';
dayjs.extend(utc);

export interface Menu {
  name: string
  label: string
  url?: string
  icon?: ReactElement
  subMenu?: Menu[]
}

export type ActiveNodes = Array<Menu['name']>;

// To use generatePageUrl, it must wait until the routes are initialized (meaning the function generatePageUrl has to be used inside components).
const getMenus = (tenantCode: string): Menu[] => {
  return [
    // {
    //   name: 'dashboard', // The name has to be unique on the same level.
    //   label: 'Dashboard',
    //   subMenu: [
    //     {
    //       name: 'overview',
    //       label: 'Overview',
    //       url: generatePageUrl('RegistrationDashboardOverview', { tenantCode }),
    //       icon: (<UiIconInfo size={'2xl'} />)
    //     }
    //   ]
    // },
    {
      name: 'manage', // The name has to be unique on the same level.
      label: 'Manage',
      subMenu: [
        {
          name: 'events',
          label: 'Registration setup',
          url: generatePageUrl('RegistrationManageEvent', { tenantCode }),
          icon: (<UiIconBrowsers size={'2xl'} />)
        },
        {
          name: 'orders',
          label: 'Orders',
          url: generatePageUrl('RegistrationManageOrder', { tenantCode }),
          icon: (<UiIconShoppingCart size={'2xl'} />)
        },
        {
          name: 'registrations',
          label: 'Registrations',
          url: generatePageUrl('RegistrationManageTable', { tenantCode }),
          icon: (<UiIconIdentificationBadge size={'2xl'} />)
        }
      ]
    },
    {
      name: 'report', // The name has to be unique on the same level.
      label: 'Reports',
      subMenu: [
        {
          name: 'orders',
          label: 'Orders',
          url: generatePageUrl('RegistrationReportOrder', { tenantCode }),
          icon: (<UiIconChartLine size={'2xl'} />)
        },
        {
          name: 'registrations',
          label: 'Registrations',
          url: generatePageUrl('RegistrationReportRegistration', { tenantCode }),
          icon: (<UiIconChartLine size={'2xl'} />)
        },
        {
          name: 'cancelled-registrations',
          label: 'Cancellations',
          url: generatePageUrl('CancelledRegistrationManageTable', { tenantCode }),
          icon: (<UiIconReceiptX size={'2xl'} />)
        },
        {
          name: 'refunds',
          label: 'Refunds',
          url: generatePageUrl('RefundManageTable', { tenantCode }),
          icon: (<UiIconHandCoins size={'2xl'} />)
        },
        {
          name: 'form-responses',
          label: 'Form responses',
          url: generatePageUrl('FormResponseManageTable', { tenantCode }),
          icon: (<UiIconListChecks size={'2xl'} />)
        }
        // {
        //   name: 'events',
        //   label: 'Registration setup',
        //   url: generatePageUrl('RegistrationManageEvent', { tenantCode }),
        //   icon: (<UiIconBrowsers size={'2xl'} />)
        // },
      ]
    },
    {
      name: 'importers', // The name has to be unique on the same level.
      label: 'Importers',
      subMenu: [
        {
          name: 'registration',
          label: 'Registration',
          url: generatePageUrl('RegistrationImporterRegistration', { tenantCode }),
          icon: (<UiIconUpload size={'2xl'} />)
        },
      ],
    },
    {
      name: 'settings', // The name has to be unique on the same level.
      label: 'Settings',
      subMenu: [
        {
          name: 'paymentOptions',
          label: 'Payment methods',
          url: generatePageUrl('RegistrationSettingsPaymentOptions', { tenantCode }),
          icon: (<UiIconIdentificationBadge size={'2xl'} />)
        },
      ],
    },
  ];
};

export interface SideNavProps {
  tenantCode: string
  activeNodes?: ActiveNodes
  enableTransition?: boolean
}

const AdminSideNav: FC<SideNavProps> = ({
  tenantCode,
  activeNodes = [],
  enableTransition = false
}) => {
  const transitionProps = enableTransition
    ? {
      transform: 'width, opacity',
      transitionDuration: '0.5s',
      transitionTimingFunction: 'ease-in-out'
    }
    : {};

  const menus = useMemo(() => {
    return getMenus(tenantCode);
  }, [tenantCode]);

  return (
    <UiVStack
      alignItems={'stretch'}
      justifyContent={'flex-start'}
      flexGrow={1}
      p={8}
      borderLeftRadius={uiStyles.bodyRadius}
      overflowY={'scroll'}
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none'
        },
        '&::-webkit-scrollbar-thumb': {
          display: 'none'
        }
      }}
      {...uiStyles.glass}
      {...transitionProps}
    >
      <UiStack width={'100%'}>
        {menus.map((menu) => {
          return (
            <UiStack key={menu.name}>
              <SellerSideMenu menu={menu} activeNodes={activeNodes} />
              {/* <UiBox height={4}/> */}
            </UiStack>
          );
        })}
      </UiStack>
    </UiVStack>
  );
};

interface SellerSideMenuProps {
  menu: Menu
  activeNodes?: ActiveNodes
}

const SellerSideMenu: FC<SellerSideMenuProps> = ({
  menu,
  activeNodes = []
}) => {
  const _activeNodePath = useMemo<string>(
    () => {
      return activeNodes.join('-');
    },
    [activeNodes]
  );

  return (
    <UiStack pb={4}>
      <UiHStack justifyContent={'flex-start'} flexGrow={1} width={'100%'}>
        <UiText variant='title'>
          {menu.label}
        </UiText>
      </UiHStack>
      <UiVStack
        spacing={0}
        alignItems={'stretch'}
        // pl={8}
      >
        {menu.subMenu?.map((subMenu) => {
          const _nodePath = `${menu.name}-${subMenu.name}`;
          return (
            <BaseRouterLink key={subMenu.url} to={subMenu.url ?? ''}>
              <UiStack
                key={_nodePath}
                {...uiStyles.hover}
                bgColor={_activeNodePath === _nodePath ? 'primary.50' : 'transparent'}
                borderRadius={uiStyles.borderRadius}
                flexGrow={1}
                px={4}
              >
                <UiButton
                  leftIcon={subMenu.icon ?? undefined}
                  variant={'unstyled'}
                  flexGrow={1}
                  bgColor={'transparent'}
                >
                  <UiText variant={'body1'} flex={1} display={'flex'}>{subMenu.label}</UiText>
                </UiButton>
              </UiStack>
            </BaseRouterLink>
          );
        })}
      </UiVStack>
    </UiStack>
  );
};

export default AdminSideNav;
