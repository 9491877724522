import { useCallback, type FC, useMemo } from 'react';
import copy from 'copy-to-clipboard';
import {
  UiButton,
  UiHStack,
  type UiHStackProps,
  UiStack,
  uiStyles,
  UiText,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerCloseButton,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerFooter,
  UiIconQuestion,
  UiBox,
  UiTag, UiIconArrowsClockwise, UiIconUsersFour, UiIconUser,
} from '@/lib/ui';
import { useDisclosure } from '@chakra-ui/react';
import CategoryForm from './CategoryForm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  InfoFormType,
  type AttendeeCategoryData,
  type AttendeeCategoryDeleteRequest,
  GroupRegistrationEnabledType,
} from '@/api/registration';
import { account, registration } from '@/api';
import { type ApiResponse } from '@/api/tenantClient';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { UiTooltip } from '@/lib/ui/Tooltip';
import DeletionConfirmation from './DeletionConfirmation';
import { generateRegisterAbsoluteUrl } from '@/lib/util';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';
import BaseButtonPlainIconDelete from '@/base/Button/PlainIconDelete';
import BaseButtonPlainIconClone from '@/base/Button/PlainIconClone';
import { TicketType } from '@/api/constant';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';
import BaseButtonPlainIcon from '@/base/Button/PlainIcon';
// import { text } from '@/util';

export interface CategoryCardProps extends UiHStackProps {
  eventId: string
  tenant: account.TenantData
  data: registration.AttendeeCategoryData
}

const CategoryCard: FC<CategoryCardProps> = ({
  eventId,
  tenant,
  data,
  ...props
}) => {
  const queryClient = useQueryClient();
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isConfirmClone, onToggle: onToggleConfirmClone } = useDisclosure();
  const { isOpen: confirmDeletion, onToggle: onToggleConfirmDeletion } = useDisclosure();
  const { createTenantAdminApiRequest } = useTenantApi();

  const { mutate, isLoading } = useMutation<undefined, Error, AttendeeCategoryDeleteRequest>({
    mutationFn: async (request: AttendeeCategoryDeleteRequest) => {
      return await registration.deleteAttendeeCategory(createTenantAdminApiRequest)(request);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [registration.attendeeCategoryListQueryKey, { eventId: data.eventId.toString() }] });
      // Refresh Ticket
      const ticketTypes = [TicketType.Main, TicketType.Function, TicketType.Tour, TicketType.Workshop];
      for (const ticketType of ticketTypes) {
        void queryClient.invalidateQueries({ queryKey: [registration.ticketListQueryKey, { eventId, ticketType }] });
      }
      // Refresh info forms
      for (const infoFormType of [InfoFormType.PERSONAL, InfoFormType.ADDITIONAL]) {
        void queryClient.invalidateQueries({ queryKey: [registration.infoFormsQueryKey, { eventId, formType: infoFormType }] });
      }
    },
    onError: () => {
      // NOTE: handle error
    }
  });

  const { mutate: mutateClone, isLoading: isCloning } = useMutation<ApiResponse<AttendeeCategoryData>, Error, number>({
    mutationFn: async (attendeeCategoryId: number) => {
      return await registration.cloneAttendeeCategory(createTenantAdminApiRequest)(attendeeCategoryId);
    },
    onSuccess: (result) => {
      // Trigger the host list reload.
      void queryClient.invalidateQueries({ queryKey: [registration.attendeeCategoryListQueryKey, { eventId: data.eventId.toString() }] });
      onToggleConfirmClone();
    }
  });

  const onDelete = useCallback(() => {
    mutate({ id: data.id });
  }, [data.id, mutate]);

  const onConfirm = useCallback(() => {
    mutateClone(data.id);
  }, [data, mutateClone]);

  const registrationLink = useMemo(
    () => { return generateRegisterAbsoluteUrl(`/event/${tenant.code}/register/${eventId}/email?attendeeCategoryId=${data.id}`); },
    [data.id, eventId, tenant.code]
  );

  const copyRegistrationLink = useCallback(
    () => { return copy(registrationLink); },
    [registrationLink]
  );

  return (
    <>
      <UiHStack
        alignItems={'center'}
        spacing={0}
        p={6}
        bgColor={'#fff'}
        {...props}
        // borderRadius={uiStyles.borderRadius}
      >
        <UiStack alignItems={'flex-start'} spacing={0} justifyContent={'flex-start'} flexGrow={1}>
          <UiText variant={'body1'} fontWeight={600}>{data.name}</UiText>
          <UiText variant={'body2'} color={'text.secondary'}>{data.description ?? ' '}</UiText>
        </UiStack>
        <UiHStack
          spacing={8}
          // px={8}
          justifyContent={'space-between'}
        >
          {(data.groupRegistrationEnabled === GroupRegistrationEnabledType.YES) ? (
              <BaseButtonPlainIcon
                label={'Group registration'}
                Icon={UiIconUsersFour}
                color={'text.secondary'}
              />
          ) : (
            <BaseButtonPlainIcon
              label={'Individual registration'}
              Icon={UiIconUser}
              color={'text.secondary'}
            />
          )}
          {
            data.isVisible
              ? <UiTag p={2} bgColor={'green.100'} >Visible</UiTag>
              : <UiTag p={2} bgColor={'blackAlpha.100'} >Hidden</UiTag>
          }
          <UiHStack spacing={2} alignItems={'center'}>
            <UiTooltip
              label={registrationLink}
              shouldWrapChildren={true}
              placement='auto-start'
            >
              <UiButton
                colorScheme={'primary'}
                variant={'ghost'}
                px={0}
                onClick={copyRegistrationLink}
              >
                Copy registration link
              </UiButton>
            </UiTooltip>
            <UiTooltip
              label={'The url will be specific for attendees of this attendee group to register for the event. '}
              shouldWrapChildren={true}
              placement='auto-start'
            >
              <UiBox {...uiStyles.hover}>
                <UiIconQuestion color={'primary.500'} />
              </UiBox>
            </UiTooltip>
          </UiHStack>
          <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
            <BaseButtonPlainIconEdit onClick={onToggle} />
            <BaseButtonPlainIconClone onClick={onToggleConfirmClone} />
            <BaseButtonPlainIconDelete onClick={onToggleConfirmDeletion} isLoading={isLoading} />
          </BaseAclContainer>
        </UiHStack>
      </UiHStack>
      <CategoryForm
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
        attendeeCategory={data}
      />
      <UiDrawer placement={'bottom'} size={'lg'} isOpen={isConfirmClone} onClose={onToggleConfirmClone}>
        <UiDrawerOverlay />
        <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
        <UiDrawerContent p={4}>
          <UiDrawerBody>
            <UiText variant={'title'} fontWeight={400}>
              Do you confirm to clone this attendee group?
            </UiText>
          </UiDrawerBody>
          <UiDrawerFooter>
            <UiHStack spacing={8}>
              <UiButton onClick={onToggleConfirmClone} colorScheme={'gray'} px={8}>
                No
              </UiButton>
              <UiButton colorScheme={'primary'} ml={4} onClick={onConfirm} isLoading={isCloning} px={8}>
                Yes
              </UiButton>
            </UiHStack>
          </UiDrawerFooter>
        </UiDrawerContent>
      </UiDrawer>
      <DeletionConfirmation isOpen={confirmDeletion} onClose={onToggleConfirmDeletion} onConfirm={onDelete} name={data.name} />
    </>
  );
};

export default CategoryCard;
