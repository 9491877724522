import { type FC, useEffect } from 'react';
import {
  useDisclosure
} from '@chakra-ui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  type UiHStackProps,
  UiIconPlusCircle,
  UiStack,
  uiStyles,
  UiHStack,
  UiText, UiSpinner, UiButton, UiTag,
} from '@/lib/ui';
import { account } from '@/api';
import { adminUserAccountOwnerQueryKey } from '@/api/account';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { AdminUserState } from '@/api/constant/adminUser';
import AccountOwnerInviteForm from '@/aaa/manage/TenantInfo/AccountOwnerInviteForm';

export interface AccountOwnerProps extends UiHStackProps {
  tenant: account.TenantData
}

const AccountOwner: FC<AccountOwnerProps> = ({
  tenant,
  ...props
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { reportToGlobal } = useApiErrorHandler();
  const queryClient = useQueryClient();

  const {data, error, isLoading} = useQuery<account.LoadAccountOwnerResponse, Error>(
    [account.adminUserAccountOwnerQueryKey, { tenantId: tenant.id, }],
    async () => {
      return await account.loadAccountOwner(createGlobalApiAclRequest())({
        tenantId: tenant.id,
      });
    },
  );

  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );

  if (isLoading) {
    return (
      <UiStack>
        <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
      </UiStack>
    );
  }

  if (error) {
    return (
      <UiStack>
        <BaseMessageBarError>
          {error.message ?? 'Failed to load the account owner'}
        </BaseMessageBarError>
      </UiStack>
    );
  }

  return (
    <UiStack
      alignItems={'stretch'}
      justifyContent={'flex-start'}
      // flexGrow={}
      px={8}
      py={6}
      spacing={0}
      flexGrow={1}
      {...props}
    >
      {(!data?.adminUser || !data?.adminUser.id) && (
        <UiHStack {...uiStyles.hover} onClick={onToggle}>
          <UiIconPlusCircle
            size={'3xl'}
            color={'primary.600'}
            // width={64}
          />
          <UiText color={'primary.500'}>Invite the account owner</UiText>
        </UiHStack>
      )}
      {(data?.adminUser && data?.adminUser.id) && (
        <UiHStack justifyContent={'space-between'}>
          <UiStack>
            <UiText color={'text.secondary'}>Account owner</UiText>
            <UiHStack spacing={4}>
              <UiText>{data.adminUser.firstName} {data.adminUser.lastName} ({data.adminUser.email})</UiText>
              {data.adminUser.state === AdminUserState.Invited && (
                <UiTag colorScheme={'yellow'}>Invited</UiTag>
              )}
              {data.adminUser.state === AdminUserState.Active && (
                <UiTag colorScheme={'green'}>Active</UiTag>
              )}
              {data.adminUser.state === AdminUserState.Inactive && (
                <UiTag colorScheme={'red'}>Inactive</UiTag>
              )}
            </UiHStack>
          </UiStack>
          {data?.adminUser.state === AdminUserState.Invited && (
            <UiStack {...uiStyles.hover} onClick={onToggle}>
              <UiButton variant={'ghost'}>Invite again</UiButton>
            </UiStack>
          )}
        </UiHStack>
      )}
      <AccountOwnerInviteForm
        tenant={tenant}
        adminUser={!!data?.adminUser ? data?.adminUser : undefined}
        onClose={onToggle}
        isVisible={isOpen}
        onSaveSuccess={() => {}}
      />
    </UiStack>
  );
};

export default AccountOwner;
