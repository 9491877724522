import { type Attributes, cloneElement, FC, isValidElement, ReactElement, useEffect, useState } from 'react';
import { UiHStackProps, UiStack, uiStyles } from '@/lib/ui';
import { getCurrentDimensions, useWindowScrollY, useWindowSize } from '@/lib/hook';
// import { bodyPadding, topBg, topOffset} from '@/base/Layout/viewport';

interface Top extends UiHStackProps {
  heightOffset: number
}

const Top: FC<Top> = ({
  heightOffset,
  children,
  ...props
}) => {
  const { clientWidth, reloadDimensions } = useWindowSize();
  const windowScrollY = useWindowScrollY();
  const [ isBreadcrumbBarFixed, setBreadcrumbBarFixed ] = useState(windowScrollY >= heightOffset);

  useEffect(() => {
    if ((windowScrollY >= heightOffset) !== isBreadcrumbBarFixed) {
      setBreadcrumbBarFixed(!isBreadcrumbBarFixed);
    }
  }, [windowScrollY]);

  if (isBreadcrumbBarFixed) {
    return (
      <>
        <UiStack
          position={'fixed'}
          left={0}
          top={0}
          zIndex={999}
          // minW={`${clientWidth - 8 * bodyPadding}px`}
          // maxW={`${clientWidth - 8 * bodyPadding}px`}
          minW={clientWidth}
          maxW={clientWidth}
          borderBottomRadius={uiStyles.borderRadius}
          {...uiStyles.topBar}
          {...props}
        >
          {isValidElement(children) ? (
            cloneElement(children, {borderRadius: uiStyles.bodyRadius, bgColor: '#fff'} as Attributes)
          ) : (
            children
          )}
          {/*{cloneElement(children as ReactElement, {borderRadius: uiStyles.bodyRadius} as Attributes)}*/}
        </UiStack>
        <UiStack opacity={0}>
          {children}
        </UiStack>
      </>
    );
  }
  return (
    <UiStack {...props}>
      {children}
    </UiStack>
  )
}

export default Top;