import { type FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  UiButton,
  UiIconWarningCircle,
  UiStack, UiText,
} from '@/lib/ui';
import PasswordForm from './PasswordForm';
import BaseMessageBarStandard from '@/base/MessageBar/Standard';
import { generatePageUrl } from '@/app/pages';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { useTenantRoute } from '@/account/hook/useTenantRoute';

export interface PasswordProps {
}

const Password: FC<PasswordProps> = ({}) => {
  const { adminAuth } = useAdminAuth();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const { tenant } = useTenantRoute();


  const toLogin = useCallback(
    () => {
      navigate(generatePageUrl('AccountLoginLoginEmail'));
    },
    [navigate]
  );

  const toAccountDashboard = useCallback(
    () => {
      if (tenant?.code) {
        navigate(generatePageUrl('AccountDashboardModules', {tenantCode: tenant.code}));
      }
    },
    [navigate, tenant?.code]
  );

  if (errors.length > 0) {
    return (
      <UiStack p={8}>
        <BaseMessageBarStandard
          icon={<UiIconWarningCircle color={'red.500'} size={'3xl'}/>}
          text={(
            <UiText color={'red.500'}>
              {errors[0]}
            </UiText>
          )}
          button={(<UiButton onClick={toLogin} variant={'ghost'}>Back to login</UiButton>)}
        />
      </UiStack>
    );
  }

  if (adminAuth?.user) {
    return (
      <>
        <PasswordForm
          adminUser={adminAuth?.user}
          onSaveSuccess={toAccountDashboard}
        />
      </>
    );
  }
};

export default Password;
