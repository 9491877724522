import { type FC, type ReactElement, useCallback } from 'react';
import {
  UiButton,
  UiHStack, UiIconCaretRight,
  UiIconHeadset,
  UiIconSignOut,
  UiIconUserCircle, UiLink,
  UiStack,
  uiStyles,
  UiTag,
  UiText,
} from '@/lib/ui';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { generatePageUrl } from '@/app/pages';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useNavigate } from 'react-router-dom';
import { adminUserTypeLabels } from '@/api/constant/adminUser';
import { useAdminUserTypeCheck } from '@/account/hook/useAdminUserTypeCheck';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BaseBlockList from '@/base/BlockList';

export interface Menu {
  name: string
  label: string
  url?: string
  icon?: ReactElement
  subMenu?: Menu[]
}

export interface SmartWindowProps {
  // enableTenantList?: boolean
}

const SmartWindow: FC<SmartWindowProps> = ({
  // enableTenantList = true,
}) => {
  const { adminAuth, signOut, isSignOutLoading } = useAdminAuth();
  const { tenant } = useTenantRoute();
  const navigate = useNavigate();
  const {isAccountOwner, isAAAUser, isLoading} = useAdminUserTypeCheck(tenant?.id ?? 0);
  const toAccountSettings = useCallback(
    () => {
      if (tenant?.code) {
        navigate(generatePageUrl('AccountManageAccountSettings', {tenantCode: tenant.code}));
      }
    },
    [navigate, tenant?.code]
  );

  return (
    <UiStack
      justifyContent={'flex-start'}
      alignItems={'stretch'}
      flexGrow={1}
      // px={8}
      spacing={8}
      height={'100%'}
    >
      <UiStack
        alignItems={'stretch'}
        transition={'height, padding, backgroundColor'}
        transitionDuration={'0.4s'}
        transitionTimingFunction={'ease-in-out'}
        spacing={4}
        flexGrow={1}
      >
        {adminAuth.user && adminAuth.user.id > 0 && (
          <>
            <UiStack alignItems={'stretch'} justifyContent={'flex-start'} spacing={2} p={8} py={8}>
              <UiHStack
                alignItems={'center'}
                justifyContent={'flex-start'}
                // px={8}
                py={6}
                // px={4}
                spacing={4}
                // bgColor={'#fff'}
                // bgColor={'#fff'}
                // borderRadius={uiStyles.borderRadius}
              >
                <UiIconUserCircle size={'64px'} weight={'thin'}/>
                {/*<UiImage*/}
                {/*  src={'/image/app/avatar_male_default_1.jpg'}*/}
                {/*  minHeight={'96px'}*/}
                {/*  maxHeight={'96px'}*/}
                {/*  borderRadius={'50%'}*/}
                {/*/>*/}
                <UiStack
                  flexGrow={1}
                >
                  <UiText fontWeight={600}>{adminAuth.user.email}</UiText>
                  <UiHStack justifyContent={'flex-start'}>
                    {isAAAUser(adminAuth.user) && (
                      <UiTag colorScheme={'purple'} px={4} py={2}>{adminUserTypeLabels[adminAuth.user.type]}</UiTag>
                    )}
                    {isAccountOwner(adminAuth.user) && (
                      <UiTag colorScheme={'brand'} px={4} py={2}>{adminUserTypeLabels[adminAuth.user.type]}</UiTag>
                    )}
                  </UiHStack>
                </UiStack>
              </UiHStack>
              <UiHStack>
                <UiButton flexGrow={1} py={6} onClick={toAccountSettings}>Manage account</UiButton>
              </UiHStack>
            </UiStack>
            <BaseDividerHorizontal height={0} />
            <UiStack p={8}>
              <BaseBlockList
                bgColor={'#fff'}
                enableDivider={true}
              >
                <UiLink href={'https://support.evexus.com/portal/en/kb/global-dashboard-registration'} target={'_blank'}>
                  <UiHStack justifyContent={'space-between'} px={6} py={4} {...uiStyles.hover}>
                    <UiHStack>
                      <UiIconHeadset/>
                      <UiText>Help & support</UiText>
                    </UiHStack>
                    <UiIconCaretRight color={'primary.500'}/>
                  </UiHStack>
                </UiLink>
              </BaseBlockList>
            </UiStack>
            <UiStack alignItems={'stretch'} flexGrow={1} p={8}>
              {/*{enableTenantList && (<TenantList/>)}*/}
              <UiHStack flexGrow={1}/>
              <UiHStack
                justifyContent={'space-between'}
                px={8}
                py={4}
                bgColor={'#fff'}
                borderRadius={uiStyles.buttonRadius}
                onClick={signOut}
                {...uiStyles.hover}
              >
                {isSignOutLoading ? (
                  <UiText color={'gray.500'} variant={'title'}>Signing out...</UiText>
                ) : (
                  <UiText color={'red.500'} variant={'title'}>Sign out</UiText>
                )}
                <UiIconSignOut size={'2xl'} color={'red.500'} weight={'bold'}/>
              </UiHStack>
            </UiStack>
          </>
        )}
      </UiStack>
      {/* <UiStack
        p={4}
        borderRadius={uiStyles.borderRadius}
        bgColor={'orange.100'}
        width={'100%'}
      >
        <UiHStack>
          <UiIconInfo size={'3xl'} color={'orange.600'}/>
          <UiText>New orders</UiText>
        </UiHStack>
        <UiText>
          You've received 10 new orders in the last 24 hours. Here is the progress in your sales target.
        </UiText>
        <UiStack
          width={'100%'}
          alignItems={'center'}
          pt={4}
        >
          <UiBox width={'300px'} height={'300px'} p={0} m={0} bgColor={'transparent'} borderRadius={uiStyles.borderRadius}>
            <svg viewBox="0 0 300 300">
              <VictoryPie
                standalone={false}
                width={300} height={300}
                colorScale={['orange', '#fff']}
                data={[
                  { x: '85%', y: 85 }, { x: 2, y: 15 }
                ]}
                innerRadius={75} labelRadius={100}
                style={{ labels: { fontSize: 20, fill: '#fff' } }}
                // domainPadding={0}
                padding={0}
              />
              <VictoryLabel
                textAnchor={'middle'}
                style={{ fontSize: 20, fill: '#666' }}
                x={150} y={150}
                text={'Sales target'}
              />
            </svg>
          </UiBox>
        </UiStack>
        <UiStack
          // px={4}
          // py={6}
          pt={4}
          borderRadius={uiStyles.borderRadius}
          bgColor={'orange.100'}
          width={'100%'}
        >
          <UiText>
            Do you want to promote the remaining packages to potential sponsors?
          </UiText>
          <UiHStack flexDirection={'row-reverse'}>
            <UiButton ml={4}>Yes</UiButton>
            <UiButton variant={'unstyled'} bgColor={'transparent'} color={'primary.dark'} >No</UiButton>
          </UiHStack>
        </UiStack>
      </UiStack> */}
    </UiStack>
  );
};

export default SmartWindow;
