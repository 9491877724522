import { type FC } from 'react';
import BaseLayout from '@/base/Layout';
import BaseLayoutBody from '@/base/Layout/Body';
// import Filter from './Filter';
import AdminSideNav from '@/registration/component/Layout/AdminSideNav';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import CancelledRegistrationTable from './CancelledRegistrationTable';
import { UiFlex, UiHStack, UiStack, UiText } from '@/lib/ui';
import { LayoutProvider } from '@/base/Layout/LayoutProvider';
import BaseLayoutFullScreenToggle from '@/base/Layout/FullScreenToggle';
import SubscribeButton from '@/base/ScheduleReport/SubscribeButton';
import { reportSubscriptionName } from '@/api/reports/reportSubscriptions';

const CancelledRegistration: FC = () => {
  const { tenantCode } = useRegisterRoute();

  return (
    <LayoutProvider>
      <BaseLayout
        smartWindow={(<SmartWindow />)}
        sideMenu={(<AdminSideNav activeNodes={['report', 'cancelled-registrations']} tenantCode={tenantCode} />)}
        appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
      >
        <BaseLayoutBody
          isStandalone={false}
          // breadcrumbNodes={breadcrumbNodes}
        >
          <UiHStack justifyContent={'space-between'} spacing={4}>
            <BaseLayoutFullScreenToggle />
            <UiText variant={'title'}>Registration cancellations</UiText>
            <UiFlex flexGrow={1} justifyContent={'flex-end'}>
              <SubscribeButton reportName={reportSubscriptionName.cancellationReport} />
            </UiFlex>
          </UiHStack>
          <UiStack height={4} />
          <CancelledRegistrationTable />
        </BaseLayoutBody>
      </BaseLayout>
    </LayoutProvider>
  );
};

export default CancelledRegistration;
