import {
  type ApiRequest,
  type ApiResponse,
  type ApiResponseSingle,
  properCallApi
} from '@/api/tenantClient';

export const infoFormResponseQueryKey = 'registration.infoFormResponseList';
export const infoFormResponseTableQueryKey = 'infoFormResponse.table';

export interface InfoFormResponse {
  id: number
  response: Record<string, any>
  eventId: string
  ownerId: number
  ownerType: 'Registration'
  infoFormId: number
}

export enum InfoFormType {
  PERSONAL = 'personal',
  ADDITIONAL = 'additional'
};

export interface InfoFormResponseQueryRequest {
  registrationId: number
  type: InfoFormType
}

export function loadRegistrationInfoFormResponse(createTenantRequest: () => ApiRequest) {
  const request = createTenantRequest();
  return async (
    params: InfoFormResponseQueryRequest
  ): Promise<ApiResponseSingle<InfoFormResponse>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/info_form_responses/' + params.registrationId.toString();
    request.endpoint.query = {
      type: params.type
    };
    return await properCallApi<ApiResponseSingle<InfoFormResponse>>(request);
  };
}

export interface InfoFormResponseQueryTableRequest {
  page: number
  filters?: any
  sorting?: any
  size: number
  search?: string
  eventId?: string
  attendeeCategoryId?: string
}

export interface InfoFormResponseTableRow {
  id: number
  idInfo: string
  attendee: { name: string, email: string }
  attendeeCategory: { name: string }
  infoFormResponses: { personal?: Record<string, any>, additional?: Record<string, any> }
  createdAt: string
  confirmDelegateTypeAt: string
}

export function loadInfoFormResponseForTable(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: InfoFormResponseQueryTableRequest
  ): Promise<ApiResponse<InfoFormResponseTableRow>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/info_form_responses/table';
    request.endpoint.query = {
      eventId: params.eventId,
      attendeeCategoryId: params.attendeeCategoryId,
      page: (params?.page ?? 0) + 1,
      filters: JSON.stringify(params.filters ?? []),
      sorting: JSON.stringify(params.sorting ?? []),
      size: params.size ?? 10,
      ...params.search ? { search: params.search } : {}
    };
    return await properCallApi<ApiResponse<InfoFormResponseTableRow>>(request);
  };
};
