/* eslint-disable react/no-unescaped-entities */
import { type FC, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  type UiBoxProps,
  UiStack,
  UiText,
  UiSpinner,
  uiStyles,
  UiHStack, UiButton,
} from '@/lib/ui';
import { account } from '@/api';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { subscriptionNextTermQueryKey } from '@/api/account';
import { generatePageUrl } from '@/app/pages';
import { useNavigate } from 'react-router-dom';

export interface AppListProps extends UiBoxProps {
}

const ManageNextSubscriptionTerm: FC<AppListProps> = () => {
  const { tenant, isLoading: isTenantLoading } = useTenantRoute();
  const navigate = useNavigate();
  const { createGlobalApiAclRequest } = useGlobalApi();
  const { data, isLoading } = useQuery<account.SubscriptionLoadNextTermResponse, Error>(
    [account.subscriptionNextTermQueryKey, {tenantId: tenant?.id}],
    async () => {
      return await account.loadNextSubscriptionTerm(createGlobalApiAclRequest())({
        tenantId: tenant?.id ?? 0,
      });
    },
    {
      enabled: !isTenantLoading,
    }
  );

  const toChangePlan = useCallback(() => {
    navigate(generatePageUrl('AccountSubscriptionPlanManagement', { tenantCode: tenant?.code ?? '' }));
  }, [tenant?.code]);

  if (isLoading) {
    return (
      <UiStack p={8}>
        <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
      </UiStack>
    );
  }

  if (!data?.subscription || !data?.subscriptionTermId) {
    return null;
  }

  return (
    <UiHStack
      p={8}
      py={4}
      bgColor={'#fff'}
      borderRadius={uiStyles.borderRadius}
      justifyContent={'space-between'}
      // shadow={uiStyles.cardShadow}
    >
      <UiStack>
        <UiHStack spacing={1}>
          <UiText>
            You are allowed to change your plan. All the changes will become effective since your next bill starting at
          </UiText>
          <UiText color={'green.500'} fontWeight={600}>{dayjs(data.from).format('DD MMMM YY')}</UiText>
        </UiHStack>
      </UiStack>
      <UiButton variant={'ghost'} px={0} onClick={toChangePlan}>Change plan</UiButton>
    </UiHStack>
  );
};

export default ManageNextSubscriptionTerm;
