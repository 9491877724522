import { useEffect, useState } from 'react';
import { account } from '@/api';
import {
  type SubscriptionPackage,
  SubscriptionPaymentPlan,
  SubscriptionProduct,
  SubscriptionState,
} from '@/api/constant/subscription';
import { isAddonAvailable } from '@/account/subscription/utils';

export interface SubscriptionEditor {
  subscription: account.Subscription | undefined
  // isLoading: boolean
  selectPackage: (packageName: SubscriptionPackage) => void
  toggleAddon: (addonProductName: SubscriptionProduct) => void
  updateServicePackHours: (hours: number) => void
  toggleServicePack: () => void
}

export function useSubscriptionPlanEditor(initSubscription: account.Subscription): SubscriptionEditor {
  const [subscription, setSubscription] = useState<account.Subscription>(initSubscription);

  function selectPackage(packageName: SubscriptionPackage) {
    if (subscription && subscription.plan.package !== packageName) {
      const newSubscription = { ...subscription };
      newSubscription.plan.package = packageName;
      newSubscription.plan.addons = []; // This is important since Add-ons are different for different packages.
      setSubscription(newSubscription);
    }
  }

  function toggleAddon(addonProductName: SubscriptionProduct, paymentPlan: SubscriptionPaymentPlan = SubscriptionPaymentPlan.Quarterly) {
    // Make sure the addon is NOT included in the package.
    if (subscription && isAddonAvailable(subscription, addonProductName)) {
      const newSubscription = { ...subscription };
      const addons = newSubscription.plan.addons ?? [];
      // const existingPos = newSubscription.plan.addon.products.indexOf(addonProductName);
      const existingPos = addons.findIndex((addon) => addon.product === addonProductName);
      if (existingPos >= 0) {
        // Remove the addon if exists.
        addons.splice(existingPos, 1);
      } else {
        // Add the addon if not exists.
        addons.push({
          product: addonProductName,
          paymentPlan,
        });
      }
      newSubscription.plan.addons = addons;
      setSubscription(newSubscription);
    }
  }

  function updateServicePackHours(hours: number) {
    if (subscription && subscription.plan.servicePack.hours !== hours) {
      const newSubscription = { ...subscription };
      newSubscription.plan.servicePack.hours = hours;
      setSubscription(newSubscription);
    }
  }

  function toggleServicePack() {
    if (subscription) {
      const newSubscription = { ...subscription };
      if (newSubscription.plan.servicePack.hours === 0) {
        newSubscription.plan.servicePack.hours = 40;
      } else {
        newSubscription.plan.servicePack.hours = 0;
      }
      setSubscription(newSubscription);
    }
  }

  return {
    subscription,
    // isLoading: isLoading || isNextSubscriptionTermLoading,
    selectPackage,
    toggleAddon,
    updateServicePackHours,
    toggleServicePack,
  };
}
