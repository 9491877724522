import { type FC, useMemo, useState } from 'react';
import BaseLayout from '@/base/Layout';
import { useParams } from 'react-router-dom';
import SmartWindow from '@/base/Layout/SmartWindow';
import BaseAppRegistrationCard from '@/base/App/RegistrationCard';
import BaseAppListDrawerButton from '@/base/App/ListDrawerButton';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import {
  UiHStack,
  UiIconArrowsLeftRight,
  UiIconPaperPlaneTilt,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import BreadcrumbBody from '@/base/Layout/BreadcrumbBody';
import type { BaseBreadcrumbBarNode } from '@/base/BreadcrumbBar';
import { generatePageUrl } from '@/app/pages';
import { type ManageRouteParams } from '@/registration/pages';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { loadRegistrationById, registrationIdQueryKey, requestDetailsChange, type RegistrationInfo, type RequestDetailsChangeRequest } from '@/api/registration';
import { useTenantApi } from '@/account/hook/useTenantApi';
import RegistrationSummary from './RegistrationSummary';
import QueryContainer from '@/base/QueryContainer/QueryContainer';
import RegistrationDiscount from './RegistrationDiscount';
import RegistrationFormResponse from './RegistrationFormResponse';
import BaseMessageBarInfo from '@/base/MessageBar/Info';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseButtonGhost from '@/base/Button/Ghost';
import BaseButtonIconText from '@/base/Button/IconText';
import MigrateRegistrationDrawer from '@/registration/manage/RegistrationInfo/TransferRegistrationDrawer';
import { useDisclosure } from '@chakra-ui/react';
import BaseButtonPlainIconEdit from '@/base/Button/PlainIconEdit';

const RegistrationManageInfoPage: FC = () => {
  const { tenantCode } = useRegisterRoute();
  const { createTenantAdminApiRequest, isLoaded } = useTenantApi();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const params = useParams<ManageRouteParams['RegistrationManageInfo']>();
  const { isOpen: isMigrateRegistrationDrawerOpen, onToggle: onMigrateRegistrationDrawerToggle, onClose: onMigrateRegistrationDrawerClose } = useDisclosure();
  const { reportToGlobal } = useApiErrorHandler();
  const registrationId = useMemo<number | null>(
    () => { return params.registrationId ? parseInt(params.registrationId, 10) : null; },
    [params.registrationId]
  );
  const breadcrumbNodes = useMemo<BaseBreadcrumbBarNode[]>(
    () => {
      return [
        { label: 'Manage' },
        { label: 'Registration', url: generatePageUrl('RegistrationManageTable', { tenantCode }) },
        /**
         * @todo orderId here should be the order serial number, not the database table row id.
         */
        { label: `Registration information (${registrationId ?? ''})` }
      ];
    },
    [registrationId, tenantCode]
  );
  const queryClient = useQueryClient();

  const registrationQuery = useQuery<RegistrationInfo, Error>({
    queryKey: [registrationIdQueryKey, { id: registrationId }],
    queryFn: async (): Promise<RegistrationInfo> => {
      const response = await loadRegistrationById(createTenantAdminApiRequest)({ id: registrationId! });
      return response.item;
    },
    enabled: !!registrationId && isLoaded
  });

  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  const { mutate: requestDetailsChangeMutate, isLoading: isRequestDetailsChangeMutationLoading } = useMutation<void, Error, RequestDetailsChangeRequest>({
    mutationFn: async (request: RequestDetailsChangeRequest) => {
      setSuccessMessage('');
      setErrorMessage('');
      await requestDetailsChange(createTenantAdminApiRequest)(request);
    },
    onSuccess: () => {
      setSuccessMessage('An email has been sent to the attendee. Please tell the attendee to follow the link in the email to change the registration details.');
      // TODO: show success message && handle errors
    },
    onError: (error) => {
      reportToGlobal(error);
      setErrorMessage(`Failed to send the email (${error.message ?? ''}).`);
      // setSaveErrors([error.message ?? 'Failed to create the team.']);
    }
  });

  const handleRequestDetailsChange = () => {
    void requestDetailsChangeMutate({ registrationId: registrationId! });
  };

  return (
    <BaseLayout
      smartWindow={(<SmartWindow />)}
      sideMenu={null}
      MainTransition={null}
      appSwitch={(<BaseAppListDrawerButton currentApp={<BaseAppRegistrationCard showDescription={false} />} currentAppName={'registration'} />)}
    >
      <BreadcrumbBody
        breadcrumbNodes={breadcrumbNodes}
      >
        <QueryContainer query={registrationQuery}>
          {(registration) => {
            return (
              <>
                <UiStack p={8} spacing={4}>
                  <UiHStack justifyContent={'flex-start'} spacing={6} alignItems={'center'}>
                    <UiText variant={'title'}>Summary</UiText>
                    {registration.status === 'Approved' && (
                      // <BaseButtonGhost
                      //   LeftIcon={UiIconPaperPlaneTilt}
                      //   onClick={handleRequestDetailsChange}
                      //   isLoading={isRequestDetailsChangeMutationLoading}
                      // >
                      //   Send details change email to attendee
                      // </BaseButtonGhost>
                      <BaseButtonIconText
                        Icon={UiIconPaperPlaneTilt}
                        onClick={handleRequestDetailsChange}
                        isLoading={isRequestDetailsChangeMutationLoading}
                      >
                        Send details change email to attendee
                      </BaseButtonIconText>
                    )}
                    <BaseButtonIconText
                      Icon={UiIconArrowsLeftRight}
                      onClick={onMigrateRegistrationDrawerToggle}
                    >
                      Transfer
                    </BaseButtonIconText>
                    {/*<UiHStack spacing={4}>*/}
                    {/*  <BaseButtonPlainIconEdit label='Change' onClick={onMigrateRegistrationDrawerToggle} />*/}
                    {/*</UiHStack>*/}
                  </UiHStack>
                  {!!errorMessage && (
                    <UiStack>
                      <BaseMessageBarError borderRadius={uiStyles.borderRadius}>
                        {errorMessage}
                      </BaseMessageBarError>
                    </UiStack>
                  )}
                  {!!successMessage && (
                    <UiStack>
                      <BaseMessageBarInfo bgColor={'green.50'} borderRadius={uiStyles.borderRadius}>
                        {successMessage}
                      </BaseMessageBarInfo>
                    </UiStack>
                  )}
                  <RegistrationSummary registration={registration} />
                  {registration.discount && (
                    <UiStack pt={4}>
                      <RegistrationDiscount registration={registration} />
                    </UiStack>
                  )}
                  {registration.personalFormResponses && (
                    <UiStack pt={4}>
                      <RegistrationFormResponse
                        title="Personal Info"
                        infoFormResponse={registration.personalFormResponses}
                      />
                    </UiStack>
                  )}
                  {registration.additionalFormReponses && (
                    <UiStack pt={4}>
                      <RegistrationFormResponse
                        title="Additional Information"
                        infoFormResponse={registration.additionalFormReponses}
                      />
                    </UiStack>
                  )}
                  {/* {registration.status === 'Approved' && ( */}
                  {/*  <UiHStack justifyContent={'flex-end'} pt={4}> */}
                  {/*    <UiButton */}
                  {/*      size={'lg'} */}
                  {/*      colorScheme={'primary'} */}
                  {/*      onClick={handleRequestDetailsChange} */}
                  {/*      // variant={'ghost'} */}
                  {/*      isLoading={isRequestDetailsChangeMutationLoading} */}
                  {/*    > */}
                  {/*      Send details change email to attendee */}
                  {/*    </UiButton> */}
                  {/*  </UiHStack> */}
                  {/* )} */}
                </UiStack>
                <MigrateRegistrationDrawer
                  isOpen={isMigrateRegistrationDrawerOpen}
                  onClose={onMigrateRegistrationDrawerClose}
                  registration={registration}
                />
              </>
            );
          }}
        </QueryContainer>
      </BreadcrumbBody>
    </BaseLayout>
  );
};

export default RegistrationManageInfoPage;
