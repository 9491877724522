import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, type FormikHelpers } from 'formik';
import {
  UiButton,
  UiHStack,
  type UiHStackProps, UiIconPlusCircle,
  UiIconWarningCircle,
  UiSpinner,
  UiStack,
  uiStyles,
  UiText,
} from '@/lib/ui';
import BaseFormCheckboxListField, { type CheckboxOption } from '@/base/Form/CheckboxListField';
// import Industries from './Industries';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
// import BaseFormTextareaField from '@/app/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { TeamState, teamStateOptions } from '@/api/constant/team';
import * as Yup from 'yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { account } from '@/api';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { AdminUserType } from '@/api/constant/adminUser';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import BaseMessageBarStandard from '@/base/MessageBar/Standard';
import { generatePageUrl } from '@/app/pages';

export interface AddAdminUsersProps extends UiHStackProps {
  team: account.LoadTeamResponseTeam
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
}

interface FormData {
  adminUserIds: string[]
}

interface Errors {
  adminUserIds?: string
}

const initFormData = {
  adminUserIds: [],
};

const formSchema = Yup.object().shape({
  adminUserIds: Yup.array().min(1, 'Please select admin users.'),
});

const AddAdminUsers: FC<AddAdminUsersProps> = ({
  team,
  onClose,
  onSaveSuccess,
  isVisible
}) => {
  const { tenant } = useTenantRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { createGlobalApiAclRequest } = useGlobalApi();
  const queryClient = useQueryClient();
  const { reportToGlobal } = useApiErrorHandler();
  const navigate = useNavigate();

  const toAdminUsers = useCallback(
    () => {
      if (tenant?.code) {
        navigate(generatePageUrl('AccountAccessAdminUser', { tenantCode: tenant?.code }));
      }
    },
    [navigate]
  );

  const { data: adminUsersData, isLoading: isAdminUsersSearchLoading, error: isAdminUsersSearchError } = useQuery<account.SearchAdminUsersResponse, Error>(
    [account.adminUserSearchQueryKey, {types: [AdminUserType.Generic], tenantCode: tenant?.code}],
    async () => {
      return await account.searchAdminUsers(createGlobalApiAclRequest())({
        tenantId: tenant?.id,
        types: [AdminUserType.Generic],
        page: 1,
        orderByField: 'email',
        isOrderedAsc: true,
        limit: 999, // Let's get all the teams at once.
      });
    },
  );

  useEffect(
    () => {
      if (isAdminUsersSearchError) {
        reportToGlobal(isAdminUsersSearchError);
      }
    },
    [isAdminUsersSearchError]
  );

  const adminUserOptions = useMemo<CheckboxOption[]>(
    () => {
      const options: CheckboxOption[] = [];
      if (adminUsersData?.adminUserList && Array.isArray(adminUsersData?.adminUserList)) {
        const teamAdminUserIds = [];
        for (const teamAdminUser of team.adminUsers) {
          teamAdminUserIds.push(teamAdminUser.id);
        }
        for (const adminUser of adminUsersData?.adminUserList) {
          if (!teamAdminUserIds.includes(adminUser.id)) {
            options.push({ label: `${adminUser.firstName} ${adminUser.lastName}`, value: `${adminUser.id}`, description: `${adminUser.email}` });
          }
        }
      }
      return options;
    },
    [team, adminUsersData]
  );

  const { mutate, isLoading } = useMutation<account.AddTeamAdminUsersResponse, Error, account.AddTeamAdminUsersRequest>({
    mutationFn: async (data: account.AddTeamAdminUsersRequest) => {
      return await account.addTeamAdminUsers(createGlobalApiAclRequest())(data);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        onSaveSuccess();
        // Trigger the host list reload.
        void queryClient.invalidateQueries({ queryKey: [account.teamQueryKey, { id: team.id }] });
        onClose();
      }
    },
    onError: (error) => {
      reportToGlobal(error);
      setSaveErrors([error.message ?? 'Failed to add the admin users.']);
    }
  });

  const submitForm = async (values: FormData) => {
    if (values.adminUserIds && Array.isArray(values.adminUserIds) && values.adminUserIds.length > 0) {
      // The admin user ids returned by the form are strings. Need to transform them to number.
      const adminUserIds = values.adminUserIds.map((i) => { return parseInt(i, 10); });
      mutate({
        teamId: team.id,
        adminUserIds,
      });
    }
  };

  return (
    <Formik<FormData>
      initialValues={initFormData}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={async (
        values: FormData,
        { setSubmitting }: FormikHelpers<FormData>
      ) => {
        setSubmitting(true);
        await submitForm(values);
        setSubmitting(false);
      }}
    >
      <BaseFormDrawer
        isOpen={isVisible}
        onClose={onClose}
        title={'Add users to the team'}
        size={'lg'}
        isLoading={isLoading}
      >
        {saveErrors.length > 0 && (
          <UiStack spacing={4} flexGrow={1} py={4}>
            {saveErrors.map((error, index) => {
              return (
                <BaseMessageBarError key={index}>
                  {error}
                </BaseMessageBarError>
              );
            })}
          </UiStack>
        )}
        <UiStack p={8} bgColor={'blackAlpha.50'} borderRadius={uiStyles.borderRadius} spacing={1} py={4}>
          <UiText variant={'title'}>{team.name}</UiText>
          {!!team.description && (<UiText variant={'body2'} color={'text.secondary'}>{team.description}</UiText>) }
        </UiStack>
        <UiStack height={4}/>
        {(!adminUsersData?.adminUserList || adminUsersData?.adminUserList.length === 0) && (
          <UiStack>
            <BaseMessageBarStandard
              bgColor={'red.50'}
              icon={<UiIconWarningCircle color={'red.500'} size={'3xl'} />}
              text={(
                <UiText color={'red.500'}>
                  Please invite admin users first.
                </UiText>
              )}
              button={(<UiButton onClick={toAdminUsers} variant={'ghost'}>Manage admin users</UiButton>)}
            />
          </UiStack>
        )}
        {isAdminUsersSearchLoading && (
          <UiSpinner size={'sm'} color={'primary.500'} />
        )}
        {(adminUsersData?.adminUserList?.length && adminUsersData?.adminUserList.length > 0) && (
          <BaseFormFieldGroup>
            <BaseFormCheckboxListField
              name={'adminUserIds'}
              label={'Admin users'}
              helperText={adminUserOptions.length === 0 ? 'All the admin users have been added to this team.' : 'Choose the admin users you want to add to this team.'}
              options={adminUserOptions}
              layout={'stack'}
              isRequired={true}
            />
          </BaseFormFieldGroup>
        )}
        <BaseDividerHorizontal height={8}/>
        <UiHStack justifyContent={'flex-end'}>
          <UiButton
            variant={'ghost'}
            leftIcon={(
              <UiIconPlusCircle color={'primary.500'} size={'3xl'} weight={'bold'}/>
            )}
            onClick={toAdminUsers}
            fontWeight={600}
            px={0}
          >
            Invite new admin users
          </UiButton>
        </UiHStack>
      </BaseFormDrawer>
    </Formik>
  );
};

export default AddAdminUsers;
