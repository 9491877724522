import { type FC, useCallback, useEffect } from 'react';
import { Player as LottiePlayer, PlayerEvent, PlayerState } from '@lottiefiles/react-lottie-player';
import { useNavigate } from 'react-router-dom';
import Layout from '@/productWebsite/component/Layout';
import {
  UiStack,
  UiSpinner, UiCenter, UiHStack,
} from '@/lib/ui';
import {generatePageUrl} from "@/app/pages";
import AnimatedBrand from '@/asset/lottie/animated-brand.json';


const Landing: FC = () => {
  const navigate = useNavigate();
  // useEffect(
  //   () => {
  //     const timeoutIndex = setTimeout(
  //       () => {
  //         navigate(generatePageUrl('AccountLoginLoginEmail'));
  //       },
  //       4000
  //     );
  //     return () => {
  //       clearTimeout(timeoutIndex);
  //     };
  //   },
  //   []
  // );

  const onAnimationEvent = useCallback(
    (event: PlayerEvent) => {
      if (event === PlayerEvent.Complete) {
        navigate(generatePageUrl('AccountLoginLoginEmail'));
      }
    },
    [navigate]
  );

  return (
    <Layout>
      {/*<UiStack spacing={4}>*/}
      {/*  <BaseMessageBarInfo borderRadius={uiStyles.borderRadius}>*/}
      {/*    This is a temporary index page.*/}
      {/*  </BaseMessageBarInfo>*/}
      {/*  <UiLink*/}
      {/*    href={generatePageUrl('AccountLoginLoginEmail')}*/}
      {/*    target={'_blank'}*/}
      {/*    {...uiStyles.hover}*/}
      {/*  >*/}
      {/*    <UiHStack>*/}
      {/*      <UiText color={'primary.500'}>Admin login</UiText>*/}
      {/*    </UiHStack>*/}
      {/*  </UiLink>*/}
      {/*</UiStack>*/}
      <UiHStack
        flexGrow={1}
        alignItems={'center'}
        justifyContent={'center'}
        pb={16}
        // bgColor={'brand.50'}
        bgGradient={'radial(200% 200% at 0% 15%, brand.50 10%, gray.200 35%, gray.200 50%, gray.200 90%, gray.200 100%)'}
      >
        <UiCenter>
          {/*<UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />*/}
          <LottiePlayer
            autoplay={true}
            loop={false}
            keepLastFrame={true}
            speed={2}
            onEvent={onAnimationEvent}
            src={AnimatedBrand}
            style={{
              'height': '300px',
            }}
          />
        </UiCenter>
      </UiHStack>
    </Layout>
  );
};

export default Landing;
