import { type FC, useState, useEffect } from 'react';
import {
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import { Formik, type FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { registration } from '@/api';
import {
  type UiHStackProps,
  UiStack
} from '@/lib/ui';
// import Industries from './Industries';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormTextareaField from '@/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import { type ApiResponse } from '@/api/tenantClient';
import { type AttendeeCategorySaveRequest, type AttendeeCategoryData, RegistrationStrategyType, GroupRegistrationEnabledType, type AttendeeCategorySaveRequestItem } from '@/api/registration';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useTenantApi } from '@/account/hook/useTenantApi';
import BaseFormSelectField from '@/base/Form/SelectField';
import BaseFormNumberField from '@/base/Form/NumberField';

export interface TypeFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  attendeeCategory?: registration.AttendeeCategoryData
}

interface FormData {
  name: string
  description?: string
  registrationStrategy: RegistrationStrategyType
  groupRegistrationEnabled: GroupRegistrationEnabledType
  minimumAttendees: number | null
  maximumAttendees: number | null
  isVisible: boolean
}

const initFormData = {
  name: '',
  description: '',
  registrationStrategy: RegistrationStrategyType.FORMS_FIRST,
  groupRegistrationEnabled: GroupRegistrationEnabledType.NO,
  minimumAttendees: 1,
  maximumAttendees: null,
  isVisible: true
};

const formSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required.'),
  description: Yup.string()
    .max(100, 'Description can not have more than 100 charactors.')
    .nullable(),
  registrationStrategy: Yup.string()
    .required('Registration strategy is required.'),
  isVisible: Yup.boolean(),
  maximumAttendees: Yup.number().nullable()
    .when('minimumAttendees',
      (minimumAttendees, yup) => { return minimumAttendees && yup.min(Number(minimumAttendees), 'Maximum to cannot be smaller than minimum'); }),
});
const TypeForm: FC<TypeFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  attendeeCategory = undefined
}) => {
  const { eventId } = useRegisterRoute();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { mutateAsync, isLoading } = useMutation<ApiResponse<AttendeeCategoryData>, Error, AttendeeCategorySaveRequest>({
    mutationFn: async (data: registration.AttendeeCategorySaveRequest) => { return await registration.saveAttendeeCategory(createTenantAdminApiRequest)(data); },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        setSaveErrors([]);
        onSaveSuccess();
        // Trigger the host list reload.
        void queryClient.invalidateQueries({ queryKey: [registration.attendeeCategoryListQueryKey, { eventId }] });
        onClose();
      }
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the attendee group.']);
    }
  });

  const onSubmit = async (
    values: FormData,
    { setSubmitting, resetForm }: FormikHelpers<FormData>
  ) => {
    setSubmitting(true);
    let attendee: AttendeeCategorySaveRequestItem = {
      // If the id is passed, the backend should update the entity. Otherwise, the backend should create a new entity.
      id: attendeeCategory?.id ?? null,
      eventId: Number(eventId),
      name: values.name,
      description: values.description ?? '',
      groupRegistrationEnabled: values.groupRegistrationEnabled,
      registrationStrategy: values.registrationStrategy,
      isVisible: values.isVisible,
      minimumAttendees: null,
      maximumAttendees: null
    };
    if (values.groupRegistrationEnabled === GroupRegistrationEnabledType.YES) {
      attendee = {
        ...attendee,
        minimumAttendees: values.minimumAttendees,
        maximumAttendees: values.maximumAttendees,
      };
    }
    await mutateAsync({ attendeeCategory: attendee });

    !attendeeCategory && resetForm();
    setSubmitting(false);
  };

  return (
    <Formik<FormData>
      initialValues={attendeeCategory ?? initFormData}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        const descriptionCharacterCount = values.description ? 100 - values.description.length : 100;

        return (
          <BaseFormDrawer
            isOpen={isVisible}
            onClose={onClose}
            title={attendeeCategory ? 'Edit attendee group' : 'Add attendee group'}
            size={'xl'}
            isLoading={isLoading}
          >
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((error, index) => {
                  return (
                    <BaseMessageBarError key={index}>
                      {error}
                    </BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <BaseFormFieldGroup>
              <BaseFormInputField
                name="name"
                label="Name"
              />
              <BaseFormSelectField
                name={'groupRegistrationEnabled'}
                label={'Registration method'}
                options={[
                  { value: GroupRegistrationEnabledType.YES, label: 'Group registrations' },
                  { value: GroupRegistrationEnabledType.NO, label: 'Individual registrations' }
                ]}
                helperText={''}
              />
              <BaseFormTextareaField
                name="description"
                label="Description"
                isRequired={false}
                maxLength={100}
                helperText={`100 character limit (${descriptionCharacterCount} characters remaining)`}
              />
              {values.groupRegistrationEnabled !== GroupRegistrationEnabledType.YES ? (
                <BaseFormSelectField
                  name={'registrationStrategy'}
                  label={'Registration strategy'}
                  options={[
                    { value: RegistrationStrategyType.FORMS_FIRST, label: 'Forms First' },
                    { value: RegistrationStrategyType.PAYMENT_FIRST, label: 'Payment First' }
                  ]}
                  helperText={'Forms first - attendees will fill out all the forms before they pay for the registrations. Payment first - attendees will pay for the registrations first and then fill out all the forms.'}
                />
              ) : (
                <><BaseFormNumberField
                  name={'minimumAttendees'}
                  label={'Minimum attendees'}
                  min={1}
                  helperText={'The minimum number of attendees that must be registered in group registration. If left blank there will be no minimum.'}
                />
                <BaseFormNumberField
                  name={'maximumAttendees'}
                  label={'Maximum attendees'}
                  isRequired={false}
                  min={1}
                  helperText={'The maximum number of attendees that must be registered in group registration. If left blank there will be no maximum.'}
                />
                </>
              )}
              <BaseFormSelectField
                name={'isVisible'}
                label={'Visible to attendees'}
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' }
                ]}
                helperText={'Attendees won’t see and choose this attendee group once this field is set to No.'}
              />
            </BaseFormFieldGroup>
          </BaseFormDrawer>);
      }}
    </Formik>
  );
};

export default TypeForm;
