import { useCallback, type FC, useMemo, useEffect } from 'react';
import dayjs from 'dayjs';
import { useDisclosure } from '@chakra-ui/react';
import {
  UiStack,
  UiText,
  UiImage,
  UiIconCalendarBlank,
  UiHStack,
  UiIconMapPin,
  UiIconArrowSquareOut,
  uiStyles,
  UiIconHouse,
  UiSpinner,
  UiIconLink, UiIconCopy, UiIconCaretRight, UiIconPlusCircle,
  // UiGrid,
} from '@/lib/ui';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { useNavigate } from 'react-router-dom';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import CloneEventForm from './CloneEventForm';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface EventProps {
  eventId: string
}

const EventDetails: FC<EventProps> = ({
  eventId,
}) => {
  const { data: event, isLoading, error } = useEventQuery(eventId);
  const { reportToGlobal } = useApiErrorHandler();
  const navigate = useNavigate();
  const { tenantCode } = useRegisterRoute();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // const onNext = useCallback(
  //   () => event.eventSettings
  //     ? navigate(generatePageUrl('RegistrationBuildEventAttendeeCategories', { tenantCode, eventId: event.id.toString() }))
  //     : navigate(generatePageUrl('RegistrationPrefillEvent', { tenantCode, eventId: event.id.toString() }))
  //   , [event.eventSettings, event.id, navigate, tenantCode]);

  const attendeeRegisterLink = useMemo(
    () => { return generatePageUrl('RegistrationRegisterEmail', { tenantCode, eventId: `${event?.id}` }); },
    [event?.id, tenantCode]
  );

  const onStarFromScratch = useCallback(
    () => { return navigate(generatePageUrl('RegistrationBuildEventAttendeeCategories', { tenantCode, eventId })); }
    , [eventId, navigate, tenantCode]);

  useEffect(
    () => {
      if (error) {
        reportToGlobal(error);
      }
    },
    [error]
  );

  if (isLoading) {
    return (
      <UiStack p={8}>
        <UiSpinner size={'lg'} color={'primary.500'} thickness='2px' />
      </UiStack>
    );
  }

  if (event) {
    return (
      <>
        <UiStack alignItems={'stretch'} spacing={4}>
          <UiHStack>
            {event.menuLogo && (
              <UiImage
                boxSize={16}
                src={event.menuLogo}
              />
            )}
            <UiText variant="h6">{event.name}</UiText>
          </UiHStack>
          <UiStack
            bgColor={'#fff'}
            borderRadius={uiStyles.borderRadius}
            enableDivider={true}
            spacing={0}
          >
            {event.startTime && event.endTime && (
              <UiHStack p={6}>
                <UiIconCalendarBlank size={'3xl'} />
                <UiText>
                  {dayjs.tz(event.startTime, event.tzInfoName).format('Do MMM YYYY')} - {dayjs.tz(event.endTime, event.tzInfoName).format('Do MMM YYYY')}
                </UiText>
              </UiHStack>
            )}
            {!!event.location && (
              <UiHStack p={6}>
                <UiIconMapPin size={'3xl'} />
                <UiText>{event.location}</UiText>
              </UiHStack>
            )}
            {
              event.venues.length > 0 && (
                <UiHStack p={6}>
                  <UiIconHouse size={'3xl'} />
                  <UiStack>
                    {event.venues
                      .map((venue) => { return (venue.name); })
                      .filter((venueName) => { return !!venueName; })
                      .map((venueName) => {
                        return (
                          <UiHStack key={venueName}>
                            <UiText>{venueName}</UiText>
                          </UiHStack>
                        );
                      })}
                  </UiStack>
                </UiHStack>
              )
            }
            <UiHStack p={6}>
              <UiIconLink size={'3xl'} />
              <UiHStack spacing={4}>
                <UiText flexGrow={1}>Event registration link</UiText>
                {/* <UiIconCaretRight color={'primary.500'} /> */}
                <UiStack {...uiStyles.hover}>
                  <BaseRouterLink to={attendeeRegisterLink ?? undefined} target={'_blank'}>
                    <UiIconArrowSquareOut color={'primary.500'} />
                  </BaseRouterLink>
                </UiStack>
              </UiHStack>
            </UiHStack>

            {/* <UiStack spacing={4}> */}
            {/*  <BaseRouterLink to={attendeeRegisterLink ?? undefined} target={'_blank'}> */}
            {/*    <UiHStack */}
            {/*      p={8} */}
            {/*      py={6} */}
            {/*      bgColor={'#fff'} */}
            {/*      borderRadius={uiStyles.borderRadius} */}
            {/*      {...uiStyles.hover} */}
            {/*    > */}
            {/*      <UiText flexGrow={1}>Event registration link</UiText> */}
            {/*      /!*<UiIconCaretRight color={'primary.500'} />*!/ */}
            {/*      <UiIconArrowSquareOut color={'primary.500'}/> */}
            {/*    </UiHStack> */}
            {/*  </BaseRouterLink> */}
            {/* </UiStack> */}
          </UiStack>
          <BaseDividerHorizontal height={8} />
          <UiStack alignItems={'flex-end'} spacing={4} >
            {/* Hide the button here once the registration build is already started. */}
            <UiHStack
              width={480}
              onClick={onOpen}
              justifyContent={'space-between'}
              px={6}
              py={4}
              bgColor={'#fff'}
              borderRadius={uiStyles.borderRadius}
              {...uiStyles.hover}
            >
              <UiHStack spacing={2}>
                <UiIconCopy />
                <UiText>Build the registration by cloning a previous event</UiText>
              </UiHStack>
              <UiIconCaretRight />
            </UiHStack>
            <UiHStack
              width={480}
              onClick={onStarFromScratch}
              justifyContent={'space-between'}
              px={6}
              py={4}
              bgColor={'#fff'}
              borderRadius={uiStyles.borderRadius}
              {...uiStyles.hover}
            >
              <UiHStack spacing={2}>
                <UiIconPlusCircle />
                {/* If the registration build is already started, we will name if to "View registration" */}
                <UiText>Build the registration from scratch</UiText>
              </UiHStack>
              <UiIconCaretRight />
            </UiHStack>
          </UiStack>
        </UiStack>
        <CloneEventForm
          eventId={eventId}
          tenantCode={tenantCode}
          isVisible={isOpen}
          onClose={onClose}
        />
      </>
    );
  }
};

export default EventDetails;
