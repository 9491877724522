import './styles.css';
import ReactPaginate from 'react-paginate';
import { type FC } from 'react';
import { UiIconCaretLeft, UiIconCaretRight } from '@/lib/ui';

export interface PaginateProps {
  pageCount: number
  currentPage: number
  onPageChange: React.Dispatch<{ selected: number }>
}

const Paginate: FC<PaginateProps> = ({ onPageChange, pageCount, currentPage }) => {
  if (pageCount > 1) {
    return (
      <ReactPaginate
        breakLabel="..."
        nextLabel={<UiIconCaretRight size={'2xl'} color={'primary.500'}/>}
        onPageChange={onPageChange}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel={<UiIconCaretLeft size={'2xl'} color={'primary.500'}/>}
        renderOnZeroPageCount={null}
        breakClassName="page-item"
        breakLinkClassName="page-link"
        marginPagesDisplayed={2}
        containerClassName="pagination justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
        // eslint-disable-next-line no-unused-vars
        hrefBuilder={(page, count, selected) => { return page >= 1 && page <= count ? `/page/${page}` : '#'; }
        }
        hrefAllControls
        forcePage={currentPage}
        // onClick={(clickEvent) => {}}
      />
    );
  }
};

export default Paginate;
