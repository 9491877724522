import { type FC, type ReactNode } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  UiHStack, UiIconArrowCircleRight, UiIconArrowSquareOut, UiIconArrowSquareRight, UiIconCaretRight, UiLink,
  UiStack,
  type UiStackProps, uiStyles, UiTag, UiText,
} from '@/lib/ui';
import { generatePageUrl } from '@/app/pages';
import BaseRouterLink from '@/base/Router/Link';
import { useTenantRoute } from '@/account/hook/useTenantRoute';
import { useGlobalApi } from '@/account/hook/useGlobalApi';
import { generateAdminUserSessionNonce, TenantData } from '@/api/account';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import ModuleRedirection from './ModuleRedirection';
import { account } from '@/api';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';

export interface ModuleCardProps {
  tenant: account.TenantData
  module: ReactNode
  navLink?: string
  isEnabled?: boolean
  isExternal?: boolean
}

const ModuleCard: FC<ModuleCardProps> = ({
  tenant,
  module,
  navLink = '',
  isEnabled = true,
  isExternal,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <UiStack
        bgColor={'#fff'}
        flexGrow={1}
        p={8}
        borderRadius={uiStyles.borderRadius}
        spacing={0}
        justifyContent={'space-between'}
        opacity={isEnabled ? 1 : 0.6}
        shadow={uiStyles.cardShadow}
      >
        <UiHStack>
          {module}
        </UiHStack>
        <BaseDividerHorizontal height={4}/>
        {isEnabled ? (
          isExternal ? (
            <UiHStack justifyContent={'space-between'}>
              <UiStack>
                <UiTag colorScheme={'green'}>Enabled</UiTag>
              </UiStack>
              <UiStack {...uiStyles.hover}>
                {/*<UiIconCaretRight size={'4xl'} color={'primary.500'} onClick={onToggle}/>*/}
                <UiIconArrowCircleRight size={'3xl'} color={'primary.500'} onClick={onToggle}/>
              </UiStack>
            </UiHStack>
          ) : (
            <UiHStack justifyContent={'space-between'}>
              <UiStack>
                <UiTag colorScheme={'green'}>Enabled</UiTag>
              </UiStack>
              <UiStack {...uiStyles.hover}>
                <UiLink
                  href={navLink ?? ''}
                  style={{ cursor: 'inherit' }}
                  // target={'_blank'}
                >
                  <UiIconArrowCircleRight size={'3xl'} color={'primary.500'} />
                  {/*<UiIconArrowSquareOut size={'3xl'} color={'primary.500'} />*/}
                </UiLink>
              </UiStack>
            </UiHStack>
          )
        ) : (
          <UiHStack justifyContent={'space-between'}>
            <UiStack>
              <UiTag colorScheme={'red'}>Inactive</UiTag>
            </UiStack>
            <UiStack {...uiStyles.hover}>
              <BaseRouterLink to={generatePageUrl('AccountSubscriptionPlan', { tenantCode: tenant.code})}>
                <UiText color={'primary.500'} fontWeight={600}>Enable</UiText>
              </BaseRouterLink>
            </UiStack>
          </UiHStack>
        )}
      </UiStack>
      {isExternal && (
        <ModuleRedirection
          module={module}
          navLink={navLink}
          isOpen={isOpen}
          onClose={onToggle}
        />
      )}
    </>
  );
};

export default ModuleCard;
