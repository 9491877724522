import { type FC, useCallback, useMemo } from 'react';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef
} from 'mantine-react-table';
import dayjs, { type Dayjs } from 'dayjs';
import { registration as registrationAPI } from '@/api';
import { UiBadge, UiButton, UiHStack, UiIconCaretCircleRight, UiStack, uiStyles, UiTag } from '@/lib/ui';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { type SerializedRegistrationTableRow } from './RegistrationTable';
import { type DatatableSelectOptions } from '@/api/tenantClient';
import { useNavigate } from 'react-router-dom';
import { generatePageUrl } from '@/app/pages';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useTenantApi } from '@/account/hook/useTenantApi';
import { useAdminAuth } from '@/app/ProviderAdminAuth';
import { useApiErrorHandler } from '@/account/hook/useApiErrorHandler';
import { useMutation } from '@tanstack/react-query';
import { useDataTableOptions } from '@/registration/hook/useDataTableOptions';
import { PermissionAction, PermissionDomain } from '@/api/constant/adminUserPermission';
import BaseAclContainer from '@/account/component/AclContainer';

dayjs.extend(advancedFormat);

interface DatatableProps {
  data: SerializedRegistrationTableRow[]
  isLoading: boolean
  isError: boolean
  isFetching: boolean
  refetch: () => void
  filters: any
  setFilters: (filters: any) => void
  sorting: any
  setSorting: (sorting: any) => void
  search: string
  setSearch: (searchText: string) => void
  pagination: any
  setPagination: (pagination: any) => void
  rowCount: number
  delegateTypeSelectOptions: DatatableSelectOptions
  booleanSelectOptions: DatatableSelectOptions
  statusSelectOptions: DatatableSelectOptions
  workshopTicketsSelectOptions: DatatableSelectOptions
  functionTicketsSelectOptions: DatatableSelectOptions
  tourTicketsSelectOptions: DatatableSelectOptions
  attendeeCategorySelectOptions: DatatableSelectOptions
}

const Datatable: FC<DatatableProps> = ({
  data, isLoading, isError, isFetching, refetch, filters, setFilters, sorting, setSorting, pagination, setPagination, search, setSearch,
  rowCount, delegateTypeSelectOptions, booleanSelectOptions, statusSelectOptions, workshopTicketsSelectOptions, functionTicketsSelectOptions, tourTicketsSelectOptions, attendeeCategorySelectOptions
}) => {
  const navigate = useNavigate();
  const { tenantCode, tenant } = useRegisterRoute();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { adminAuth } = useAdminAuth();
  const { reportToGlobal } = useApiErrorHandler();
  const dataTableOptions = useDataTableOptions<SerializedRegistrationTableRow>();

  const { mutate: exportEmailAsCSV, isLoading: isExportCSVLoading } = useMutation<{}, Error>({
    mutationFn: async () => {
      return await registrationAPI.exportEmailAsCSV(createTenantAdminApiRequest)({
        model: 'Registration',
        email: adminAuth.user!.email
      });
    },
    onError: (error) => {
      reportToGlobal(error);
    }
  });

  const onExportEmailAsCSV = useCallback(() => { return exportEmailAsCSV(); }, [exportEmailAsCSV]);

  const columns = useMemo<Array<MRT_ColumnDef<SerializedRegistrationTableRow>>>(
    () => {
      return [
        {
          accessorKey: 'id',
          header: 'ID'
        },
        {
          accessorKey: 'uuid',
          header: 'Uuid'
        },
        {
          accessorKey: 'idInfo',
          header: 'ID info'
        },
        {
          accessorKey: 'event.name',
          header: 'Event'
        },
        {
          accessorKey: 'user.fullName',
          header: 'Name'
        },
        {
          accessorKey: 'user.email',
          header: 'Email'
        },
        {
          accessorKey: 'delegateType.name',
          header: 'Registration type',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: delegateTypeSelectOptions
          }
        },
        {
          accessorKey: 'attendeeCategory.name',
          header: 'Attendee group',
          filterVariant: 'select',
          size: 300,
          mantineFilterSelectProps: {
            data: attendeeCategorySelectOptions
          },
          enableSorting: false,
        },
        {
          accessorKey: 'ticket.function',
          header: 'Function tickets',
          filterVariant: 'multi-select',
          size: 300,
          mantineFilterSelectProps: {
            data: functionTicketsSelectOptions
          },
          enableSorting: false,
          Cell: ({ cell }) => {
            const tickets = cell.getValue() as Array<{ name: string, quantity: number, type: string }>;
            return (
              <>
                {tickets.map((ticket, index) => {
                  return (
                    <UiBadge key={index} colorScheme="blue" mr={1}>
                      {`${ticket.name}`}
                    </UiBadge>
                  );
                })}
              </>
            );
          }
        },
        {
          accessorKey: 'ticket.workshop',
          header: 'Workshop tickets',
          filterVariant: 'multi-select',
          size: 300,
          enableSorting: false,
          mantineFilterSelectProps: {
            data: workshopTicketsSelectOptions
          },
          Cell: ({ cell }) => {
            const tickets = cell.getValue() as Array<{ name: string, quantity: number, type: string }>;
            return (
              <>
                {tickets.map((ticket, index) => {
                  return (
                    <UiBadge key={index} colorScheme="blue" mr={1}>
                      {`${ticket.name}`}
                    </UiBadge>
                  );
                })}
              </>
            );
          }
        },
        {
          accessorKey: 'ticket.tour',
          header: 'Tour tickets',
          filterVariant: 'multi-select',
          size: 300,
          enableSorting: false,
          mantineFilterSelectProps: {
            data: tourTicketsSelectOptions
          },
          Cell: ({ cell }) => {
            const tickets = cell.getValue() as Array<{ name: string, quantity: number, type: string }>;
            return (
              <>
                {tickets.map((ticket, index) => {
                  return (
                    <UiBadge key={index} colorScheme="blue" mr={1}>
                      {`${ticket.name}`}
                    </UiBadge>
                  );
                })}
              </>
            );
          }
        },
        {
          accessorKey: 'status',
          header: 'Status',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: statusSelectOptions
          }
        },
        {
          accessorKey: 'abandonedCart',
          header: 'Abandoned cart',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: booleanSelectOptions
          },
          Cell: ({ cell }) => {
            if (cell.getValue() === null) return;

            if (cell.getValue()) {
              return (
                <UiTag colorScheme='red'>
                  Yes
                </UiTag>
              );
            } else if (!cell.getValue()) {
              return (
                <UiTag colorScheme='gray'>
                  No
                </UiTag>
              );
            }
          }
        },
        {
          accessorKey: 'isGroupRegistration',
          header: 'Is group rego',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: booleanSelectOptions
          },
          Cell: ({ cell }) => {
            if (cell.getValue() === null) return;

            if (cell.getValue()) {
              return (
                <UiTag colorScheme='green'>
                  Yes
                </UiTag>
              );
            } else if (!cell.getValue()) {
              return (
                <UiTag colorScheme='gray'>
                  No
                </UiTag>
              );
            }
          }
        },
        {
          accessorKey: 'registeredBy',
          header: 'Registered by',
        },
        {
          accessorKey: 'paid',
          header: 'Paid',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: booleanSelectOptions
          }
        },
        {
          accessorKey: 'complete',
          header: 'Complete',
          filterVariant: 'select',
          mantineFilterSelectProps: {
            data: booleanSelectOptions
          }
        },
        {
          accessorKey: 'createdAt',
          header: 'Created at date',
          id: 'createdAtDate',
          Cell: ({ cell }) => { return cell.getValue() ? dayjs(cell.getValue() as string | number | Date | Dayjs).format('Do MMMM') : ''; },
          filterVariant: 'date-range'
        },
        {
          accessorKey: 'createdAt',
          header: 'Created at time',
          id: 'createdAtTime',
          Cell: ({ cell }) => { return cell.getValue() ? dayjs(cell.getValue() as string | number | Date | Dayjs).format('hh:mm A Z') : ''; },
          enableColumnFilter: false,
          enableSorting: false,
        },
        {
          accessorKey: 'confirmDelegateTypeAt',
          header: 'Completed at date',
          id: 'completedAtDate',
          Cell: ({ cell }) => { return cell.getValue() ? dayjs(cell.getValue() as string | number | Date | Dayjs).format('Do MMMM') : ''; },
          filterVariant: 'date-range'
        },
        {
          accessorKey: 'confirmDelegateTypeAt',
          header: 'Completed at time',
          id: 'completedAtTime',
          Cell: ({ cell }) => { return cell.getValue() ? dayjs(cell.getValue() as string | number | Date | Dayjs).format('hh:mm A Z') : ''; },
          enableColumnFilter: false,
          enableSorting: false,
        }
      ];
    },
    []
  );

  const toRegistrationInfoPage = (registrationId: number) => {
    navigate(generatePageUrl('RegistrationManageInfo', { tenantCode, registrationId: registrationId.toString() }));
  };

  const table = useMantineReactTable({
    columns,
    data,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: false,
      columnVisibility: {
        id: false,
        uuid: false,
        'delegateType.name': false,
        'ticket.function': false,
        'ticket.workshop': false,
        'ticket.tour': false,
        paid: false,
        complete: false,
        createdAt: false,
        confirmDelegateTypeAt: false,
        'attendeeCategory.name': false
      }
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    onColumnFiltersChange: setFilters,
    onSortingChange: setSorting,
    onGlobalFilterChange: setSearch,
    onPaginationChange: setPagination,
    rowCount,
    state: {
      columnFilters: filters,
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isFetching,
      sorting,
      globalFilter: search,
      pagination
    },
    displayColumnDefOptions: { 'mrt-row-actions': { size: 96 } },
    enableRowActions: true,
    positionActionsColumn: 'last',
    globalFilterFn: 'contains',
    renderTopToolbarCustomActions: () => {
      return (
        <UiHStack
          spacing={8}
          flexGrow={1}
          justifyContent={'flex-end'}
          px={8}
        >
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={onExportEmailAsCSV}
            isLoading={isExportCSVLoading}>
            Email as CSV
          </UiButton>
          <UiButton
            px={0}
            size={'sm'}
            variant={'ghost'}
            colorScheme={'primary'}
            onClick={refetch}>
            Apply Filter
          </UiButton>
        </UiHStack>
      );
    },
    renderRowActions: ({ row }) => {
      return (
        <BaseAclContainer tenantId={tenant?.id ?? 0} permissionsRequired={[[PermissionDomain.Registration, PermissionAction.Write]]}>
          <UiHStack
            px={0}
            justifyContent={'flex-start'}
            onClick={() => { return toRegistrationInfoPage(row.original.id); }}
            {...uiStyles.hover}
          >
            <UiIconCaretCircleRight size={'3xl'} color={'primary.500'} />
          </UiHStack>
        </BaseAclContainer>
      );
    },
    ...dataTableOptions
  });

  return <MantineReactTable table={table} />;
};

export default Datatable;
