import { useCallback, type FC, useMemo } from 'react';
import dayjs from 'dayjs';
import {
  UiStack,
  UiDrawer,
  UiDrawerOverlay,
  UiDrawerContent,
  UiDrawerBody,
  UiDrawerCloseButton,
  UiText,
  type UiDrawerProps,
  UiImage,
  UiIconCalendarBlank,
  UiHStack,
  UiIconMapPin,
  UiButton,
  UiDrawerFooter, UiIconCaretRight, uiStyles, UiIconHouse, UiIconArrowSquareOut,
  UiLink,
  // UiGrid,
} from '@/lib/ui';
import BaseDividerHorizontal from '@/base/Divider/Horizontal';
import { type Event } from '@/api/registration';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { useNavigate } from 'react-router-dom';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { generateRegisterAbsoluteUrl } from '@/lib/util';
import { UiIconBrowser } from '@/lib/ui/Icon/UiIconBrowser';
import BaseBlockList from '@/base/BlockList';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface EventDetailProps extends Omit<UiDrawerProps, 'children'> {
  event: Event
  isOpen: boolean
}

const EventDetail: FC<EventDetailProps> = ({
  event,
  isOpen,
  ...props
}) => {
  const navigate = useNavigate();
  const { tenantCode } = useRegisterRoute();
  const attendeeRegisterLink = useMemo(
    () => { return `/event/${tenantCode}/register/${event.id}/email`; },
    [event.id, tenantCode]
  );
  const onNext = useCallback(
    () => {
      return navigate(generatePageUrl('RegistrationBuildEventAttendeeCategories', { tenantCode, eventId: event.id.toString() }));
    }, [event.eventSettings, event.id, navigate, tenantCode]
  );

  const croppedWebsiteUrl = useMemo(
    () => { return event.websiteUrl ? event.websiteUrl.replace(/(^\w+:|^)\/\//, '') : ''; }
    , [event]);

  return (
    <UiDrawer placement={'right'} size={'lg'} isOpen={isOpen} {...props}>
      <UiDrawerOverlay />
      <UiDrawerContent bgColor={'gray.100'}>
        {/* <UiDrawerHeader borderBottomWidth='1px'>Filter events</UiDrawerHeader> */}
        <UiDrawerBody p={0}>
          <UiDrawerCloseButton size={'lg'} color={'primary.500'} />
          <UiStack alignItems={'stretch'} p={8} py={16} spacing={4}>
            <UiHStack>
              {
                event.menuLogo && (
                  <UiImage
                    boxSize={16}
                    objectFit={'contain'}
                    src={event.menuLogo}
                  />)
              }
              <UiText variant="h6">{event.name}</UiText>
            </UiHStack>
            <BaseBlockList
              bgColor={'#fff'}
              // borderRadius={uiStyles.borderRadius}
              enableDivider={true}
              // spacing={0}
            >
              {event.startTime && event.endTime && (
                <UiHStack p={6}>
                  <UiIconCalendarBlank size={'3xl'} />
                  <UiText>
                    {dayjs(event.startDate).format('Do MMM YYYY')} - {dayjs(event.endDate).format('Do MMM YYYY')}
                    {/* {dayjs.tz(event.startTime, event.tzInfoName).format('Do MMM YYYY')} - {dayjs.tz(event.endTime, event.tzInfoName).format('Do MMM YYYY')} */}
                  </UiText>
                </UiHStack>
              )}
              {!!event.websiteUrl && (
                <UiHStack p={6}>
                  <UiIconBrowser size={'3xl'} />
                  <UiLink href={croppedWebsiteUrl} target={'_blank'} {...uiStyles.hover}>
                    <UiText>{croppedWebsiteUrl}</UiText>
                  </UiLink>
                </UiHStack>
              )}
              {!!event.location && (
                <UiHStack p={6}>
                  <UiIconHouse size={'3xl'} />
                  <UiText>{event.location}</UiText>
                </UiHStack>
              )}
              {/* {
                event.venues.length > 0 && (
                  <UiHStack p={6}>
                    <UiIconHouse size={'3xl'} />
                    <UiStack>
                      {event.venues.map((venue) => { return (venue.name); }).filter((venueName) => { return !!venueName; }).map((venueName) => {
                        return (
                          <UiHStack key={venueName}>
                            <UiText>{venueName}</UiText>
                          </UiHStack>
                        );
                      })}
                    </UiStack>
                  </UiHStack>
                )
              } */}
            </BaseBlockList>
            <BaseDividerHorizontal height={8} />
            <UiStack spacing={4}>
              <BaseRouterLink to={generateRegisterAbsoluteUrl(attendeeRegisterLink) ?? undefined} target={'_blank'}>
                <UiHStack
                  p={8}
                  py={6}
                  bgColor={'#fff'}
                  borderRadius={uiStyles.borderRadius}
                  shadow={uiStyles.cardShadow}
                  {...uiStyles.hover}
                >
                  <UiText flexGrow={1}>Event registration link</UiText>
                  <UiIconArrowSquareOut color={'primary.500'} />
                </UiHStack>
              </BaseRouterLink>
            </UiStack>
          </UiStack>
        </UiDrawerBody>
        <UiDrawerFooter p={0}>
          <UiStack flexDirection={'row-reverse'} p={8}>
            <UiButton
              px={8}
              size={'lg'}
              shadow={'base'}
              colorScheme={'primary'}
              onClick={onNext}
            >
              Start
            </UiButton>
          </UiStack>
        </UiDrawerFooter>
      </UiDrawerContent>
    </UiDrawer>
  );
};

export default EventDetail;
