import {
  type ApiRequest,
  type ApiResponse,
  type ApiResponseSingle,
  properCallApi
} from '@/api/tenantClient';

import { type OrderById } from '@/api/registration/order';

export const refundTableQueryKey = 'refund.table';

export interface RefundRequest {
  orderId: number
  refundAmount: number
  refundNote: string
  refunderEmail: string | undefined
  selectedItemsIds: number[]
}

export function refunds(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: RefundRequest
  ): Promise<ApiResponseSingle<OrderById>> => {
    const { orderId, ...payload } = params;
    request.method = 'POST';
    request.endpoint.path = `/api/v1/orders/${orderId}/refunds`;
    request.payload = { order: payload };
    return await properCallApi<ApiResponseSingle<OrderById>>(request);
  };
};

export interface RefundQueryRequest {
  page: number
  filters?: any
  sorting?: any
  size: number
  search?: string
}

export interface RefundTableRow {
  id: number
  attendee: { name: string, email: string }
  event: { id: number, name: string }
  attendeeCategory: { name: string }
  country: string | null
  discountCode: string | null
}

export function loadRefundsForTable(createTenantAdminRequest: () => ApiRequest) {
  const request = createTenantAdminRequest();
  return async (
    params: RefundQueryRequest
  ): Promise<ApiResponse<RefundTableRow>> => {
    request.method = 'GET';
    request.endpoint.path = '/api/v1/refunds';
    request.endpoint.query = {
      page: (params?.page ?? 0) + 1,
      filters: JSON.stringify(params.filters ?? []),
      sorting: JSON.stringify(params.sorting ?? []),
      size: params.size ?? 10,
      ...params.search ? { search: params.search } : {}
    };
    return await properCallApi<ApiResponse<RefundTableRow>>(request);
  };
}
