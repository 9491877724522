import { type RouteObject } from 'react-router-dom';

import AdminAccess from '@/account/component/AdminAccess';
import RegistrationDashboardOverviewPage from '@/registration/dashboard/Overview';

import BuildEventLayout from '@/registration/component/Layout/BuildEventLayout';

import RegistrationManageEventPage from '@/registration/manage/Event';
import RegistrationManageEventInfoPage from '@/registration/manage/EventInfo';
import RegistrationManageOrderPage from '@/registration/manage/Order';
import RegistrationManageOrderInfoPage from '@/registration/manage/OrderInfo';
// import RegistrationPrefillPage from '@/registration/manage/PrefillEvent';
import CancelledRegistrationManageTablePage from '@/registration/manage/CancelledRegistration';
import FormResponseManageTablePage from '@/registration/manage/FormResponse';
import RefundManageTablePage from '@/registration/manage/Refund';
import RegistrationManageTablePage from '@/registration/manage/Registration';
import RegistrationManageInfoPage from '@/registration/manage/RegistrationInfo';

import RegistrationReportOrderPage from '@/registration/report/Order';
import RegistrationReportRegistrationPage from '@/registration/report/Registration';

// import RegistrationBuildEventEventInfoPage from '@/registration/buildEvent/EventInfo';
import RegistrationBuildEventAdditionalInfoFormPage from '@/registration/buildEvent/AdditionalInfoForm';
import RegistrationBuildEventAttendeeCategoriesPage from '@/registration/buildEvent/AttendeeCategories';
import RegistrationBuildEventBadgePage from '@/registration/buildEvent/Badge';
import RegistrationBuildEventBuildAdditionalInfoFormPage from '@/registration/buildEvent/BuildAdditionalInfoForm';
import RegistrationBuildEventBuildPersonalInfoFormPage from '@/registration/buildEvent/BuildPersonalInfoForm';
import RegistrationBuildEventCheckoutPage from '@/registration/buildEvent/Checkout';
import RegistrationBuildEventAddDiscountCodesPage from '@/registration/buildEvent/DiscountCodes';
import RegistrationBuildEventFunctionTicketsPage from '@/registration/buildEvent/FunctionTickets';
import RegistrationBuildEventGroupRegistrationPage from '@/registration/buildEvent/GroupRegistration';
import RegistrationBuildEventMainEventTicketsPage from '@/registration/buildEvent/MainEventTickets';
import RegistrationBuildEventMerchandisePage from '@/registration/buildEvent/Merchandise';
import RegistrationBuildEventPaymentMethodsPage from '@/registration/buildEvent/PaymentMethods';
import RegistrationBuildEventPersonalInfoFormPage from '@/registration/buildEvent/PersonalInfoForm';
import RegistrationBuildEventPrivacyPage from '@/registration/buildEvent/Privacy';
import RegistrationBuildEventTourTicketsPage from '@/registration/buildEvent/TourTickets';
import RegistrationBuildEventWorkshopTicketsPage from '@/registration/buildEvent/WorkshopTickets';
// import RegistrationBuildEventLabelsDescriptionsPage from '@/registration/buildEvent/LabelsDescriptions';
import RegistrationBuildEventOtherSettingsPage from '@/registration/buildEvent/OtherSettings';
import RegistrationImporterAttemptErrorRegistrationPage from '@/registration/Importers/ImportAttemptErrorRegistration';
import RegistrationImporterRegistrationPage from '@/registration/Importers/Importer';
import RegistrationSettingsPaymentOptionsPage from 'src/registration/settings/PaymentOptions';

import AIChatSessionWindow from './eventGeneration/AIChatSessionWindow';

export interface DashboardRouteParams {
  RegistrationDashboardOverview: { tenantCode: string }
}
export type DashboardRouteName = keyof DashboardRouteParams;

export interface ManageRouteParams {
  RegistrationManageEvent: { tenantCode: string }
  // RegistrationManageEventInfo: { tenantCode: string, eventId: string }
  RegistrationManageOrder: { tenantCode: string }
  RegistrationManageTable: { tenantCode: string }
  RefundManageTable: { tenantCode: string }
  CancelledRegistrationManageTable: { tenantCode: string }
  FormResponseManageTable: { tenantCode: string }
  RegistrationManageInfo: { tenantCode: string, registrationId: string }
  RegistrationManageOrderInfo: { tenantCode: string, orderId: string }
  // RegistrationPrefillEvent: { tenantCode: string, eventId: string }
  RegistrationReportOrder: { tenantCode: string }
  RegistrationReportRegistration: { tenantCode: string }
  RegistrationImporterRegistration: { tenantCode: string }
  RegistrationImporterAttemptErrorRegistration: {
    tenantCode: string
    importAttemptId: string
  }
  RegistrationSettingsPaymentOptions: {
    tenantCode: string
  }
}

export type ManageRouteName = keyof ManageRouteParams;

export interface BuildEventRouteParams {
  // RegistrationBuildEventEventInfo: { tenantCode: string, eventId: string }
  RegistrationBuildEventGroupRegistration: {
    tenantCode: string
    eventId: string
  }
  RegistrationBuildEventAttendeeCategories: {
    tenantCode: string
    eventId: string
  }
  RegistrationBuildEventPersonalInfoForm: {
    tenantCode: string
    eventId: string
  }
  RegistrationBuildEventBuildPersonalInfoForm: {
    tenantCode: string
    eventId: string
  }
  RegistrationBuildEventDiscountCodes: { tenantCode: string, eventId: string }
  RegistrationBuildEventMainEventTickets: {
    tenantCode: string
    eventId: string
  }
  RegistrationBuildEventFunctionTickets: { tenantCode: string, eventId: string }
  RegistrationBuildEventWorkshopTickets: { tenantCode: string, eventId: string }
  RegistrationBuildEventTourTickets: { tenantCode: string, eventId: string }
  RegistrationBuildEventAdditionalInfoForm: {
    tenantCode: string
    eventId: string
  }
  RegistrationBuildEventBuildAdditionalInfoForm: {
    tenantCode: string
    eventId: string
  }
  RegistrationBuildEventPrivacy: { tenantCode: string, eventId: string }
  RegistrationBuildEventPaymentMethods: { tenantCode: string, eventId: string }
  RegistrationBuildEventBadge: { tenantCode: string, eventId: string }
  RegistrationBuildEventCheckout: { tenantCode: string, eventId: string }
  RegistrationBuildEventMerchandise: { tenantCode: string, eventId: string }
  // RegistrationBuildEventLabelsDescriptions: { tenantCode: string, eventId: string }
  RegistrationBuildEventOtherSettings: { tenantCode: string, eventId: string }
  EventGeneration: { tenantCode: string }
}
export type BuildEventRouteName = keyof BuildEventRouteParams;

export interface RegisterRouteParams {
  RegistrationRegisterEmail: { tenantCode: string, eventId: string }
  RegistrationRegisterPersonalInfo: { tenantCode: string, eventId: string }
  RegistrationRegisterCategory: { tenantCode: string, eventId: string }
  RegistrationRegisterMainTickets: { tenantCode: string, eventId: string }
  RegistrationRegisterFunctionTickets: { tenantCode: string, eventId: string }
  RegistrationRegisterWorkshopTickets: { tenantCode: string, eventId: string }
  RegistrationRegisterTourTickets: { tenantCode: string, eventId: string }
  RegistrationRegisterShoppingCart: { tenantCode: string, eventId: string }
  RegistrationRegisterAdditionalInfo: { tenantCode: string, eventId: string }
  RegistrationRegisterReview: { tenantCode: string, eventId: string }
  RegistrationRegisterCheckoutAddress: { tenantCode: string, eventId: string }
  RegistrationRegisterPayment: { tenantCode: string, eventId: string }
  RegistrationRegisterPaymentResult: { tenantCode: string, eventId: string }
  RegistrationRegisterInvoiceResult: { tenantCode: string, eventId: string }
}

export type RegisterRouteName = keyof RegisterRouteParams;

export interface RegistrationRouteParams
  extends DashboardRouteParams,
  ManageRouteParams,
  BuildEventRouteParams,
  RegisterRouteParams {}

export type RegistrationRouteName = keyof RegistrationRouteParams;

export const buildEventRoutes: Record<
keyof BuildEventRouteParams,
RouteObject
> = {
  // RegistrationBuildEventEventInfo: {
  //   id: 'RegistrationBuildEventEventInfo',
  //   path: ':eventId/event-info',
  //   element: <RegistrationBuildEventEventInfoPage />
  // },
  RegistrationBuildEventGroupRegistration: {
    id: 'RegistrationBuildEventGroupRegistration',
    path: ':eventId/group-registration',
    element: <RegistrationBuildEventGroupRegistrationPage />
  },
  RegistrationBuildEventAttendeeCategories: {
    id: 'RegistrationBuildEventAttendeeCategories',
    path: ':eventId/attendee-categories',
    element: <RegistrationBuildEventAttendeeCategoriesPage />
  },
  RegistrationBuildEventDiscountCodes: {
    id: 'RegistrationBuildEventDiscountCodes',
    path: ':eventId/discount-codes',
    element: <RegistrationBuildEventAddDiscountCodesPage />
  },
  RegistrationBuildEventPersonalInfoForm: {
    id: 'RegistrationBuildEventPersonalInfoForm',
    path: ':eventId/personal-info-form',
    element: <RegistrationBuildEventPersonalInfoFormPage />
  },
  RegistrationBuildEventBuildPersonalInfoForm: {
    id: 'RegistrationBuildEventBuildPersonalInfoForm',
    path: ':eventId/build-personal-info-form',
    element: <RegistrationBuildEventBuildPersonalInfoFormPage />
  },
  RegistrationBuildEventMainEventTickets: {
    id: 'RegistrationBuildEventMainEventTickets',
    path: ':eventId/main-event-tickets',
    element: <RegistrationBuildEventMainEventTicketsPage />
  },
  RegistrationBuildEventFunctionTickets: {
    id: 'RegistrationBuildEventFunctionTickets',
    path: ':eventId/function-tickets',
    element: <RegistrationBuildEventFunctionTicketsPage />
  },
  RegistrationBuildEventWorkshopTickets: {
    id: 'RegistrationBuildEventWorkshopTickets',
    path: ':eventId/workshop-tickets',
    element: <RegistrationBuildEventWorkshopTicketsPage />
  },
  RegistrationBuildEventTourTickets: {
    id: 'RegistrationBuildEventTourTickets',
    path: ':eventId/tour-tickets',
    element: <RegistrationBuildEventTourTicketsPage />
  },
  RegistrationBuildEventAdditionalInfoForm: {
    id: 'RegistrationBuildEventAdditionalInfoForm',
    path: ':eventId/additional-info-form',
    element: <RegistrationBuildEventAdditionalInfoFormPage />
  },
  RegistrationBuildEventBuildAdditionalInfoForm: {
    id: 'RegistrationBuildEventBuildAdditionalInfoForm',
    path: ':eventId/build-additional-info-form',
    element: <RegistrationBuildEventBuildAdditionalInfoFormPage />
  },
  RegistrationBuildEventPrivacy: {
    id: 'RegistrationBuildEventPrivacy',
    path: ':eventId/privacy',
    element: <RegistrationBuildEventPrivacyPage />
  },
  RegistrationBuildEventPaymentMethods: {
    id: 'RegistrationBuildEventPaymentMethods',
    path: ':eventId/payment-methods',
    element: <RegistrationBuildEventPaymentMethodsPage />
  },
  RegistrationBuildEventBadge: {
    id: 'RegistrationBuildEventBadge',
    path: ':eventId/badge',
    element: <RegistrationBuildEventBadgePage />
  },
  RegistrationBuildEventCheckout: {
    id: 'RegistrationBuildEventCheckout',
    path: ':eventId/checkout',
    element: <RegistrationBuildEventCheckoutPage />
  },
  RegistrationBuildEventMerchandise: {
    id: 'RegistrationBuildEventMerchandise',
    path: ':eventId/merchandise',
    element: <RegistrationBuildEventMerchandisePage />
  },
  // RegistrationBuildEventLabelsDescriptions: {
  //   id: 'RegistrationBuildEventLabelsDescriptions',
  //   path: ':eventId/labels-descriptions',
  //   element: (<RegistrationBuildEventLabelsDescriptionsPage />)
  // },
  RegistrationBuildEventOtherSettings: {
    id: 'RegistrationBuildEventOtherSettings',
    path: ':eventId/other-settings',
    element: <RegistrationBuildEventOtherSettingsPage />
  },
  EventGeneration: {
    id: 'EventGeneration',
    path: 'event-generation',
    element: <AIChatSessionWindow />
  }
};

export const registrationRoutes: Record<string, RouteObject> = {
  // Dashboard routes
  RegistrationDashboardOverview: {
    id: 'RegistrationDashboardOverview',
    path: 'dashboard/overview',
    element: (
      <AdminAccess>
        <RegistrationDashboardOverviewPage />
      </AdminAccess>
    )
  },
  // Manage routes
  RegistrationManageEvent: {
    id: 'RegistrationManageEvent',
    path: 'manage/event',
    element: (
      <AdminAccess>
        <RegistrationManageEventPage />
      </AdminAccess>
    )
  },
  RegistrationManageEventInfo: {
    id: 'RegistrationManageEventInfo',
    path: 'manage/event/:eventId',
    element: (
      <AdminAccess>
        <RegistrationManageEventInfoPage />
      </AdminAccess>
    )
  },
  RegistrationManageOrder: {
    id: 'RegistrationManageOrder',
    path: 'manage/order',
    element: (
      <AdminAccess>
        <RegistrationManageOrderPage />
      </AdminAccess>
    )
  },
  RegistrationReportOrder: {
    id: 'RegistrationReportOrder',
    path: 'reports/order',
    element: (
      <AdminAccess>
        <RegistrationReportOrderPage />
      </AdminAccess>
    )
  },
  RegistrationReportRegistration: {
    id: 'RegistrationReportRegistration',
    path: 'reports/registration',
    element: (
      <AdminAccess>
        <RegistrationReportRegistrationPage />
      </AdminAccess>
    )
  },
  // RegistrationPrefillEvent: {
  //   id: 'RegistrationPrefillEvent',
  //   path: 'prefill-event/:eventId',
  //   element: (
  //     <AdminAccess>
  //       <RegistrationPrefillPage />
  //     </AdminAccess>
  //   )
  // },
  RegistrationManageOrderInfo: {
    id: 'RegistrationManageOrderInfo',
    path: 'manage/order-info/:orderId',
    element: (
      <AdminAccess>
        <RegistrationManageOrderInfoPage />
      </AdminAccess>
    )
  },
  RegistrationManageTable: {
    id: 'RegistrationManageTable',
    path: 'manage/registration',
    element: (
      <AdminAccess>
        <RegistrationManageTablePage />
      </AdminAccess>
    )
  },
  RefundManageTable: {
    id: 'RefundManageTable',
    path: 'manage/refund',
    element: (
      <AdminAccess>
        <RefundManageTablePage />
      </AdminAccess>
    )
  },
  CancelledRegistrationManageTable: {
    id: 'CancelledRegistrationManageTable',
    path: 'manage/cancelled-registration',
    element: (
      <AdminAccess>
        <CancelledRegistrationManageTablePage />
      </AdminAccess>
    )
  },
  FormResponseManageTable: {
    id: 'FormResponseManageTable',
    path: 'manage/form-response',
    element: (
      <AdminAccess>
        <FormResponseManageTablePage />
      </AdminAccess>
    )
  },
  // Importer routes
  RegistrationImporterRegistration: {
    id: 'RegistrationImporterRegistration',
    path: 'manage/registration-import',
    element: (<AdminAccess><RegistrationImporterRegistrationPage modelType='Registration' /></AdminAccess>)
  },
  RegistrationImporterAttemptErrorRegistration: {
    id: 'RegistrationImporterAttemptErrorRegistration',
    path: 'manage/registration-import-error/:importAttemptId',
    element: (<AdminAccess><RegistrationImporterAttemptErrorRegistrationPage /></AdminAccess>)
  },
  RegistrationManageInfo: {
    id: 'RegistrationManageInfo',
    path: 'manage/registration-info/:registrationId',
    element: (
      <AdminAccess>
        <RegistrationManageInfoPage />
      </AdminAccess>
    )
  },
  // Build Event routes
  BuildEvent: {
    path: 'build-event',
    element: <BuildEventLayout />,
    children: Object.values(buildEventRoutes)
  },
  RegistrationSettingsPaymentOptions: {
    path: 'manage/payment-option',
    element: (
      <AdminAccess>
        <RegistrationSettingsPaymentOptionsPage />
      </AdminAccess>
    )
  },
};
