import { type FC, useState, useMemo, useCallback } from 'react';
import { Formik, type FormikHelpers } from 'formik';
import {
  UiButton,
  UiHStack,
  type UiHStackProps,
  UiStack,
  UiText,
  UiBox
} from '@/lib/ui';
import BaseFormDrawer from '@/base/Form/Drawer';
import BaseFormFieldGroup from '@/base/Form/FieldGroup';
import BaseFormInputField from '@/base/Form/InputField';
import BaseFormTextareaField from '@/base/Form/TextareaField';
import BaseMessageBarError from '@/base/MessageBar/Error';
import BaseFormCheckboxListField, { type CheckboxOption } from '@/base/Form/CheckboxListField';
import { useAttendeeCategoryQuery } from '@/registration/hook/useAttendeeCategoryQuery';
import { useDaysQuery } from '@/registration/hook/useDaysQuery';
import { useThemesQuery } from '@/registration/hook/useThemesQuery';
import { useRecommendedDelegateTypesQuery } from '@/registration/hook/useRecommendedDelegateTypesQuery';
import { useRegistrationBadgesQuery } from '@/registration/hook/useRegistrationBadgesQuery';
import {
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import { registration } from '@/api';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { type TicketData, type TicketSaveRequest } from '@/api/registration';
import { StockType, stockTypeOptions, TicketType } from '@/api/constant';
import { useTenantApi } from '@/account/hook/useTenantApi';
import BaseRouterLink from '@/base/Router/Link';
import { generatePageUrl } from '@/app/pages';
import { type ApiResponse } from '@/api/tenantClient';
import { useRegisterRoute } from '@/registration/hook/useRegisterRoute';
import { useEventQuery } from '@/registration/hook/useEventQuery';
import BaseFormSelectField, { type Option } from '@/base/Form/SelectField';
import { useSharedStocksQuery } from '@/registration/hook/useSharedStocksQuery';

interface FormData {
  name: string
  description: string
  price?: number
  stock?: number
  validFrom: string
  validTo: string
  code: string
  attendeeCategories: Array<CheckboxOption['value']>
  stockType: StockType
  ticketSharedStockId: number | null
  days: Array<CheckboxOption['value']>
  themes: Array<CheckboxOption['value']>
  recommendedDelegateTypes: Array<CheckboxOption['value']>
  registrationBadges: Array<CheckboxOption['value']>
  accessLiveStream: string
  accessMeetingMatching: string
  accessNetworking: string
  accessResources: string
  accessVodContent: string
  accessChat: string
  accessUserProfile: string
  registrationBadgesColour: string
}

export interface TicketFormProps extends UiHStackProps {
  onClose: () => void
  onSaveSuccess: () => void
  isVisible: boolean
  ticket?: TicketData
  ticketType: TicketType
}

const formSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required.'),
    description: Yup.string()
    .max(200, 'Description can not have more than 100 characters.')
    .nullable(),
  price: Yup.number()
    .required('Price is required.'),
  stock: Yup.number().when('stockType', {
    is: (stockType: StockType) => {
      return stockType === StockType.Exclusive;
    },
    then: (schema) => { return schema.required('Capacity is required.'); },
    otherwise: (schema) => { return schema.nullable(); }
  },),
  validFrom: Yup.date()
    .required('Valid from is required field.').nullable()
    .typeError('Invalid Date format. Please enter a valid date.'),
  validTo: Yup.date()
    .required('Valid to is required field.').nullable()
    .typeError('Invalid Date format. Please enter a valid date.')
    .when('validFrom',
      (validFrom, yup) => {
        if (validFrom && validFrom[0] instanceof Date) {
          return yup.min(validFrom, 'Valid to cannot be before valid form.');
        } else {
          return yup;
        }
      }),
  attendeeCategories: Yup.array().of(Yup.string()).min(1, 'You must select at least 1 option'),
  stockType: Yup.number().required('Capacity type is required.'),
  ticketSharedStockId: Yup.number().when('stockType', {
    is: (stockType: StockType) => {
      return stockType === StockType.Shared;
    },
    then: (schema) => { return schema.required('Shared capacity is required.'); },
    otherwise: (schema) => { return schema.nullable(); }
  }),
  registrationBadgesColour: Yup.string()
    .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, 'Invalid color hex value')
    .nullable()
});

const TicketForm: FC<TicketFormProps> = ({
  onClose,
  onSaveSuccess,
  isVisible,
  ticket,
  ticketType
}) => {
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const { tenantCode, eventId } = useRegisterRoute();
  const queryClient = useQueryClient();
  const { createTenantAdminApiRequest } = useTenantApi();
  const { data: event } = useEventQuery(eventId);

  const { data = [], isLoading: isLoadingAttendeeCategory } = useAttendeeCategoryQuery(eventId);

  // Get list of shared stocks for the ticket type
  const sharedStocksQuery = useSharedStocksQuery(eventId, ticketType);
  const sharedStockOptions = useMemo(
    () => {
      const options: Option[] = [];
      sharedStocksQuery.data?.map(stock => {
        options.push({
          value: stock.id,
          label: stock.name
        });
      });
      return options;
    },
    [
      sharedStocksQuery.data,
    ]
  );

  const attendeeCategoryOptions = useMemo(
    () => {
      return data.map((attendeeCategory) => {
        return {
          value: `${attendeeCategory.id}`,
          label: attendeeCategory.name
        };
      });
    },
    [data]
  );

  const { data: daysData = [], isLoading: isLoadingDays } = useDaysQuery(eventId);
  const daysOptions = useMemo(
    () => {
      return daysData.map((day) => {
        return {
          value: `${day.id}`,
          label: day.date
        };
      });
    },
    [daysData]
  );

  const { data: themesData = [], isLoading: isLoadingThemes } = useThemesQuery(eventId);
  const themesOptions = useMemo(
    () => {
      return themesData.map((theme) => {
        return {
          value: `${theme.id}`,
          label: theme.name
        };
      });
    },
    [themesData]
  );

  const { data: recommendedDelegateTypesData = [], isLoading: isLoadingRecommendedDelegateTypes } = useRecommendedDelegateTypesQuery(eventId, ticket?.id.toString() ?? '');
  const recommendedDelegateTypesOptions = useMemo(
    () => {
      return recommendedDelegateTypesData.map((delegateType) => {
        return {
          value: `${delegateType.id}`,
          label: delegateType.name
        };
      });
    },
    [recommendedDelegateTypesData]
  );

  const { data: registrationBadgesData = [], isLoading: isLoadingRegistrationBadges } = useRegistrationBadgesQuery(eventId);
  const registrationBadgesOptions = useMemo(
    () => {
      return registrationBadgesData.map((badge) => {
        return {
          value: `${badge.id}`,
          label: badge.title
        };
      });
    },
    [registrationBadgesData]
  );

  const { mutateAsync, isLoading } = useMutation<ApiResponse<TicketData>, Error, TicketSaveRequest>({
    mutationFn: async (ticketData: TicketSaveRequest) => {
      setSaveErrors([]);
      return await registration.saveTicket(createTenantAdminApiRequest)(ticketData);
    },
    onSuccess: (result) => {
      if (result?.errors && Array.isArray(result?.errors) && result?.errors.length > 0) {
        setSaveErrors(result?.errors);
      } else {
        onSaveSuccess();
        void queryClient.invalidateQueries({ queryKey: [registration.ticketListQueryKey, { eventId, ticketType }] });
        void queryClient.invalidateQueries({ queryKey: [registration.eventSettingsQueryKey, { eventId }] });
        void queryClient.invalidateQueries({ queryKey: [registration.recommendedDelegateTypesQueryKey, eventId] });

        onClose();
      }
    },
    onError: (error) => {
      setSaveErrors([error.message ?? 'Failed to save the host.']);
    }
  });

  const getInitValues = useCallback((): FormData => {
    if (!ticket) {
      return {
        name: '',
        description: '',
        validFrom: '',
        validTo: '',
        code: '',
        attendeeCategories: [],
        stockType: StockType.Exclusive,
        ticketSharedStockId: null,
        days: [],
        themes: [],
        recommendedDelegateTypes: [],
        registrationBadges: [],
        accessLiveStream: 'false',
        accessMeetingMatching: 'false',
        accessNetworking: 'false',
        accessResources: 'false',
        accessVodContent: 'false',
        accessChat: 'false',
        accessUserProfile: 'false',
        registrationBadgesColour: '#000000'
      };
    }

    const dateTimeFormat = 'YYYY-MM-DD HH:mm';
    const validFrom = dayjs(ticket.validFrom).tz(event?.tzInfoName).format(dateTimeFormat);
    const validTo = dayjs(ticket.validTo).tz(event?.tzInfoName).format(dateTimeFormat);
    return {
      ...ticket,
      price: ticket.price / 100,
      validFrom,
      validTo,
      attendeeCategories: ticket.attendeeCategories.map(category => { return category.id.toString(); }),
      stockType: ticket.stockType ?? StockType.Exclusive,
      ticketSharedStockId: ticket.ticketSharedStockId,
      days: ticket.days?.map(day => { return day.id.toString(); }) ?? [],
      themes: ticket.themes?.map(theme => { return theme.id.toString(); }) ?? [],
      recommendedDelegateTypes: ticket.recommendedDelegateTypes?.map(delegateType => { return delegateType.id.toString(); }) ?? [],
      registrationBadges: ticket.registrationBadges?.map(badge => { return badge.id.toString(); }) ?? [],
      accessLiveStream: ticket.accessLiveStream?.toString() === 'true' ? 'true' : 'false',
      accessMeetingMatching: ticket.accessMeetingMatching?.toString() === 'true' ? 'true' : 'false',
      accessNetworking: ticket.accessNetworking?.toString() === 'true' ? 'true' : 'false',
      accessResources: ticket.accessResources?.toString() === 'true' ? 'true' : 'false',
      accessVodContent: ticket.accessVodContent?.toString() === 'true' ? 'true' : 'false',
      accessChat: ticket.accessChat?.toString() === 'true' ? 'true' : 'false',
      accessUserProfile: ticket.accessUserProfile?.toString() === 'true' ? 'true' : 'false',
      registrationBadgesColour: ticket.registrationBadgesColour ?? '#000000'
    } satisfies FormData;
  }, [ticket, event?.tzInfoName]);

  const onSubmit = async (
    values: FormData,
    { setSubmitting, resetForm }: FormikHelpers<FormData>
  ) => {
    setSubmitting(true);
    const formatString = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    const validFrom = dayjs(values.validFrom).tz(event?.tzInfoName, true).utc().format(formatString);
    const validTo = dayjs(values.validTo).tz(event?.tzInfoName, true).utc().format(formatString);

    await mutateAsync({
      id: ticket?.id,
      eventIds: [eventId],
      name: values.name,
      description: values.description,
      price: values.price!,
      stock: values.stock!,
      validFrom,
      validTo,
      code: values.code ?? '',
      attendeeCategoryIds: values.attendeeCategories,
      ticketType,
      stockType: values.stockType ?? StockType.Exclusive,
      ticketSharedStockId: values.ticketSharedStockId ?? null,
      dayIds: values.days,
      themeIds: values.themes,
      delegateTypeRecommendationIds: values.recommendedDelegateTypes,
      registrationBadgeIds: values.registrationBadges,
      accessLiveStream: values.accessLiveStream,
      accessMeetingMatching: values.accessMeetingMatching,
      accessNetworking: values.accessNetworking,
      accessResources: values.accessResources,
      accessVodContent: values.accessVodContent,
      accessChat: values.accessChat,
      accessUserProfile: values.accessUserProfile,
      registrationBadgesColour: values.registrationBadgesColour
    });
    !ticket && resetForm();
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={getInitValues()}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        const descriptionCharacterCount = values.description ? 200 - values.description.length : 200;
        return (
          <BaseFormDrawer
            isOpen={isVisible}
            onClose={onClose}
            title={'Add ticket'}
            size={'xl'}
            isLoading={isLoading || isLoadingAttendeeCategory || isLoadingDays || isLoadingThemes || isLoadingRecommendedDelegateTypes || isLoadingRegistrationBadges}
          >
            {saveErrors.length > 0 && (
              <UiStack spacing={4} flexGrow={1} py={4}>
                {saveErrors.map((error, index) => {
                  return (
                    <BaseMessageBarError key={index}>
                      {error}
                    </BaseMessageBarError>
                  );
                })}
              </UiStack>
            )}
            <BaseFormFieldGroup>
              <BaseFormInputField
                name={'name'}
                label={'Name'}
              />
              <BaseFormTextareaField
                name={'description'}
                label={'Description'}
                isRequired={false}
                maxLength={200}
                helperText={`200 character limit (${descriptionCharacterCount} characters remaining)`}
              />
              <BaseFormInputField
                name={'price'}
                type={'number'}
                label={'Price (in dollars)'}
                helperText={'Please round up your price into dollars if needed.'}
                min={0}
                max={21474836} // BE x 100 to convert from cents to int4
              />
              <BaseFormSelectField
                name={'stockType'}
                label={'Capacity type'}
                options={stockTypeOptions}
              />
              {values.stockType === StockType.Shared && (
                <BaseFormSelectField
                  name={'ticketSharedStockId'}
                  label={'Shared capacity'}
                  options={sharedStockOptions}
                  isDisabled={sharedStocksQuery.isLoading}
                  isRequired={true}
                  helperText={(sharedStockOptions.length && sharedStockOptions.length > 0) ? '' : 'Please create the shared capcity first.'}
                />
              )}
              {values.stockType === StockType.Exclusive && (
                <BaseFormInputField
                  name={'stock'}
                  type={'number'}
                  label={'Capacity'}
                  min={0}
                  max={2147483647}
                />
              )}
              <UiText color={'gray.600'} variant="caption">
                {"Tickets will only be visible on dates including and between the 'Valid from' date and 'Valid to' date."}
              </UiText>
              <BaseFormInputField
                name={'validFrom'}
                label={'Valid from'}
                type={'datetime-local'}
                helperText="The provided date should be in the event's time zone."
                isRequired={true}
              />
              <BaseFormInputField
                name={'validTo'}
                label={'Valid to'}
                type={'datetime-local'}
                helperText="The provided date should be in the event's time zone."
                isRequired={true}
              />
              <BaseFormInputField
                name={'code'}
                label={'Ticket code'}
                type={'text'}
                helperText={'Ticket code for your own reference.'}
                isRequired={false}
              />
              {attendeeCategoryOptions.length > 0 ? (
                <BaseFormCheckboxListField
                  name={'attendeeCategories'}
                  label={'Visible for attendee groups'}
                  helperText={'Add the attendee groups that can see this ticket.'}
                  options={attendeeCategoryOptions}
                />
              ) : (
                <UiHStack justifyContent={'space-between'} alignItems={'flex-start'}>
                  <UiHStack>
                    <UiText>Attendee groups</UiText>
                  </UiHStack>
                  <UiStack alignItems={'flex-end'}>
                    <UiText color={'red.500'}>Please create attendee groups first.</UiText>
                    <UiStack>
                      <BaseRouterLink to={generatePageUrl('RegistrationBuildEventAttendeeCategories', { tenantCode, eventId })} relative={'route'}>
                        <UiButton variant={'outline'}>Manage attendee groups</UiButton>
                      </BaseRouterLink>
                    </UiStack>
                  </UiStack>
                </UiHStack>
              )}
              {ticketType === TicketType.Main && (
                <>
                  <BaseFormCheckboxListField
                    name={'days'}
                    label={'Choose days'}
                    helperText={'Select the days the attendee can access.'}
                    options={daysOptions}
                  />
                  <BaseFormCheckboxListField
                    name={'themes'}
                    label={'Choose themes'}
                    isRequired={false}
                    helperText={'Select the themes the attendee can access.'}
                    options={themesOptions}
                  />
                  {/*{recommendedDelegateTypesOptions.length > 0 ? (*/}
                  {/*  <BaseFormCheckboxListField*/}
                  {/*    name={'recommendedDelegateTypes'}*/}
                  {/*    label={'Recommended delegate types'}*/}
                  {/*    helperText={'Select the recommended delegate types'}*/}
                  {/*    options={recommendedDelegateTypesOptions}*/}
                  {/*  />*/}
                  {/*) : (*/}
                  {/*  <UiHStack justifyContent={'space-between'} alignItems={'flex-start'}>*/}
                  {/*    <UiHStack>*/}
                  {/*      <UiText>Recommended delegate types</UiText>*/}
                  {/*    </UiHStack>*/}
                  {/*    <UiStack alignItems={'flex-end'}>*/}
                  {/*      <UiText color={'gray.500'}>No recommended delegate types to select</UiText>*/}
                  {/*    </UiStack>*/}
                  {/*  </UiHStack>*/}
                  {/*)}*/}
                  <BaseFormCheckboxListField
                    name={'registrationBadges'}
                    label={'Registration badges'}
                    isRequired={false}
                    helperText={'Choose the registration badges.'}
                    options={registrationBadgesOptions}
                  />
                  <BaseFormInputField
                    name={'registrationBadgesColour'}
                    label={'Registration badge colour'}
                    isRequired={false}
                    maxLength={7}
                    helperText={'Enter a valid color value (e.g., #000000 for black). This is the color bar on the badge that indicates the registry trpe of the attendee.'}
                    rightElement={
                      <UiBox
                        width="35px"
                        height="35px"
                        backgroundColor={values.registrationBadgesColour}
                        borderRadius="md"
                        border="1px"
                        borderColor="gray.200"
                      />
                    }
                  />
                  <BaseFormSelectField
                    name={'accessLiveStream'}
                    label={'Access live streams'}
                    isMultiple={false}
                    helperText={'If the attendee can access live streams for the days/themes that have been selected.'}
                    options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]}
                  />
                  <BaseFormSelectField
                    name={'accessMeetingMatching'}
                    label={'Schedule/attend meetings'}
                    isMultiple={false}
                    helperText={'If the attendee can schedule 1:1 meetings and join them.'}
                    options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]}
                  />
                  <BaseFormSelectField
                    name={'accessNetworking'}
                    label={'Access small group networking'}
                    isMultiple={false}
                    helperText={'If the attendee can access small group networking.'}
                    options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]}
                  />
                  <BaseFormSelectField
                    name={'accessResources'}
                    label={'Access resources'}
                    isMultiple={false}
                    helperText={'If the attendee can access resources for the event.'}
                    options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]}
                  />
                  <BaseFormSelectField
                    name={'accessVodContent'}
                    label={'Access VoD content'}
                    isMultiple={false}
                    helperText={'If the attendee can access video on demand content for the days/themes that have been selected.'}
                    options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]}
                  />
                  <BaseFormSelectField
                    name={'accessChat'}
                    label={'Access Chat feature'}
                    isMultiple={false}
                    helperText={'If the attendee can chat with other users.'}
                    options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]}
                  />
                  <BaseFormSelectField
                    name={'accessUserProfile'}
                    label={'Access user profile'}
                    isMultiple={false}
                    helperText={'If the attendee can view the profile of other users.'}
                    options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]}
                  />
                </>
              )}
            </BaseFormFieldGroup>
          </BaseFormDrawer>);
      }}
    </Formik>
  );
};

export default TicketForm;
