import { useMemo, type FC } from 'react';
import { UiSimpleGrid, UiStack, uiStyles, UiText } from '@/lib/ui';
import { type OrderItemType } from '@/api/registration';
import { createFormatPrice } from '@/lib/util/locale';

export interface TicketProps {
  ticket: OrderItemType
  currencyCode: string
}

const Ticket: FC<TicketProps> = ({
  ticket,
  currencyCode
}) => {
  const priceLocale = 'en-AU';
  const formatPrice = useMemo(
    () => { return createFormatPrice({ locale: priceLocale, currency: currencyCode }); },
    []
  );
  return (
    <UiStack p={8} py={6} borderRadius={uiStyles.borderRadius} bgColor={'#fff'} flexGrow={1} enableDivider={true} spacing={4} shadow={uiStyles.cardShadow}>
      <UiStack>
        <UiText>{ticket.name}</UiText>
      </UiStack>
      <UiSimpleGrid columns={3} gap={8}>
        <UiStack>
          <UiText color={'text.secondary'}>Amount</UiText>
          <UiText>{formatPrice(ticket.price)}</UiText>
        </UiStack>
        <UiStack>
          <UiText color={'text.secondary'}>Quantity</UiText>
          <UiText>{ticket.quantity}</UiText>
        </UiStack>
        <UiStack>
          <UiText color={'text.secondary'}>Discounted</UiText>
          <UiText>{ticket.discountRuleType ? 'Yes' : 'No'}</UiText>
        </UiStack>
      </UiSimpleGrid>
    </UiStack>
  );
};

export default Ticket;
